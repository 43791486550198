import { SHOPIFY_REMOVE_CART_REQUEST, SHOPIFY_REMOVE_CART_SUCCESS, SHOPIFY_REMOVE_CART_ERROR, 
	SHOPIFY_EMPTY_CART_REQUEST, SHOPIFY_EMPTY_CART_SUCCESS, SHOPIFY_EMPTY_CART_ERROR 
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const rmvCart = (brand_id, var_id, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_REMOVE_CART_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/removefromcart`,
		headers: { Authorization: `Bearer ${token}` },
		
		data: {
			brand: brand_id,
			line_item_id: var_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_REMOVE_CART_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_REMOVE_CART_ERROR,
				payload: error.response,
			});
		});
};


export const emptyCart = (brand_id, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_EMPTY_CART_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/emptycart`,
		headers: { Authorization: `Bearer ${token}` },
		
		data: {
			brand: brand_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_EMPTY_CART_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_EMPTY_CART_ERROR,
				payload: error.response,
			});
		});
};
