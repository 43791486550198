import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrum from "../../components/breadCrum/breadCrum";
import SubCategories from "../../components/subCategory/subCategory";
import PopularInfluencers from "../../components/popularInfluencer/popularInfluencer";
import AllInfluencer from "../../components/influencer/allInfluencer";
import "../../css/category.scss";
import "react-multi-carousel/lib/styles.css";

export default () => {
	const params = useParams();

	const { subCategory } = useSelector((state) => state);
	// const category = params.category;
	let subCategoryName = subCategory?.payload?.parentData?.category_name;
	if (params?.category) {
		subCategoryName = subCategory?.payload?.data?.find((item) => item._id === params.category)?.category_name;
	}

	return (
		<>
			<BreadCrum categoryName={subCategoryName} page={"influencer"} />
			<SubCategories categoryName={subCategoryName} page="sub-influencer" />
			<PopularInfluencers />
			<AllInfluencer heading="All Influencers" />
		</>
	);
};
