import { GET_CATEGORY_WISE_INFLUENCER_REQUEST, GET_CATEGORY_WISE_INFLUENCER_SUCCESS, GET_CATEGORY_WISE_INFLUENCER_ERROR } from "../../types/types";
export default function  categoryWiseInfluencer(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_CATEGORY_WISE_INFLUENCER_REQUEST:
			return {
				loading: true,
			};
		case GET_CATEGORY_WISE_INFLUENCER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_CATEGORY_WISE_INFLUENCER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
