import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPopularInfluencer } from "../../redux/actions/category.action";
import "../../css/category.scss";

const responsivepopular = {
	desktop: {
		breakpoint: { max: 3000, min: 1280 },
		items: 5,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1279, min: 768 },
		items: 3,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

export default () => {

	const params = useParams();
	const id = params.id;
	const category = params.category;
	const dispatch = useDispatch();
	const [PopularInfluencer, setPopularInfluencer] = useState([]);

	useEffect(() => {
		dispatch(getPopularInfluencer()).then((res) => {
			setPopularInfluencer(res.payload.data);
		});
	}, [id, category]);

	return (
		<>
			<div className="top_picks_main">
				<div className="midd-width-ifti">
					<div className="top_picks">
						<div className="pik-header">
							<h3>Popular Influencers</h3>
						</div>
						<div className="popular_influencers">
							<Carousel responsive={responsivepopular} containerClass="carousel-container row" removeArrowOnDeviceType={["tablet", "mobile"]}>
								{PopularInfluencer.map((item) => {
									return (
										<>
											<div className="popular-infl-deal">
												<NavLink to={`/${item.instagram_username}`} className="ft_box_link_popular">
													<div className="ft_logo_popular">
														<img src={item.profile_image_url} />
													</div>
													<div className="ft_content_popular">
														<div className="ft-1_popular">
															<span className="ft-name_popular">{item.name}</span>
														</div>
														<div className="ft-2_popular">{/* <span className="ft-off_popular">100K Followers</span> */}</div>
													</div>
												</NavLink>
											</div>
										</>
									);
								})}
							</Carousel>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};