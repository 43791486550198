import React, { useEffect } from "react";
import error from "../../images/error.png";
import { NavLink } from "react-router-dom";
export default () => {
	return (
		<>
			<div className="error_page_main">
				<div className="midd-width-ifti">
					<div className="error_content">
						<div className="error_header">
							{/* <h2>404</h2> */}
							<img className="error-img" src={error} />
							<h3>The page you’re looking for does not exist</h3>
							<p>Go to homepage for the latest and greatest.</p>
							<NavLink to="/">Go To Homepage</NavLink>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
