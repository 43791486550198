import React from "react";
import { Skeleton } from "antd";
import "../../css/category.scss";
const CatInfLoader = () => {
	return (
		<>
			<Skeleton active avatar>
				<div className="cat_brands">
					<div className="filter_brands">
						<div className="ft_box">
							<a href="" className="ft_box_link">
								<div className="ft_logo">
									<img src={""} />
								</div>
								<div className="ft_content">
									<div className="ft-1">
										<span className="ft-name"></span>
									</div>
									<div className="ft-2">
										<span className="ft-off">1</span>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</Skeleton>
		</>
	);
};

export default CatInfLoader;