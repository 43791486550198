import Layout from "./component/layout";
import Link from "./component/link";
export default () => {
	return (
		<>
			<Layout>
				<Link />
			</Layout>
		</>
	);
};
