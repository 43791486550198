import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const putResetPassword = (token, password) => (dispatch) => {
	dispatch({ type: RESET_PASSWORD_REQUEST });
	return axios({
		method: "PUT",
		url: `${BASEURL}users/revise/password/new`,
		headers: { Authorization: `Bearer ${token}` },
		data: { password: password },
	})
		.then((response) => {
			return dispatch({
				type: RESET_PASSWORD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: RESET_PASSWORD_ERROR,
				payload: error.response,
			});
		});
};
