import { SHOPIFY_PROD_REQUEST, SHOPIFY_PROD_SUCCESS, SHOPIFY_PROD_ERROR } from "../../types/types";
export default function shopifyProduct(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case SHOPIFY_PROD_REQUEST:
			return {
				loading: true,
				success: true,
			};
		case SHOPIFY_PROD_SUCCESS:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};

		case SHOPIFY_PROD_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};

		default:
			return state;
	}
}