import { BASEURL } from "../../config";
import axios from "axios";
import {
	GET_CATEGORY_WISE_INFLUENCER_REQUEST,
	GET_CATEGORY_WISE_INFLUENCER_SUCCESS,
	GET_CATEGORY_WISE_INFLUENCER_ERROR,
	GET_ALL_INFLUENCER_REQUEST,
	GET_ALL_INFLUENCER_SUCCESS,
	GET_ALL_INFLUENCER_ERROR,
} from "../types/types";

export const getAllInfluencer =
	(page = 1, limit = 12, name) =>
	(dispatch) => {
		dispatch({ type: GET_ALL_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/category/getAllInfluencerForWebCustomer`,
			headers: {
				Accept: "application/json",
			},
			data: {
				name: name === "all" ? "" : name,
				account_type: "influencer",
			},
			params: {
				limit,
				page,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_ALL_INFLUENCER_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_ALL_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};

export const getCategoryWiseInfluencer =
	(categoryId, key, name, page = 1, limit = 100) =>
	(dispatch) => {
		let categoryUrl = `mobile/category/categoryWiseInfluencerAndBrand`;
		if (key === "subcategory") {
			categoryUrl = `mobile/category/getInfluencerSubCategoryWise?limit=${limit}&page=${page}`;
		}
		dispatch({ type: GET_CATEGORY_WISE_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: BASEURL + categoryUrl,
			headers: {
				Accept: "application/json",
			},
			data: {
				account_type:"influencer",
				categoryId: categoryId,
				name: name ? name : "",
				key: key,
			},
			params: {
				limit,
				page,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_CATEGORY_WISE_INFLUENCER_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_CATEGORY_WISE_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};
