import Layout from "./component/layout";
import TermsAndCondition from "./component/termsAndCondition";
export default () => {
	return (
		<>
			<Layout>
				<TermsAndCondition />
			</Layout>
		</>
	);
};