import { LIVE_EVENT_REQUEST, LIVE_EVENT_SUCCESS, LIVE_EVENT_ERROR } from "../../types/types";
export default function liveEvent(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case LIVE_EVENT_REQUEST:
			return {
				loading: true,
			};
		case LIVE_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case LIVE_EVENT_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}