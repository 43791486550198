import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { postContact } from "../../../redux/actions/contact.action";
import Swal from "sweetalert2";
const { TextArea } = Input;

export default () => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { contact } = useSelector((state) => state);
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	const onFinish = async (values) => {
		dispatch(postContact(userInfo?.token, values.firstName, values.email, values.phone, values.subject, values.message)).then(() => {
			form.resetFields(["firstName"]);
			form.resetFields(["email"]);
			form.resetFields(["subject"]);
			form.resetFields(["phone"]);
			form.resetFields(["message"]);
			Swal.fire({
				icon: "success",
				title: "Message Sent Successfully!",
			});
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<>
			<div className="contact_content">
				<h4>Contact Us</h4>
				<p>Have any questions? Fill out the form and wait for us to get back to you. That’s all!</p>
				<Form layout={"vertical"} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
					<div className="form-group contact-us-form-kb">
						<div className="row">
							<div className="col-6 cus-input-height">
								<Form.Item
									name="firstName"
									label="Name"
									rules={[
										{
											required: true,
											message: `Please enter your name`,
										},
									]}
								>
									<Input size="large" />
								</Form.Item>
							</div>

							<div className="col-6 cus-input-height">
								<Form.Item
									name="email"
									label="Email"
									rules={[
										{
											required: true,
											message: `Please enter your email address`,
										},
										{
											type: "email",
											message: `Please enter valid email address`,

										},
									]}
								>
									<Input size="large" />
								</Form.Item>
							</div>

							<div className="col-6 cus-input-height">
								<Form.Item
									name="phone"
									label="Phone"
									rules={[
										{
											required: true,
											message: `Please enter your phone number`,
										},
									]}
								>
									<Input size="large" />
								</Form.Item>
							</div>

							<div className="col-6 cus-input-height">
								<Form.Item
									name="subject"
									label="Subject"
									rules={[
										{
											required: true,
											message: `Please enter subject`,
										},
									]}
								>
									<Input size="large" />
								</Form.Item>
							</div>

							<div className="col-12 cus-text-area">
								<Form.Item name="message" label="Message">
									<TextArea rows={6} size="large" />
								</Form.Item>
							</div>
							<div className="col-12">
								{contact.loading ? (
									<Button type="primary" htmlType="submit">
										<Spin indicator={antIcon} />
									</Button>
								) : (
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								)}
							</div>
						</div>
					</div>
				</Form>
			</div>
		</>
	);
};