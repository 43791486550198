import Layout from "./component/layout";
import PrivacyPolicy from "./component/privacyPolicy";
export default () => {
	return (
		<>
			<Layout>
				<PrivacyPolicy />
			</Layout>
		</>
	);
};
