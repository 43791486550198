import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "../../css/influencer-reviews.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import closeicon from "../../images/brand-logos/close-icon.svg";
import qvc1avatar from "../../images/reviews/vid-6-avatar.jpg";

import poster1 from "../../images/reviews/ank-1.jpg";
import posteravt1 from "../../images/reviews/ank-1-avt.jpg";

import poster2 from "../../images/reviews/ank-2.jpg";
import posteravt2 from "../../images/reviews/ank-2-avt.jpg";

import poster3 from "../../images/reviews/ank-3.jpg";
import posteravt3 from "../../images/reviews/ank-3-avt.jpg";

import poster4 from "../../images/reviews/poster-4.jpg";
import posteravt4 from "../../images/reviews/vid-4-avatar.jpg";

import poster5 from "../../images/reviews/poster-5.jpg";
import posteravt5 from "../../images/reviews/vid-5-avatar.jpg";

import poster6 from "../../images/reviews/poster-6.jpg";
import posteravt6 from "../../images/reviews/vid-6-avatar.jpg";

export default () => {
  const slider = useRef();
  const [showProducts, setShowProducts] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    document.body.classList.add("customer-reviews-body");
    return () => {
      document.body.classList.remove("customer-reviews-body");
    };
  }, []);

  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    vertical: true,
    verticalSwiping: true,
    slidesToScroll: 1,
    nextArrow: <RightNavButton />,
    prevArrow: <LeftNavButton />,
    onSwipe: (e) => {
      console.log("e", e);
    },
    beforeChange: (oldIndex, newIndex) => {},
  };
  const otherreviews = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4.7,
          swipe: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.7,
          swipe: true,
        },
      },
    ],
  };
  function LeftNavButton(props) {
    const { style } = props;
    return (
      <button
        onClick={() => {
          slider.current.slickPrev();
          const prevVideo = document.getElementById(`video-${slideIndex + 1}`);
          const nextVideo = document.getElementById(`video-${slideIndex}`);

          if (prevVideo) {
            prevVideo.pause();
          }

          if (nextVideo) {
            if (nextVideo.paused) {
              nextVideo.play();
            }
          }

          if (slideIndex !== 0) setSlideIndex(slideIndex - 1);

          //alert(slideIndex);
        }}
        style={{ ...style, display: "block" }}
        type="button"
        data-role="none"
        //	className="slick-arrow slick-prev"
        class={`slick-arrow slick-prev ${slideIndex === 0 && "slick-disabled"}`}
      >
        {" "}
        Previous
      </button>
    );
  }

  function RightNavButton(props) {
    const { style } = props;
    return (
      <button
        onClick={(e) => {
          slider.current.slickNext();
          const prevVideo = document.getElementById(`video-${slideIndex + 1}`);
          const nextVideo = document.getElementById(`video-${slideIndex + 2}`);

          if (prevVideo) {
            prevVideo.pause();
          }

          if (nextVideo) {
            if (nextVideo.paused) {
              nextVideo.play();
            }
          }

          if (slideIndex !== 5) {
            setSlideIndex(slideIndex + 1);
          }

          //alert(slideIndex);
        }}
        style={{ ...style, display: "block" }}
        type="button"
        data-role="none"
        class={`slick-arrow slick-next ${slideIndex === 5 && "slick-disabled"}`}
      >
        {" "}
        Next
      </button>
    );
  }

  return (
    <>
      <div className="infl-rev-main-container">
        <div className="rev-container">
          <div className="rev-video-container">
            <NavLink to="/" className="close-video">
              <img src={closeicon} className="filter-gray" />
            </NavLink>
            <div className="rev-video-box-main">
              <Slider ref={(c) => (slider.current = c)} {...settings}>
                <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video
                        id="video-1"
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline
                        playsInline
                        controls
                        autoPlay
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://adm-videos.s3.amazonaws.com/vid-6.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">cyndewatson</div>
                        <span className="infl-image">
                          <img src={qvc1avatar} />
                        </span>
                      </div>
                      {/* <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div> */}
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src={qvc1avatar} />
                    </div>
                  </div>
                </div>
                {/* <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video id="video-2"
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/a74c0449-0062-4c43-9f2a-d9f82811da47.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div> */}
                {/* <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video id="video-3"
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/5fa51296-0202-4e14-8882-d9ad326d7491.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div> */}
                {/* <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video id="video-4"
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/e5150fe9-84d6-4115-82e1-ea10fd5a6c94.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div> */}
                {/* <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video id="video-5"
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                      
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/09648efd-eb0c-4314-b353-a55724e4c619.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div> */}
                {/* <div className="rev-vid-panel">
                  <div className="rev-vid-panel-inr">
                    <div className="rev-vid-dm1">
                      <video id="video-6"
                        controlsList="nodownload"
                        preload="metadata"
                        webkit-playsInline 
                        playsInline
                        controls
                        
                        loop
                        width="100%"
                        height="100%"
                      >
                        <source
                          src="https://static.konnect.bio/900000005/posts/fe617709-8700-4661-b167-9e62b3242937.mp4#t=0.001"
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="influ-detail">
                        <div className="inf-title">_alyssa_lorraine_</div>
                        <span className="infl-image">
                          <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                        </span>
                      </div>
                      <div className="influ-product-popup">
                        <Button
                          onClick={() => {
                            setShowProducts(true);
                          }}
                          variant="primary"
                        >
                          Products
                        </Button>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="infl-image-outer">
                    <div className="infl-out-img">
                      <img src="https://flipfit-cdn.akamaized.net/flip_pic/profile/61e6ffb6f4baba00243b4391/1642529141382-.tiny.webp" />
                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>
          </div>
          <div className="rev-content-container">
            <div className="review-container">
              <div className="event-container event-prod-container">
                <h3>Products in this video</h3>
                <Slider {...otherreviews}>
                  <div className="event-product-box">
                    <a
                      target="_blank"
                      href="https://www.qvc.com/IT-Cosmetics-CC-Cream-SPF-50-%26-Superhero-Eye-4-Pc-Collection-w-Makeup-Bag.product.A547353.html?"
                      className="revi-products"
                    >
                      <div className="rev-pro-image">
                        <img src="//qvc.scene7.com/is/image/QVC/a/53/a547353.005?$aempdlarge$" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          IT Cosmetics CC Cream SPF 50 & Superhero Eye 4-Pc
                          Collection w/ Makeup Bag
                        </span>
                        <span className="rev-pro-price">$59.82</span>
                      </div>
                    </a>
                  </div>
                  <div className="event-product-box">
                    <a
                      target="_blank"
                      href="https://www.qvc.com/IT-Cosmetics-Special-Edition-IT-Girl-Holiday-Beauty-Book-w-Gift-Box-%26-Bow.product.A522301.html?recommendationTypeId=QUS-AT-PDP-Z2&recommendationLocation=1&recommendationModuleType=PRODUCT_CAROUSEL&sc=DTLR&referringItem=A547353&TZ=EST"
                      className="revi-products"
                    >
                      <div className="rev-pro-image">
                        <img src="//qvc.scene7.com/is/image/QVC/a/01/a522301.004?$aempdlarge$" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          IT Cosmetics Special Edition IT Girl Holiday Beauty
                          Book w/ Gift Box & Bow
                        </span>
                        <span className="rev-pro-price">$59.96</span>
                      </div>
                    </a>
                  </div>
                  <div className="event-product-box">
                    <a
                      target="_blank"
                      href="https://www.qvc.com/IT-Cosmetics-CC-Cream-SPF-50-%26-Superhero-Eye-4-Pc-Kit-w-Bag-Auto-Delivery.product.A596781.html?recommendationTypeId=QUS-AT-PDP-Z2&recommendationLocation=2&recommendationModuleType=PRODUCT_CAROUSEL&sc=DTLR&referringItem=A547353&TZ=EST"
                      className="revi-products"
                    >
                      <div className="rev-pro-image">
                        <img src="//qvc.scene7.com/is/image/QVC/a/81/a596781.007?$aempdlarge$" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          IT Cosmetics CC Cream SPF 50 & Superhero Eye 4-Pc Kit
                          w/ Bag Auto-Delivery Spout
                        </span>
                        <span className="rev-pro-price">$69.00</span>
                      </div>
                    </a>
                  </div>
                  <div className="event-product-box">
                    <a
                      target="_blank"
                      href="https://www.qvc.com/Estee-Lauder-Pure-Color-Envy-Lipstick%2C-Eyeliner-%26-Mascara-Kit.product.A465477.html?recommendationTypeId=QUS-AT-PDP-Z2&recommendationLocation=3&recommendationModuleType=PRODUCT_CAROUSEL&sc=DTLR&referringItem=A547353&TZ=EST"
                      className="revi-products"
                    >
                      <div className="rev-pro-image">
                        <img src="//qvc.scene7.com/is/image/QVC/a/77/a465477.001?$aempdlarge$" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Estee Lauder Pure Color Envy Lipstick, Eyeliner &
                          Mascara Kit
                        </span>
                        <span className="rev-pro-price">$49.50</span>
                      </div>
                    </a>
                  </div>
                  <div className="event-product-box">
                    <a
                      target="_blank"
                      href="https://www.qvc.com/rms-beauty-Living-Luminizer-and-Lip2Cheek-Cream-Blush.product.A493580.html?recommendationTypeId=QUS-AT-PDP-Z2&recommendationLocation=4&recommendationModuleType=PRODUCT_CAROUSEL&sc=DTLR&referringItem=A547353&TZ=EST"
                      className="revi-products"
                    >
                      <div className="rev-pro-image">
                        <img src="//qvc.scene7.com/is/image/QVC/a/80/a493580.001?$aempdlarge$" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          rms beauty Living Luminizer and Lip2Cheek Cream Blush
                        </span>
                        <span className="rev-pro-price">$52.00</span>
                      </div>
                    </a>
                  </div>
                  <div className="event-product-box">
                    <a
                      target="_blank"
                      href="https://www.qvc.com/Clinique-Makeup-Must-Haves.product.A475230.html?recommendationTypeId=QUS-AT-PDP-Z2&recommendationLocation=5&recommendationModuleType=PRODUCT_CAROUSEL&sc=DTLR&referringItem=A547353&TZ=EST"
                      className="revi-products"
                    >
                      <div className="rev-pro-image">
                        <img src="//qvc.scene7.com/is/image/QVC/a/30/a475230.001?$aempdlarge$" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Clinique Makeup Must Haves
                        </span>
                        <span className="rev-pro-price">$29.50</span>
                      </div>
                    </a>
                  </div>
                  {/* <div className="event-product-box">
                    <NavLink to="#"
                      onClick={() => {
                        window.open(
                          "https://www.qvc.com/LocknLock-Set-of-2-Pantry-Storage-Containers-w-Pour-Spout.product.K50229.html?recommendationTypeId=QUS-AT-PDP-Z2&recommendationLocation=1&recommendationModuleType=PRODUCT_CAROUSEL&sc=DTLR&referringItem=K68667&TZ=EST"
                        );
                      }} className="revi-products">
                      <div className="rev-pro-image">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13245_EMILIE31_DARKOASIS_006.jpg?v=1643729652" />
                        <span className="rev-buy-now">
                          <i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                      <div className="rev-pro-detail">
                        <span className="rev-pro-title">
                          Rachel Flare Ultra High Rise Instasculpt 35" | White
                          Raw
                        </span>
                        <span className="rev-pro-price">$33.99</span>
                      </div>
                    </NavLink>
                  </div> */}
                </Slider>
              </div>

              <div className="event-container">
                <h3>Other reviews</h3>
                <Slider {...otherreviews}>
                  <div className="event-col-box">
                    <NavLink to="/influencer-reviews" className="live-vid-link">
                      <div className="vid-item">
                        <img src={poster1} />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src={posteravt1} />
                              </span>
                              <span className="auth-title">taylor_lovee</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <NavLink
                      to="/influencer-reviews-2"
                      className="live-vid-link"
                    >
                      <div className="vid-item">
                        <img src={poster2} />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src={posteravt2} />
                              </span>
                              <span className="auth-title">
                                andreaslookbook
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <NavLink
                      to="/influencer-reviews-3"
                      className="live-vid-link"
                    >
                      <div className="vid-item">
                        <img src={poster3} />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src={posteravt3} />
                              </span>
                              <span className="auth-title">jessglistening</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <NavLink
                      to="/influencer-reviews-4"
                      className="live-vid-link"
                    >
                      <div className="vid-item">
                        <img src={poster4} />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src={posteravt4} />
                              </span>
                              <span className="auth-title">janinegraffqvc</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <NavLink
                      to="/influencer-reviews-5"
                      className="live-vid-link"
                    >
                      <div className="vid-item">
                        <img src={poster5} />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src={posteravt5} />
                              </span>
                              <span className="auth-title">
                                shawnkillingerqvc
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <NavLink
                      to="/influencer-reviews-6"
                      className="live-vid-link"
                    >
                      <div className="vid-item">
                        <img src={poster6} />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <div className="author-name">
                              <span className="auth-image">
                                <img src={posteravt6} />
                              </span>
                              <span className="auth-title">cyndewatson</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </Slider>
              </div>

              {/* <div className="event-container">
                <h3>Brand Videos</h3>
                <Slider {...otherreviews}>
                  <div className="event-col-box">
                    <NavLink to="/influencer-reviews" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://cdn.shopify.com/s/files/1/1698/6315/articles/154078066_3840355622708575_4418324325494105805_n_1024x.jpg?v=1614290282" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://images.squarespace-cdn.com/content/v1/514ecafde4b045db9417fa2f/1634566581598-8Q7DGGYPQR8ZHBSYQ1MV/JR_Marlen_5x7_Postcard.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/ff-collection-shot-models-original-1629924514.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://media1.popsugar-assets.com/files/thumbor/HDjdstm17IyKfb4D4dp21SZiHBw/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2018/08/29/701/n/44285655/1e399895360866ee_AdidasOriginal_FW18_Falcon_B28126_Look2_FullBody_0190_03/i/Kylie-Jenner-Adidas-Falcon-Sneakers-2018.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/13246_Hepburn_Keys_042_650x.jpg?v=1643730359" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="event-col-box">
                    <a href="#" className="live-vid-link">
                      <div className="vid-item">
                        <img src="https://thevou.com/wp-content/uploads/2021/10/PlusSizeClothing.thevou.com_.warpweftworld.01.jpg" />
                        <div className="vid-item-detail">
                          <div className="vid-time">
                            <span className="vid-views">
                              <i className="fa fa-play" aria-hidden="true"></i>{" "}
                              59.5k
                            </span>
                          </div>
                          <div className="vid-title">
                            <span className="v-title">41 BEST Plus Size Jeans</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </Slider>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        backdropClassName="custom-backdrop"
        dialogClassName="influ-products-modal-box"
        show={showProducts}
        scrollable
        onHide={() => {
          setShowProducts(false);
        }}
        centered
      >
        <span
          className="filter-close"
          onClick={() => {
            setShowProducts(false);
          }}
        >
          <img src={closeicon} className="filter-gray" />
        </span>
        <Modal.Body>
          <div className="pro-list-main">
            <h2>Products in this Live Show</h2>
            <div className="pro-list-inner">
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
              <div className="list-col">
                <div className="prod-box">
                  <div className="prod-image">
                    <img src="https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_13248_BRIDGET30_TORNADO_004.jpg?v=1643729927" />
                  </div>
                  <div className="prod-name">
                    <div className="prod-title">
                      <span className="prod-name-inr">
                        Ella Straight: Ultra High Rise Vintage 32" | Droplet
                      </span>
                    </div>
                    <div className="prod-price">
                      <span className="prod-inr-price">
                        <span>$188.10</span>
                        <span className="original-price">$209.00</span>
                      </span>
                    </div>
                  </div>
                  <div className="prod-cart-btn">
                    <a href="#">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
