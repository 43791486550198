import { SHOPIFY_CART_REQUEST, SHOPIFY_CART_SUCCESS, SHOPIFY_CART_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const addToCart = (brand_id, status, quantity, varId, item, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_CART_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/addtocart`,
		headers: { Authorization: `Bearer ${token}` },
		
		data: {
            line_item: [{
                variantId: varId,
                quantity,
                customAttributes:item
              }],
			brand: brand_id,
            first_item: status,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_CART_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_CART_ERROR,
				payload: error.response,
			});
		});
};
