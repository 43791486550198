import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button } from "antd";
import { NavLink } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useSelector, useDispatch } from "react-redux";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";
import { getSubCategories } from "../../../redux/actions/category.action";
import static3 from "../../../images/brand-logos/Icons-03.jpg";
import static4 from "../../../images/brand-logos/Icons-04.jpg";
import { addToFavouriteBrand, removeFavouriteBrandList } from "../../../redux/actions/favourite.action";
import ProfileModal from "./profileModal";
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import Swal from "sweetalert2";
import { openLogin } from "../../../redux/actions/login.action";
import queryString from "query-string";

const responsivebioshoptop = {
	superLargeDesktop: {
		breakpoint: { max: 3000, min: 1300 },
		items: 5,
		slidesToSlide: 3, // optional, default to 1.
	},
	desktop: {
		breakpoint: { max: 1299, min: 768 },
		items: 4,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 767, min: 500 },
		items: 3.5,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 500, min: 0 },
		items: 2.5,
		slidesToSlide: 2, // optional, default to 1.
	},
};

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ children }) => {
	const queryParams = queryString.parse(window.location.search);
	const [subCategory, setSubCategory] = useState([]);
	const dispatch = useDispatch();
	const { bioShopMenu } = useSelector((state) => {
		return state;
	});
	const params = useParams();
	const category = params.parentId;
	const componentWillUnmount = useRef(false);
	const sub = params.sub;

	const [isFavourite, setIsFavourite] = useState(false);
	const [showProfileModal, setShowProfileModal] = useState(false);

	useEffect(() => {
		document.body.classList.add("bioshop-body");
		dispatch(getBioShopMenu(params.username, userInfo?.token));
	}, [params.username]);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body");
		};
	}, []);

	useEffect(() => {
		if (category) {
			dispatch(getSubCategories(sub ? sub : category)).then((res) => {
				setSubCategory(res?.payload?.data);
			});
		}
	}, [category]);

	useEffect(() => {
		if (bioShopMenu?.payload?.message?.data?.isFavoriteBrand) {
			setIsFavourite(bioShopMenu?.payload?.message?.data?.isFavoriteBrand);
		}
	}, [bioShopMenu?.payload?.message?.data]);

	return (
		<>
			<div className="bio_shop_main_ift">
				<div className="midd-width-ifti">
					<div className="bio_container_main">
						<div className="bio_shop_header">
							<div className="bio_shop_header_left">
								<div
									className="brand-descrp"
									onClick={() => {
										setShowProfileModal(true);
									}}
								>
									<div className="brand_logo">
										<img src={bioShopMenu?.payload?.message?.data?.profile_image_url} />
									</div>
									<div className="current-brand-name">
										{bioShopMenu?.payload?.message?.data?.name}
										<br />
										<span className="view-profile-btn">View Profile</span>
									</div>
								</div>

								{bioShopMenu?.payload?.message?.data?.promo !== "KB0" && bioShopMenu?.payload?.message?.data?.promo && <div className="brand_name">GET {bioShopMenu?.payload?.message?.data?.discount} OFF USING KBSHOP APP</div>}
							</div>

							<div className="bio_shop_header_center">
								<div className="bio_shop_cat_slider">
									{!bioShopMenu?.loading && (
										<Carousel responsive={responsivebioshoptop} autoPlay={false} arrows={true} slidesToSlide={2} containerClass="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]}>
											<div className="carousel-items">
												<NavLink to={`/${params.username}`}>
													<div className="cat_height">
														<img src={bioShopMenu?.payload?.message?.data?.menu[1].image_url} />
													</div>
													<span>All</span>
												</NavLink>
											</div>
											<div className="carousel-items">
												<NavLink to={`/live-events/${params.username}`}>
													<div className="cat_height">
														<img src={static4} />
													</div>
													<span>Live Events</span>
												</NavLink>
											</div>
											{bioShopMenu?.payload?.message?.data?.menu[3]?.image_url && (
												<div className="carousel-items">
													<NavLink className={queryParams?.post_type === "video" ? "active" : ""} to={`/video/${params.username}?post_type=video`}>
														<div className="cat_height">
															<img src={bioShopMenu?.payload?.message?.data?.menu[3].image_url} />
														</div>
														<span>Brand Videos</span>
													</NavLink>
												</div>
											)}
											<div className="carousel-items">
												<a href="#">
													<div className="cat_height">
														<img src={static3} />
													</div>
													<span>Influencer Videos</span>
												</a>
											</div>
										</Carousel>
									)}
								</div>
							</div>

							<div className="bio_shop_header_right">
								{userInfo?.account_type === "customer" && (
									<Button
										className="fav-btn"
										size="medium"
										type="primary"
										onClick={() => {
											if (!userInfo) {
												dispatch(openLogin(true));
											} else {
												if (isFavourite) {
													setIsFavourite(false);
													dispatch(removeFavouriteBrandList(userInfo.token, bioShopMenu?.payload?.message?.data?._id)).then((res) => {
														if (res.payload.success) {
															Swal.fire({
																icon: "success",
																title: res.payload.message,
															});
														} else {
															Swal.fire({
																icon: "error",
																title: "Oops...",
																text: res.payload.data.message,
															});
														}
													});
												} else {
													dispatch(addToFavouriteBrand(userInfo.token, bioShopMenu?.payload?.message?.data?._id)).then((res) => {
														if (res.payload.success) {
															setIsFavourite(true);
															Swal.fire({
																icon: "success",
																title: res.payload.message,
															});
														} else {
															Swal.fire({
																icon: "error",
																title: "Oops...",
																text: res.payload.data.message,
															});
														}
													});
												}
											}
										}}
									>
										<i class={`fa fa-heart ${isFavourite ? "active" : ""} `} aria-hidden="true"></i>
										Add to Favourite
									</Button>
								)}
							</div>
						</div>
						<div className="bio_shop_body">{children}</div>
					</div>
				</div>

				<ProfileModal showProfileModal={showProfileModal} setShowProfileModal={setShowProfileModal} />
			</div>
		</>
	);
};
