import { SHOPIFY_PROD_REQUEST, SHOPIFY_PROD_SUCCESS, SHOPIFY_PROD_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getProduct = (prod_id, brand_id, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_PROD_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/getproduct`,
		headers: { Authorization: `Bearer ${token}` },
		
		data: {
			brand: brand_id,
			product_id: prod_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_PROD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_PROD_ERROR,
				payload: error.response,
			});
		});
};
