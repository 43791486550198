import Layout from "./component/layout";
import BecomeInfluencer from "./component/becomeInfluencer";

export default () => {
	return (
		<>
			<Layout>
				<BecomeInfluencer />
			</Layout>

		</>
	);
};