import { Link } from "react-router-dom";
export default ({ categoryName, page }) => {
	return (
		<div className="bredcrumb_main">
			<div className="midd-width-ifti">
				<div className="bredcurmb">
					<ul>
						<li>
							<Link to="/">Home</Link>
							<span>/</span>
						</li>

						{(categoryName !== "Influencers"  && page !='influencer')&& (
							<li>
								<Link to={`/${page ? page : "category"} `}>Categories</Link>
								<span>/</span>
							</li>
						)}
						{page === "influencer" && (
							<>
								<li>
									<Link to={`/${page}`}>Influencers</Link>
									<span>/</span>
								</li>
							</>
						)}
						<li>
							<a href="#" className="active">
								{categoryName}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};
