import { POST_COUNTRY_REQUEST, POST_COUNTRY_SUCCESS, POST_COUNTRY_ERROR, POST_CONTACT_REQUEST, POST_CONTACT_SUCCESS, POST_CONTACT_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getCountry = () => (dispatch) => {
	dispatch({ type: POST_COUNTRY_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}common/receive/countries`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_COUNTRY_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_COUNTRY_ERROR,
				payload: error.response,
			});
		});
};

export const postContact = (token, firstName,email,phone,subject,message) => (dispatch) => {
	dispatch({ type: POST_CONTACT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}common/reserve/contact`,
		headers: { 	Accept: "application/json", },
		data: {
			firstName: firstName,
			email: email,
			phone:phone,
			subject: subject,
			message: message,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_CONTACT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_CONTACT_ERROR,
				payload: error.response,
			});
		});
};
