import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonPosts = ({ theme }) => {
	const themeClass = theme || "light";
	return (
		<div className={`skeleton-wrapper ${themeClass}`}>
			<div className="skeleton-article">
				{/* <SkeletonElement type="title" /> */}
				<div className="row">
					<div className="col-3 skeleton-post-box">
						<SkeletonElement type="text" />
					</div>

					<div className="col-3 skeleton-post-box">
						<SkeletonElement type="text" />
					</div>
					<div className="col-3 skeleton-post-box">
						<SkeletonElement type="text" />
					</div>
					<div className="col-3 skeleton-post-box">
						<SkeletonElement type="text" />
					</div>
				</div>
			</div>
			<Shimmer />
		</div>
	);
};

export default SkeletonPosts;
