import React from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {NavLink} from "react-router-dom"
import { Collapse } from "antd";
const { Panel } = Collapse;
export default () => {
	return (
		<>
			<h3>FAQs</h3>
			<h5>For Brands</h5>
			<div className="faq-container-ift">
				<Collapse
					accordion
					expandIconPosition={"end"}
					// expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
					expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
				>
					<Panel header="How does a brand sign-up?" key="1">
						<p>
							Sign-up as a Brand on <NavLink to="/">kbshop.com</NavLink>, and complete the required information along with payment details.
						</p>
					</Panel>
					<Panel header="What is the typical process for getting started?" key="2">
						<p>Brands after registration can build their own digital shop with curated products which can be linked to their e-commerce page. Brands create campaigns for influencers to participate.</p>
					</Panel>
					<Panel header="What are the fees involved on sales?" key="3">
						<p>
							<strong>In addition to the platform fee, following are the costs:</strong>
						</p>
						<ul>
							<li>Minimum 10% of sales generated through KBShop</li>
							<li>In case of sales generated through an Influencer, an additional commission.</li>
						</ul>
					</Panel>
					<Panel header="How do brands benefit from the platform?" key="4">
						<p>
							<strong>Brands benefits from KBShop by:</strong>
						</p>
						<ul>
							<li>Boosting sales through KBShop</li>
							<li>Boosting sales and reach through influencers’ participation in campaigns</li>
							<li>Measuring actual return of investment (ROI) against influencer spend</li>
							<li>Turning Instagram followers into customers</li>
							<li>Promoting your products across all social channels through Link-in-Bio</li>
							<li>Exclusive access to marketplace with a community of influencers and shoppers</li>
						</ul>
					</Panel>
					<Panel header="How do brands promote their products through influencers?" key="5">
						<ul>
							<li>Influencer requests the brand to become brand influencers</li>
							<li>Brand approves the influencer</li>
							<li>Influencers can then participate in brand’s campaigns and share the products to their followers</li>
						</ul>
					</Panel>
					<Panel header="How do brands increase sales? " key="6">
						<ul>
							<li>Through influencers participation in brand’s campaigns</li>
							<li>Through shoppers sharing affiliate links with their family and friends</li>
						</ul>
					</Panel>
					<Panel header="How can a brand contact KBShop?" key="7">
						<p>
							Brands can email <a href="mailto:support@kbshop.com">support@kbshop.com</a>.
						</p>
					</Panel>
				</Collapse>
			</div>
			<h5 className="mt-4">For Influencers</h5>
			<div className="faq-container-ift">
				<Collapse
					accordion
					expandIconPosition={"end"}
					// expandIcon={({ isActive }) => <PlusOutlined rotate={isActive ? 90 : 0} />}
					expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
				>
					<Panel header="How do I sign up as influencer?" key="1">
						<p>
							You can sign up on the app or the platform at <NavLink to="/">kbshop.com</NavLink> and apply to become an influencer (if you meet the requirements of having at least 1,000 Instagram followers).
						</p>
					</Panel>
					<Panel header="After I join, is there any fees to pay?" key="2">
						<p>
							There are no contractual obligations. You can simply activate the brands which you like to promote and deactivate at any time. KBShop is totally FREE and you get commissions on total sales referred
							to the brand, ranging upto 50%.
						</p>
					</Panel>
					<Panel header="What’s the typical process to participate in brand campaigns?" key="3">
						<p>
							Sign-in, select categories and apply to brands for participation. If a brand accepts your request, select the campaigns to promote. Campaigns get added to your very own digital shop. You can also
							connect your Instagram, upload your own photos and add links to posts. You can promote the link of your digital shop to your Instagram bio and social profiles/posts to start driving traffic and earn
							commissions.
						</p>
					</Panel>
					<Panel header="How will I get my commission paid?" key="4">
						<p>All earnings of commission on sales from your referral will be paid via PayPal or check.</p>
					</Panel>
					<Panel header="What features will be available to me as an Influencer?" key="5">
						<p>
							<strong>As an Influencer, you will:</strong>
						</p>
						<ul>
							<li>Get your own customizable digital shop</li>
							<li>Connect your Instagram and add links to your favorite posts</li>
							<li>Upload your own photos and make them clickable</li>
							<li>Curate your shop with your affiliate links from other networks like CJ, Amazon, Rakuten, eBay etc.</li>
							<li>Add and monitor your participation in brand campaigns</li>
							<li>Get real-time analytics on your KBShop posts to see what’s working for you</li>
							<li>Track you earnings and performance of campaign participation</li>
							<li>Share your unique link across social channels and use it as a Link-in-Bio</li>
						</ul>
					</Panel>
					<Panel header="How can I communicate with brands?" key="7">
						<p>
							For all your queries email us at <a href="mailto:support@kbshop.com">support@kbshop.com</a> since direct contact with brands is not available.
						</p>
					</Panel>
				</Collapse>
			</div>
		</>
	);
};