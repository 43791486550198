import Layout from "./component/layout";
import Profile from "./component/profile";
export default () => {
	return (
		<>
			<Layout>
				<Profile />
			</Layout>
		</>
	);
};
