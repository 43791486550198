let count = 0;
export default function HeartLike() {
	let mainContainer = document.querySelector(".emoji-overlay");
	count++;
	if (count === 4) {
		count = 1;
	}
	let id = Math.random().toString(16).slice(2);
	let id1 = Math.random().toString(16).slice(2) + 1;
	let id2 = Math.random().toString(16).slice(2) + 2;
	let id3 = Math.random().toString(16).slice(2) + 3;
	let div = document.createElement("div");
	div.id = id;
	div.classList.add("emoji-batch");
	mainContainer.appendChild(div);
	let innerDiv = document.getElementById(id);
	let div1 = document.createElement("div");
	div1.classList.add("emoji-kind");
	if (count === 1) {
		div1.style.cssText = "right: -12px; animation-name: emoji-animation-1-horizontal; animation-duration: 4.07s; animation-delay: 0s;";
	} else if (count === 2) {
		div1.style.cssText = "right: -12px; animation-name: emoji-animation-2-horizontal; animation-duration: 4.07s; animation-delay: 0s;";
	} else if (count === 3) {
		div1.style.cssText = "right: -12px; animation-name: emoji-animation-3-horizontal; animation-duration: 4.07s; animation-delay: 0s;";
	}

	div1.id = id1;
	innerDiv.appendChild(div1);

	let innerDiv2 = document.getElementById(id1);
	let div2 = document.createElement("div");
	div2.classList.add("emoji-kind-vertical");

	if (count === 1) {
		div2.style.cssText = "animation-name: emoji-animation-1-vertical; animation-duration: 4.07s; animation-delay: 0s;";
	} else if (count === 2) {
		div2.style.cssText = "animation-name: emoji-animation-2-vertical; animation-duration: 4.07s; animation-delay: 0s;";
	} else if (count === 3) {
		div2.style.cssText = "animation-name: emoji-animation-3-vertical; animation-duration: 4.07s; animation-delay: 0s;";
	}

	div2.id = id2;
	innerDiv2.appendChild(div2);

	let innerDiv3 = document.getElementById(id2);
	let div3 = document.createElement("div");
	const externalHTML = `<svg
		xmlns="http://www.w3.org/2000/svg"
		width="30"
		height="30"
		viewBox="0 0 24 24"
		>
		<path
			style="fill: #f00;"
			d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"
		/>
		</svg>`;
	div3.classList.add("emoji-kind-opacity");
	div3.innerHTML = externalHTML;

	if (count === 1) {
		div3.style.cssText = "animation-name: emoji-animation-1-opacity; animation-duration: 4.07s; animation-delay: 0s;";
	} else if (count === 2) {
		div3.style.cssText = "animation-name: emoji-animation-2-opacity; animation-duration: 4.07s; animation-delay: 0s;";
	} else if (count === 3) {
		div3.style.cssText = "animation-name: emoji-animation-3-opacity; animation-duration: 4.07s; animation-delay: 0s;";
	}

	div3.id = id3;
	innerDiv3.appendChild(div3);

	if (document.getElementById(id)) {
		setTimeout(() => {
			document.getElementById(id).remove();
		}, 6000);
	}
}