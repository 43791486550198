import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { Modal } from "react-bootstrap";
import Profile from "../../../pages/bioShop/component/profile";
import Link from "../../../pages/bioShop/component/link";

const { TabPane } = Tabs;

export default ({ showProfileModal, setShowProfileModal }) => {
  return (
    <Modal
      size="lg"
      backdropClassName="custom-backdrop"
      show={showProfileModal}
      onHide={() => setShowProfileModal(false)}
      centered
      dialogClassName="profile-modal"
    >
      <Modal.Body>
        <span
          className="cst-close"
          onClick={() => {
            setShowProfileModal(false);
          }}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Profile" key="1">
            <Profile />
          </TabPane>
          <TabPane tab="Links" key="2">
            <Link />
          </TabPane>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
