import { GET_HOT_DEAL_REQUEST,GET_HOT_DEAL_SUCCESS,GET_HOT_DEAL_ERROR} from "../../types/types";
export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_HOT_DEAL_REQUEST:
			return {
				loading: true,
			};
		case GET_HOT_DEAL_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		case GET_HOT_DEAL_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
		}
}