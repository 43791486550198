import moment from "moment";
export const 	dateInDays = (end, start = moment().utc()) =>{
	end = moment(end).utc();
	const duration = moment.duration(moment(end).diff(moment(start)));
	//Get Days
	const months = Math.floor(duration.asMonths()); // .asDays returns float but we are interested in full days only
	const monthsFormatted = months ? `${months} month ` : `${months} months `; // if no full days then do not display it at all
	//Get Days
	const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
	const daysFormatted = parseInt(days) === 1 ? `${days} day` : `${days} days`; // if no full days then do not display it at all
	//Get Hours
	const hours = duration.hours();
	const hoursFormatted = parseInt(hours) === 1 ? `${hours} hour` : `${hours} hours`; 
	//Get Minutes
	const minutes = duration.minutes();
	const minutesFormatted =  parseInt(minutes) === 1 ? `${minutes} minute` : `${minutes} minutes`; ;
	let timer = monthsFormatted;

	if (parseInt(months) === 0 && parseInt(days) !== 0) {
		timer = daysFormatted;
	} else if (parseInt(days) === 0 && parseInt(hours) !==0)  {
		timer = hoursFormatted;
	} else if (parseInt(days) === 0 && parseInt(hours) === 0) {
		timer = minutesFormatted;
	}

	return [timer].join("");
	//	return [monthsFormatted, daysFormatted, hoursFormatted, minutesFormatted].join("");
};