import Layout from "./component/layout";
import Faqs from "./component/faqs";
export default () => {
	return (
		<>
			<Layout>
				<Faqs />
			</Layout>
		</>
	);
};
