import { GET_EVENT_VIEW_REQUEST,
    GET_EVENT_VIEW_SUCCESS,
    GET_EVENT_VIEW_ERROR, 
} from "../../types/types";
export default (state = [], action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_EVENT_VIEW_REQUEST:
			return {
				loading: true,
			};
		case GET_EVENT_VIEW_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_EVENT_VIEW_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
};