import { Button, Form, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getForgetPassword } from "../../redux/actions/forgetPassword.action";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
import Swal from "sweetalert2";
export const history = createBrowserHistory({ forceRefresh: true });

export default function ForgetPassword({ closeModal }) {
	const dispatch = useDispatch();
	const { forgetPassword } = useSelector((state) => {
		return state;
	});

	const onFinish = (values) => {
		dispatch(getForgetPassword(values.email)).then((res) => {
			if (res.payload.success) {
				Swal.fire({
					icon: "success",
					title: "Forgot Password Successfully Please check your email",
				});
				closeModal(false);
			}
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="login-signup-ift">
			<Form
				name="basic"
				labelCol={{
					span: 8,
				}}
				wrapperCol={{
					span: 24,
				}}
				// initialValues={{
				//   remember: true,
				// }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<h2>Forgot password?</h2>
				<p className="text-danger">{forgetPassword?.payload?.data?.message}</p>

				<p>We’ll send you a link to reset your password.</p>

				<Form.Item
					name="email"
					rules={[
						{
							required: true,
							message: "Please input your email",
						},
					]}
				>
					<Input placeholder="Email" size="large" />
				</Form.Item>

				<Form.Item
					wrapperCol={{
						offset: 0,
						span: 24,
					}}
				>
					{forgetPassword.loading ? (
						<Button type="primary" size="large" block loading>
							Loading
						</Button>
					) : (
						<Button type="primary" htmlType="submit" size="large" block>
							Reset Password
						</Button>
					)}
				</Form.Item>
			</Form>
		</div>
	);
}
