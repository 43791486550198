import { SHOPIFY_UPDATE_ATTRIBUTE_REQUEST, SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS, SHOPIFY_UPDATE_ATTRIBUTE_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const updateShopifyAttribute = (brand, event, source, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_UPDATE_ATTRIBUTE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/updateattribute`,
		headers: { Authorization: `Bearer ${token}` },

		data: {
			brand: brand,
			event: event,
			source: source.event ? source.event : 'eventshop',
			pixel_id: source?.pixel_id,
			publisher_id: source?.publisher_id,
			advertiser_id: source?.advertiser_id,
			event_id: source?.event_id,
			roiEvent: source?.roiEvent,
			brandKey: source?.brand,
			category_id: source?.category_id
		},
	})
		.then((response) => {
			return dispatch({

				type: SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_UPDATE_ATTRIBUTE_ERROR,
				payload: error.response,
			});
		});
};
