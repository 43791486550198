import { GET_FAVOURITE_LIST_REQUEST, GET_FAVOURITE_LIST_SUCCESS, GET_FAVOURITE_LIST_ERROR } from "../../types/types";
export default function brand(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_FAVOURITE_LIST_REQUEST:
			return {
				loading: true,
			};
		case GET_FAVOURITE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_FAVOURITE_LIST_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}