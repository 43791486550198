import Layout from "./component/layout";
import Wallet from "./component/wallet";
export default () => {
	return (
		<>
			<Layout>
				<Wallet />
			</Layout>
		</>
	);
};