import { GET_BANNER_REQUEST,GET_BANNER_SUCCESS,GET_BANNER_ERROR} from "../../types/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_BANNER_REQUEST:
			return {
				loading: true,
			};
		case GET_BANNER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_BANNER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
		}
	}
