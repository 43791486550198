import Layout from "./component/layout";
import CategoryPost from "./component/categoryPost";

export default () => {
	return (
		<>
			<Layout>
				<CategoryPost />
			</Layout>
		</>
	);
};
