import React, { useEffect, useState } from "react";

import ReactHlsPlayer from "react-hls-player";
import "bootstrap/dist/css/bootstrap.min.css";

export default React.memo (function HlsPlayer(props) {
	
	return <ReactHlsPlayer
	
	src={props?.streamUrl} autoPlay={true} controls={true} playsInline={true} fill width="100%" height="100%" />;
}
)