import {
  SHOPIFY_GET_CART_REQUEST,
  SHOPIFY_GET_CART_SUCCESS,
  SHOPIFY_GET_CART_ERROR,
} from "../../types/types";
export default (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOPIFY_GET_CART_REQUEST:
      return {
        loading: true,
      };
    case SHOPIFY_GET_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case SHOPIFY_GET_CART_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
};
