import { SHOPIFY_REMOVE_PROMO_CODE_REQUEST, SHOPIFY_REMOVE_PROMO_CODE_SUCCESS, SHOPIFY_REMOVE_PROMO_CODE_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const removePromoCode = (brand_id, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_REMOVE_PROMO_CODE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/removepromocode`,
		headers: { Authorization: `Bearer ${token}` },

		data: {
			brand: brand_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_REMOVE_PROMO_CODE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_REMOVE_PROMO_CODE_ERROR,
				payload: error.response,
			});
		});
};
