const CONTROLS = {
  mute: 'mute',
  close: 'close',
  resize: 'resize',
};

const POSITION = {
  topLeft: 'top-left',
  topRight: 'top-right',
  bottomRight: 'bottom-right',
  bottomLeft: 'bottom-left',
};

export { CONTROLS, POSITION };
