import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrum from "../../components/breadCrum/breadCrum";
import SubCategories from "../../components/subCategory/subCategory";
import Banner from "../../components/banner/banner1";
import CategoryFeatureBrand from "../../components/brand/categoryFeatureBrand";
import CategoryFavouriteBrand from "../../components/brand/categoryFavouriteBrand";
import SubCategoryBrand from "../../components/brand/subCategoryBrand";
import "../../css/category.scss";
import "react-multi-carousel/lib/styles.css";

export default () => {
	const { subCategory } = useSelector((state) => state);
	const params = useParams();
	let subCategoryName = subCategory?.payload?.parentData?.category_name;
	if (params?.category) {
		subCategoryName = subCategory?.payload?.data?.find((item) => item._id === params.category)?.category_name;
	}

	return (
		<>
			<BreadCrum categoryName={subCategoryName} />
			<SubCategories categoryName={subCategoryName} />
			<Banner category={params.category} categoryName={subCategoryName} type="subcategory" />
			<CategoryFeatureBrand category={params.category} type="subcategory" />
			<CategoryFavouriteBrand category={params.category} type="subcategory" />
			<SubCategoryBrand heading={`${subCategoryName ? subCategoryName : "Sub Category"} Brands`} />
		</>
	);
};
