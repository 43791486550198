import { POST_REGISTER_OPEN_REQUEST, POST_REGISTER_OPEN_SUCCESS, POST_REGISTER_OPEN_ERROR } from "../../types/types";
export default function AuthModal(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case POST_REGISTER_OPEN_REQUEST:
			return {
				loading: true,
			};
		case POST_REGISTER_OPEN_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			
			};

		case POST_REGISTER_OPEN_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
				
			};

		default:
			return state;
	}
}
