import React, { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tabs, Empty, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import Table from "react-bootstrap/Table";
import wallet1 from "../../../images/brand-logos/wallet-icon.svg";
import wallet2 from "../../../images/brand-logos/balance-icon.svg";
import { Modal } from "react-bootstrap";
import { getWithdrawRequest, postWithdrawRequest, getEarnCommission } from "../../../redux/actions/wallet.action";
import Swal from "sweetalert2";
import moment from "moment";
import numeral from "numeral";

const { TabPane } = Tabs;

export default () => {
	const dispatch = useDispatch();
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [paymentRequestModal, setPaymentRequestModal] = useState(false);
	const { withDrawRequest, commissionEarn } = useSelector((state) => state);

	const [allWithdrawRequest, setAllWithDrawRequest] = useState([]);
	const [pendingWithdrawRequest, setPendingWithDrawRequest] = useState([]);

	useEffect(() => {
		dispatch(getEarnCommission(userInfo?.token));
		dispatch(getWithdrawRequest(userInfo?.token, "All")).then((res) => {
			setAllWithDrawRequest(res);
		});
		dispatch(getWithdrawRequest(userInfo?.token, "Pending")).then((res) => {
			setPendingWithDrawRequest(res);
		});
	}, []);

	const onFinish = (values) => {
		dispatch(postWithdrawRequest(userInfo?.token, values.email, values.amount)).then((res) => {
			if (res.payload.success) {
				Swal.fire({
					icon: "success",
					title: "Withdraw Requeust Sent Successfully!",
				});
				setPaymentRequestModal(false);
				dispatch(getWithdrawRequest(userInfo?.token, "All")).then((res) => {
					setAllWithDrawRequest(res);
				});
				dispatch(getWithdrawRequest(userInfo?.token, "Pending")).then((res) => {
					setPendingWithDrawRequest(res);
				});

				dispatch(getEarnCommission(userInfo?.token));
			}
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<>
			<div className="account-left-content">
				<h1>My Wallet</h1>
				<div className="wallet-content-main">
					<div className="wallet-row-1">
						<div className="balance-box">
							<div className="inr-box-main">
								<div className="wallet-icon">
									<img src={wallet1} alt="" className="filter-gray" />
								</div>
								<div className="wallet-data">
									<div className="wallet-detail">
										<span className="box-title">Fee Earned</span>
										<div className="box-ammount">
											${commissionEarn?.payload?.data?.dateRangeEarningTotal ? numeral(commissionEarn?.payload?.data?.dateRangeEarningTotal).formate("0.00") : 0} {/* <span>July</span> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="balance-box">
							<div className="inr-box-main">
								<div className="wallet-icon">
									<img src={wallet2} alt="" className="filter-gray" />
								</div>
								<div className="wallet-data">
									<div className="wallet-detail">
										<span className="box-title">Total Balance</span>
										<div className="box-ammount">${commissionEarn?.payload?.data?.total_balance ? numeral(commissionEarn?.payload?.data?.total_balance).format("0.00") : 0}</div>
									</div>
									<div className="withdraw">
										<a
											onClick={() => {
												setPaymentRequestModal(true);
											}}
											href="javascript:void"
										>
											Withdraw
										</a>
										<a href="javascript:void">Link Account</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="withdraw-request">
						<h3>Withdraw Requests</h3>
						<div className="favourite-brands-container">
							<div>
								<Tabs defaultActiveKey="1">
									<TabPane tab="All Requests" key="1">
										<div className="purchase-data">
											<Table responsive="lg" bordered>
												<thead>
													<tr>
														<th>Date</th>
														<th>Amount</th>
														<th>Status</th>
													</tr>
												</thead>

												<tbody>
													{allWithdrawRequest?.loading ? (
														<div className="antd-loading-ift">
															<Spin tip="Loading..."></Spin>
														</div>
													) : (
														allWithdrawRequest?.payload?.message?.data.map((item) => {
															return (
																<tr>
																	<td>{moment(item.created_at).format("YYYY/MM/DD")}</td>
																	<td>${numeral(item?.amount).format("0.00")}</td>
																	<td>
																		<span className="status-success">{item?.status}</span>
																	</td>
																</tr>
															);
														})
													)}
												</tbody>
											</Table>
										</div>
									</TabPane>
									<TabPane tab="Pending" key="2">
										<div className="purchase-data">
											<Table responsive="lg" bordered>
												<thead>
													<tr>
														<th>Date</th>
														<th>Amount</th>
														<th>Status</th>
													</tr>
												</thead>
												<tbody>
													{pendingWithdrawRequest.loading ? (
														<div className="antd-loading-ift">
															<Spin tip="Loading..."></Spin>
														</div>
													) : (
														pendingWithdrawRequest?.payload?.message?.data.map((item) => {
															return (
																<tr>
																	<td>{moment(item.created_at).format("YYYY/MM/DD")}</td>
																	<td>${numeral(item?.amount).format("0.00")}</td>
																	<td>
																		<span className="status-success">{item?.status}</span>
																	</td>
																</tr>
															);
														})
													)}
												</tbody>
											</Table>
										</div>
									</TabPane>
									<TabPane tab="Successful" key="3">
										<div className="purchase-data">
											<Table responsive="lg" bordered>
												<thead>
													<tr>
														<th>Date</th>
														<th>Amount</th>
														<th>Status</th>
													</tr>
												</thead>

												<tbody>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-success">Successful</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-success">Successful</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-success">Successful</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-success">Successful</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-success">Successful</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-success">Successful</span>
														</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</TabPane>
									<TabPane tab="In Process" key="4">
										<div className="purchase-data">
											<Table responsive="lg" bordered>
												<thead>
													<tr>
														<th>Date</th>
														<th>Amount</th>
														<th>Status</th>
													</tr>
												</thead>

												<tbody>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-process">In Process</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-process">In Process</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-process">In Process</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-process">In Process</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-process">In Process</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-process">In Process</span>
														</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</TabPane>
									<TabPane tab="Failed" key="5">
										<div className="purchase-data">
											<Table responsive="lg" bordered>
												<thead>
													<tr>
														<th>Date</th>
														<th>Amount</th>
														<th>Status</th>
													</tr>
												</thead>

												<tbody>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-failed">Failed</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-failed">Failed</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-failed">Failed</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-failed">Failed</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-failed">Failed</span>
														</td>
													</tr>
													<tr>
														<td>8/11/2022</td>
														<td>$55.00</td>
														<td>
															<span className="status-failed">Failed</span>
														</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</TabPane>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				dialogClassName="signup-modal form-close-modal"
				size="md"
				backdropClassName="custom-backdrop"
				show={paymentRequestModal}
				// onHide={() => setLoginModal(false)}
				centered
				animation={false}
				backdrop={true}
				keyboard={false}
			>
				<Modal.Header>
					<button
						type="button"
						className="close"
						onClick={() => {
							setPaymentRequestModal(false);
						}}
					>
						<span aria-hidden="true">×</span>
						<span className="sr-only">Close</span>
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="login-signup-ift">
						<Form
							layout="vertical"
							name="basic"
							labelCol={{
								span: 8,
							}}
							wrapperCol={{
								span: 24,
							}}
							// initialValues={{
							//   remember: true,
							// }}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
						>
							<h2>Paypal</h2>
							<p className="text-danger">{withDrawRequest?.payload?.data?.message}</p>
							<Form.Item
								label="Enter Your Email"
								name="email"
								rules={[
									{
										required: true,
										message: "Please input your paypal email!",
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
							<Form.Item
								label="Enter amount"
								name="amount"
								rules={[
									{
										required: true,
										message: "Please input amount",
									},
								]}
							>
								<Input size="large" />
							</Form.Item>

							<Form.Item
								wrapperCol={{
									offset: 0,
									span: 24,
								}}
							>
								{withDrawRequest.loading ? (
									<Button type="primary" size="large" block>
										<Spin indicator={antIcon} />
									</Button>
								) : (
									<Button type="primary" htmlType="submit" size="large" block>
										Send Request
									</Button>
								)}
							</Form.Item>
						</Form>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};