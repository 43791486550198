import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getParentCategories } from "../../redux/actions/category.action";
import { Empty } from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/category.scss";
// import SkeletonLoader from "../loader/categoryLoader";

export default () => {
	const dispatch = useDispatch();
	// const { category } = useSelector((state) => state);
	const [loading, setLoading] = useState(false);
	const [allCategory, setAllCategory] = useState([]);

	useEffect(() => {
		dispatch(getParentCategories()).then((res) => {
			setAllCategory(res.payload.data);
			setLoading(true);
		});
	}, []);

	return (
		<>
			<div className="cat_brands_main all-cat-page">
				<div className="midd-width-ifti">
					<div className="cat_brands">
						<div className="pik-header">
							<h3>All Categories</h3>
						</div>
						<div className="category_slider">
							<div className="all_categories_ift">
								{loading === false ? (
									<>
										{new Array(24).fill().map((_, i) => {
											return (
												<div className="indv-categ">
													<Link to={"#"}>
														<div className="categ-boxes"></div>
														<h4>Loading</h4>
													</Link>
												</div>
											);
										})}
									</>
								) : (
									<>
										{allCategory.length === 0 ? (
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
										) : (
											<>
												{allCategory.map((item) => {
													return (
														<>
															<div className="indv-categ">
																<Link to={`/sub-category/${item._id}`}>
																	<div className="categ-boxes">
																		<img src={item.image_url} />
																	</div>
																	<h4>{item.category_name}</h4>
																</Link>
															</div>
														</>
													);
												})}
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};