export default () => {
	return (
		<>
			<h3>Terms of Service</h3>
			<h5>Welcome to KBShop!</h5>
			<p>
				These Terms of Service ("Terms") govern your use of the website, web app, and services (together or individually, "Service") offered by KONNECTBIO INC. ("KBShop", "we", "our", "us", "Company"). "You"
				refers to you as a user of the Service.
			</p>
			<p>
				Please read these terms carefully. Your access to and use of the Service is based on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use
				the Service.
			</p>
			<p>By accessing or using the Service, or otherwise indicating your acceptance, you agree to be bound by these Terms and accept all legal consequences. If you do not agree, please do not use the Service.</p>
			<h5>Community:</h5>
			<p>
				Our Service allows you to connect, share and communicate with other users of the Service by accessing the Community. You are responsible for the Content that you post or provide to the Community and
				KBShop does not control and assumes no responsibility for such Content or any Content posted or provided by other users of the Community. You agree to use the Community only to post or provide messages
				and materials that are appropriate and relevant to our Service.
			</p>
			<p>You will not submit, post, upload or display or distribute through the Community any messages or materials (including text, links, images, sounds, data, or other information) that will or may:</p>
			<ul className="list-simple">
				<li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others</li>
				<li>Infringe, misappropriate or violate intellectual or proprietary rights or breach confidentiality obligations</li>
				<li>Involve unsolicited advertising, junk mail, spam, surveys, contests, chain letters or any other form of unwanted solicitations</li>
				<li>Constitute or encourage conduct that is unlawful or would constitute a criminal offense, give rise to civil liability or otherwise violate any law or be objectionable or injurious to third-parties or</li>
				<li>Violate any code of conduct or other guidelines which may be applicable to the Community.</li>
			</ul>
			<p>
				While KBShop has no obligation to monitor the Community, we reserve all rights to review any Content posted or provided to the Community and remove any Content from the Community or refuse to post or
				provide any Content to the Community, in our sole discretion. KBShop reserves the right to restrict or terminate your access to the Community at any time, without notice, for any reason whatsoever.
				KBShop is not responsible and will have no liability for any removal of Content or restriction or termination of access to the Community or any failure or delay in removing Content or restricting or
				terminating access to the Community.
			</p>
			<h3>Account Terms</h3>
			<h5>Eligibility:</h5>
			<p>
				Our Service is intended only for access and use by individuals at least thirteen (13) years old. By accessing or using any of KBShop, you warrant and represent that you are at least thirteen (13) years of
				age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least thirteen (13) years old, you are prohibited
				from both access and usage of Service.
			</p>
			<h5>Account Creation:</h5>
			<p>
				When you create an account with us, you guarantee that you are above the age of 13, and that the information you provide us is accurate, complete, and current at all times. Failure to do so will cause
				unavailability of the Services.
			</p>
			<p>
				You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility
				for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of
				any breach of security or unauthorized use of your account.
			</p>
			<p>
				You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you,
				without appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene. We reserve the right to change, reclaim or transfer usernames that do not comply with these
				Terms or that are inactive.
			</p>
			<h5>Communications:</h5>
			<p>
				By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send. However, you may opt out of receiving any, or all, of these
				communications from us by following the unsubscribe link or by emailing us at <a href="mailto:support@kbshop.com">support@kbshop.com</a>
			</p>
			<h5>Prohibited Uses:</h5>
			<p>You may use Service only for lawful purposes and in accordance with Terms. You agree not to use Service:</p>
			<p>(a) In any way that violates any applicable national or international law or regulation.</p>
			<p>(b) For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
			<p>(c) To transmit, or procure the sending of, any advertising or promotional material, including any junk mail, chain letter, spam, or any other similar solicitation.</p>
			<p>(d) To impersonate or attempt to impersonate KBShop, a KBShop employee, another user, or any other person or entity.</p>
			<p>
				(e) In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
			</p>
			<p>
				(f) To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of Service, or which, as determined by us, may harm or offend KBShop or users of Service or expose them to
				liability.
			</p>
			<h3>Additionally, you agree not to:</h3>
			<p>
				(a) Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party's use of Service, including their ability to engage in real-time activities through
				Service.
			</p>
			<p>(b) Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>
			<p>(c) Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>
			<p>(d) Use any device, software, or routine that interferes with the proper working of Service.</p>
			<p>(e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
			<p>(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</p>
			<p>(g) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
			<p>(h) Take any action that may damage or falsify Company rating.</p>
			<p>(i) Otherwise attempt to interfere with the proper working of Service.</p>
			<h3>Fees and Payment</h3>
			<h5>Purchases:</h5>
			<p>
				If you wish to purchase any product or service made available through our Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your
				credit card number, the expiration date of your credit card, your billing address, and your shipping information.
			</p>
			<p>
				You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true,
				correct, and complete.
			</p>
			<p>
				We may employ the use of third party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to
				these third parties subject to our Privacy Policy.
			</p>
			<p>
				We reserve the right to refuse or cancel your order at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error
				in your order, or other reasons.
			</p>
			<p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>
			<h3>Subscriptions:</h3>
			<p>
				Some parts of our Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly
				or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
			</p>
			<p>
				At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it, or KBShop cancels it. You may cancel your Subscription renewal either
				through your online account management page or by contacting KBShop customer support team at <a hrf="mailto:support@kbshop.com">support@kbshop.com</a>.
			</p>
			<p>
				We reserve the right to revise the terms of your Subscription upon a renewal of your Subscription by giving reasonable prior notice of the change to give you an opportunity to cancel your Subscription before
				the change becomes effective upon the renewal. If you do not accept the change, you may cancel your Subscription as provided above. If you do not cancel your Subscription prior to the renewal, you will be
				bound by the revised terms of your Subscription
			</p>
			<p>
				A valid payment method, including credit card or PayPal, is required to process the payment for your subscription. You shall provide KBShop with accurate and complete billing information including full
				name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize KBShop to charge all Subscription
				fees incurred through your account to any such payment instruments.
			</p>
			<p>
				Should automatic billing fail to occur for any reason, we will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the
				billing period as indicated on the invoice.
			</p>
			<h5>Fee Changes:</h5>
			<p>KBShop in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
			<p>KBShop will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.</p>
			<p>Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.</p>
			<h5>Refunds:</h5>
			<p>Except when necessary by law, paid Subscription fees are non-refundable.</p>
			<h3>Content Policy</h3>
			<p>
				Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post to
				the Service, including its legality, reliability, and appropriateness.
			</p>
			<p>
				By posting, uploading or otherwise submit Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through
				the Service. You retain any and all of your rights to any Content you submit, post, upload or display on or through the Service and you are responsible for protecting those rights.
			</p>
			<p>
				You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting, uploading or
				submitting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.
			</p>
			<p>We reserve all rights to block or remove communications or materials that we determine to be:</p>
			<ul className="list-simple">
				<li>Abusive, defamatory, or obscene</li>
				<li>Fraudulent, deceptive, or misleading</li>
				<li>Violation of a copyright, trademark or, other intellectual property right of another or</li>
				<li>Offensive or otherwise unacceptable to us in our sole discretion.</li>
			</ul>
			<p>
				You acknowledge that, by providing you with the ability to view and distribute user-generated content on the Service, we are merely acting as a passive conduit for such distribution and is not undertaking any
				obligation or liability relating to any contents or activities on the Service.
			</p>
			<h5>Intellectual Property</h5>
			<p>
				All Intellectual Property Rights in the KBShop Service, the Website, and their content (excluding your Content, but including the KBShop Content), features and functionality are and will remain our
				(or our licensors’) exclusive property, and you will not challenge such ownership.
			</p>
			<p>
				The KBShop Service is protected by copyright, trade mark, and other laws of both United States of America and foreign countries. Our trademarks and trade names and ‘get up’ may not be used in connection
				with any product or service without our prior written consent. Nothing in these terms of service constitutes a transfer of any Intellectual Property Rights from us to you.
			</p>
			<p>
				You are permitted to use the KBShop Service only as authorized by us (which includes use in accordance with these terms and conditions). As a user, you are granted a limited, non-exclusive, revocable,
				non-transferable right to use the KBShop Service to create, display, use, play, and upload Content subject to these terms of service.
			</p>
			<p>You must not use the KBShop Service in connection with a product or service that is not affiliated with us or in any way brings us into disrepute.</p>
			<p>Any opinions, advice, statements, services, offers, or other information or content expressed or made available by any other users are those of the respective authors or distributors and not of us.</p>
			<p>If we provide you with any images, icons, video, graphics, or other content (KBShop Content) for you to use in connection with the KBShop Service, you must:</p>
			<p>(1) only use such KBShop Content on your Page and not anywhere else;</p>
			<p>(2) comply with any reasonable written guidelines or terms, which may be the guidelines or terms of a third party, in relation to the KBShop Content that we provide to you.</p>
			<h3>Third-Party Services</h3>
			<h5>Analytics:</h5>
			<p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
			<h5>Google Analytics:</h5>
			<p>
				Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with
				other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
			</p>
			<p>
				For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">privacy</a>
			</p>
			<p>
				We also encourage you to review the Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245">click here.</a>
			</p>
			<h5>Error Reporting and Feedback:</h5>
			<p>
				You may provide us either directly at <a href="mailto:support@kbshop.com">support@kbshop.com</a> or via third party sites and tools with information and feedback concerning errors, suggestions for
				improvements, ideas, problems, complaints, and other matters related to our Service ("Feedback"). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or
				other right, title, or interest in or to the Feedback; (ii) Company may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from
				you or any third party; and (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to
				applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited, and perpetual right to use (including copy, modify, create
				derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
			</p>
			<h5>Links to Other Web Sites:</h5>
			<p>Our Service may contain links to third party web sites or services that are not owned or controlled by KBShop.</p>
			<p>
				KBShop has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these
				entities/individuals or their websites.
			</p>
			<p>
				You acknowledge and agree that KBShop shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any
				such content, goods, or services available on or through any such third party web sites or services.
			</p>
			<p>We strongly advise you to read the terms of service and privacy policies of any third party web sites or services that you visit.</p>
			<h3>Legal Matters</h3>
			<h5>Disclaimer of Warranty:</h5>
			<p>
				These services are provided by KBShop on an as is and as available basis. KBShop makes no representations or warranties of any kind, express or implied, as to the operation of Service. You expressly
				agree that your use of Service is at your sole risk.
			</p>
			<p>
				Neither KBShop nor any person associated with KBShop makes any warranty or representation with respect to the completeness, security, reliability, quality, accuracy, or availability of Service, or
				that Service will be accurate, reliable, error-free, or uninterrupted, that defects will be corrected, that Service is free of viruses or other harmful components, or that Service will otherwise meet your
				needs or expectations.
			</p>
			<p>
				KBShop hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties or merchantability, non-infringement, and fitness for
				particular purpose.
			</p>
			<p>The foregoing does not affect any warranties which cannot be excluded or limited under applicable law.</p>
			<h5>Termination:</h5>
			<p>
				We may terminate or suspend your account and bar access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but
				not limited to a breach of Terms.
			</p>
			<p>If you wish to terminate your account, you may simply discontinue using Service.</p>
			<p>
				All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
				liability.
			</p>
			<h5>Limitation of Liability:</h5>
			<p>
				Except as prohibited by law, you will hold us and our officers, directors, employees, and agents harmless for any indirect, punitive, special, incidental, or consequential damage, however it arises (including
				attorneys' fees and all related costs and expenses of litigation and arbitration, or at trial or on appeal, if any, whether or not litigation or arbitration is instituted), whether in an action of contract,
				negligence, or other tortious action, or arising out of or in connection with this agreement, including without limitation any claim for personal injury or property damage, arising from this agreement and any
				violation by you of any federal, state, or local laws, statutes, rules or regulations, even if company has been previously advised of the possibility of such damage. Except as prohibited by law, if there is
				liability found on the part of the company, it will be limited to the amount paid for the products and/or services, and under no circumstances will there be consequential or punitive damages. Some states do
				not allow the exclusion or limitation of punitive, incidental, or consequential damages, so the prior limitation or exclusion may not apply to you.
			</p>
			<h5>Governing Law:</h5>
			<p>These Terms will be governed by, and interpreted and enforced in accordance with, the laws in the Ney York and in the laws of United States of America, as applicable.</p>
			<p>
				If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, then any remaining provisions of these Terms will remain in effect. These Terms constitute the
				entire agreement between KBShop and you regarding our Service, and supersede and replace any prior agreements, oral or otherwise, regarding the Service.
			</p>
			<h5>Changes to Service:</h5>
			<p>
				We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of
				Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
			</p>
			<h5>Amendment to Terms:</h5>
			<p>We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
			<p>
				Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as
				they are binding on you.
			</p>
			<p>
				By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use Service.
			</p>
			<h5>Waiver and Severability:</h5>
			<p>
				No waiver by Company of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Company
				to assert a right or provision under Terms shall not constitute a waiver of such right or provision.
			</p>
			<p>
				If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum
				extent such that the remaining provisions of Terms will continue in full force and effect.
			</p>
			<h5>Acknowledgement:</h5>
			<p>By using Service or other services provided by us, you acknowledge that you have read these Terms of Service and agree to be bound by them.</p>
			<h5>Contact us:</h5>
			<p>Please send your feedback, comments, and requests for technical support.</p>
			<p>
				KONNECTBIO INC.
				<br />
				121 Varick Street, 4th Floor
				<br /> New York, NY 10013
				<br /> <a href="mailto:support@kbshop.com">support@kbshop.com</a>
			</p>
		</>
	);
};