import { GET_MULTIPLE_SUB_CATEGORIES_REQUEST, GET_MULTIPLE_SUB_CATEGORIES_SUCCESS, GET_MULTIPLE_SUB_CATEGORIES_ERROR, GET_POPULAR_CATEGORIES_REQUEST, GET_POPULAR_CATEGORIES_SUCCESS, GET_POPULAR_CATEGORIES_ERROR, GET_PARENT_CATEGORIES_REQUEST, GET_PARENT_CATEGORIES_SUCCESS, GET_PARENT_CATEGORIES_ERROR, GET_SUB_CATEGORIES_REQUEST, GET_SUB_CATEGORIES_SUCCESS, GET_SUB_CATEGORIES_ERROR, GET_POPULAR_INFLUENCER_REQUEST, GET_POPULAR_INFLUENCER_SUCCESS, GET_POPULAR_INFLUENCER_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getParentCategories = () => (dispatch) => {
	dispatch({ type: GET_PARENT_CATEGORIES_REQUEST });

	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/parentCategories`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_PARENT_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_PARENT_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getSubCategories = (id) => (dispatch) => {
	dispatch({ type: GET_SUB_CATEGORIES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/subCategories`,
		headers: {
			Accept: "application/json",
		},
		data: {
			parent_id: id,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_SUB_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_SUB_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getMultipleSubCategories = (ids = []) => (dispatch) => {

		dispatch({ type: GET_MULTIPLE_SUB_CATEGORIES_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}public/events/getsubcategories`,
			headers: {
				Accept: "application/json",
			},
			data: {
				ids: ids,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_MULTIPLE_SUB_CATEGORIES_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_MULTIPLE_SUB_CATEGORIES_ERROR,
					payload: error.response,
				});
			});
	};

export const getPopularCategories = (page) => async (dispatch) => {
	dispatch({ type: GET_POPULAR_CATEGORIES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/getPopular`,
		headers: {
			Accept: "application/json",
		},
		data: {
			key: "category",
		},

		params: {
			limit: 12,
			page,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_POPULAR_CATEGORIES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_POPULAR_CATEGORIES_ERROR,
				payload: error.response,
			});
		});
};

export const getPopularInfluencer = (page) => async (dispatch) => {
	dispatch({ type: GET_POPULAR_INFLUENCER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/getPopular`,
		headers: {
			Accept: "application/json",
		},
		data: {
			key: "influencer",
		},
		params: {
			limit: 12,
			page,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_POPULAR_INFLUENCER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_POPULAR_INFLUENCER_ERROR,
				payload: error.response,
			});
		});
};
