export default function Detail({ event }) {
	return (
		<>
			<div className="brand-detail">
				<div className="author-name">
					<span className="auth-image">
						<img alt="" src={event&& event[0]?.brand[0]?.profile_image_url} />
					</span>
					<div className="auth-detail">
						<span className="auth-title">{event && event[0]?.title}</span>
						<span className="event-name">by {event && event[0]?.brand[0]?.brand_name}</span>
					</div>
				</div>
			</div>
			<div className="brand-description">
				<p>{event && event[0]?.notes}</p>
			</div>
		</>
	);
}
