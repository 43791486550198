//export const BASEURL = "http://172.16.1.157:9090/v1/";
//export const BASEURL = "http://172.16.1.85:9090/v1/";
//export const BASEURL = "https://kbserverdev.herokuapp.com/v1/";
 export const BASEURL = "https://apiv2.konnect.bio/v1/";
 //export const BASEURL1 = "https://apiv2.konnect.bio/";
 export const BASEURL1 = "https://apiv2.konnect.bio/";

 export const TEMPURL = 'https://kbsly.com/v1/';

 export const SOCKETURL = 'wss://v2-server-web-sockets1.herokuapp.com';
 