import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import outSideClick from "../../../helper/outSideClick";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";
import { getSubCategories } from "../../../redux/actions/category.action";
import Popup from "./popup.js";
import "react-multi-carousel/lib/styles.css";
import "../../../css/bioshop.scss";
import All from "./all";
const { Option } = Select;
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export default ({ children }) => {
	const [subCategory, setSubCategory] = useState([]);
	const dispatch = useDispatch();
	const { bioShopMenu } = useSelector((state) => {
		return state;
	});
	const [showCustomerModal, setsShowCustomerModal] = useState(false);
	const params = useParams();
	const componentWillUnmount = useRef(false);
	const [displayCategory, setDisplayCategory] = useState(false);
	const divRef = useRef();
	const handler = useCallback(() => setDisplayCategory(false), []);
	const [activeCategory, setActiveCategory] = useState("");
	const [activeSubCategory, setActiveSubCateogry] = useState("");
	const [filterPost, setFilterPost] = useState({
		categoryId: "",
		subCategoryId: "",
		postType: "image,campaign,video",
		sortBy: "",
	});
	outSideClick(divRef, handler);
	useEffect(() => {
		document.body.classList.add("bioshop-body");
		dispatch(getBioShopMenu(params.username, userInfo?.token));
	}, [params.username]);

	// This is componentWillUnmount
	useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
			document.body.classList.remove("bioshop-body");
		};
	}, []);

	return (
		<>
			<div className="bio_shop_main_ift">
				<div className="midd-width-ifti">
					<div className="bio_container_main">
						<div className="bio_shop_body">
							<div className="bioshop_filters_new">
								<div className="post-type">
									<label>Sort By</label>
									<Select
										onChange={(sortBy) => {
											setFilterPost({ ...filterPost, sortBy: sortBy });
										}}
										size="large"
										defaultValue=""
									>
										<Option value="">Date</Option>
										<Option value="topdiscount">Top Discount %</Option>
										<Option value="referralfee">Referral Fee</Option>
										<Option value="influencerfee">Influencer Fee</Option>
									</Select>
								</div>
								<div ref={divRef} className="post-type category-filter">
									<label>Categories</label>
									<span
										className="category-box"
										onClick={() => {
											if (!displayCategory) setDisplayCategory(true);
											else setDisplayCategory(false);
										}}
									>
										All Categories <DownOutlined />
									</span>
									{displayCategory && (
										<div className="category-box-all">
											<div className="all-inner">
												<div className="main-cat-left">
													<h3>Categories</h3>
													<div className="cat-filter-box">
														<button
															onClick={() => {
																setFilterPost({ ...filterPost, categoryId: "", parentId: "" });
																setActiveCategory("");
																setActiveSubCateogry("");
																setSubCategory([]);
															}}
															className={activeCategory === "" && "active"}
														>
															All
														</button>
														{bioShopMenu?.payload?.message?.data?.categories.map((item, i) => {
															return (
																<button
																	className={activeCategory === item.parent_id && "active"}
																	onClick={() => {
																		if (activeCategory === item.parent_id) {
																			setActiveCategory("");
																			setFilterPost({ ...filterPost, categoryId: "", parentId: "",subCategoryId: "" });
																		} else {
																			setActiveCategory(item.parent_id);
																			setFilterPost({ ...filterPost, categoryId: item.category_id, parentId: item.parent_id,subCategoryId: "" });
																		}

																		dispatch(getSubCategories(item?.parent_id)).then((res) => {
																			setSubCategory(res?.payload?.data);
																		});
																	}}
																	key={i}
																>
																	{item?.category_name}
																</button>
															);
														})}
													</div>
												</div>
												{subCategory?.length > 0 && (
													<div className="main-cat-right">
														<h3>Subcategories</h3>
														<div className="cat-filter-box">
															{subCategory?.map((item, key) => {
																return (
																	<button
																		className={activeSubCategory === item._id && "active"}
																		onClick={() => {
																			if (activeSubCategory === item._id) {
																				setActiveSubCateogry("");
																				setFilterPost({ ...filterPost, subCategoryId: "" });
																			} else {
																				setActiveSubCateogry(item._id);
																				setFilterPost({ ...filterPost, subCategoryId: item._id });
																			}
																		}}
																		key={key}
																	>
																		{item?.category_name}
																	</button>
																);
															})}
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>
								<div className="post-type">
									<label>Post Type</label>
									<Select
										onChange={(postType) => {
											setFilterPost({ ...filterPost, postType: postType });
										}}
										size="large"
										defaultValue="All"
									>
										<Option value="image,video,campaign">All</Option>
										<Option value="image">Simple Posts</Option>
										<Option value="video">Video Posts</Option>
									</Select>
								</div>
							</div>
							<All filterPost={filterPost} />
						</div>
					</div>
				</div>
				<Popup showCustomerModal={showCustomerModal} setsShowCustomerModal={setsShowCustomerModal} />
			</div>
		</>
	);
};
