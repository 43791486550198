import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { notification } from "antd";
export default function ({ showShare, setShowShare }) {
	const { url } = useSelector((state) => {
		console.log('state',state)
		return state;
	});
	const Urls = url?.payload?.message;

	const copyToClipboard = (url) => {
		let textField = document.createElement("textarea");
		textField.innerText = url;
		document.body.appendChild(textField);
		textField.select();
		document.execCommand("copy");
		textField.remove();
		notification.success({ message: "Copied to Clipboard!" });
		setShowShare(false);
	};

	return (
		<Modal
			backdropClassName="custom-backdrop"
			show={showShare}
			onHide={() => {
				setShowShare(false);
			}}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Share</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!url?.loading ? (
					<div className="your-copy-link">
						<div onClick={() => copyToClipboard(Urls)} className="item-a">
							<a target="_blank" rel="noreferrer" href={Urls}>
								{Urls}
							</a>
						</div>
						<div onClick={() => copyToClipboard(Urls)} className="item-b">
							Copy
						</div>
					</div>
				) : (
					<div>Loading....</div>
				)}
			</Modal.Body>
		</Modal>
	);
}
