import Layout from "./component/layout";
import Account from "./component/account";
export default () => {
	return (
		<>
			<Layout>
				<Account />
			</Layout>
		</>
	);
};
