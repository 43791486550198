import { ALL_PUBLISH_EVENT_REQUEST, ALL_PUBLISH_EVENT_SUCCESS, ALL_PUBLISH_EVENT_ERROR } from "../../types/types";
export default function allPublishEvent(state = [], action) {
	const { type, payload } = action;

	// console.log("old State", state.payload?.message?.data);
	// console.log("new state", payload?.message?.data);

	switch (type) {
		case ALL_PUBLISH_EVENT_REQUEST:
			return {
				loading: true,
			};
		case ALL_PUBLISH_EVENT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case ALL_PUBLISH_EVENT_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
}
