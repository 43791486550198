import { POST_COUNTRY_REQUEST, POST_COUNTRY_SUCCESS, POST_COUNTRY_ERROR,POST_SELECTED_COUNTRY_SUCCESS } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getCountry = () => (dispatch) => {
	dispatch({ type: POST_COUNTRY_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}common/receive/countries`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_COUNTRY_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_COUNTRY_ERROR,
				payload: error.response,
			});
		});
};

export const getSelectedCountry = (country) => (dispatch) => {
	return dispatch({
		type: POST_SELECTED_COUNTRY_SUCCESS,
		payload: country,
	});
};