import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Spin } from "antd";
import { getBioShopAllLink, getBioShopPostClick } from "../../../redux/actions/bioShop.action";
import { useSelector, useDispatch } from "react-redux";

export default () => {
	const dispatch = useDispatch();
	const { bioShopAllLink } = useSelector((state) => {
		return state;
	});
	const params = useParams();
	const userId = bioShopAllLink?.payload?.message?.user_id;

	useEffect(() => {
		dispatch(getBioShopAllLink(params.username));
	}, []);

	return (
		<>
			<div key={1} className="bioshop_links">
				<div key={1} className="all-links">
					{bioShopAllLink?.loading ? (
						<>
							<Spin tip="Loading..."></Spin>
						</>
					) : (
						<>
							{bioShopAllLink?.payload?.message?.result?.data?.length === 0 ? (
								<>
									<div className="no-data-found">
										<h1>Nothing Here Yet</h1>
										<p>{params.username} hasn't added anything.</p>
									</div>
								</>
							) : (
								<>
									{bioShopAllLink?.payload?.message?.result?.data.map((item, i) => {
										return (
											<>
												<Link
													key={i}
													to={"#"}
													onClick={() => {
														dispatch(getBioShopPostClick(item?.post_id, params.username, item?.media_url, item?.media_type, item?.caption, item?.created_at, userId, item?.post_type, item?.media_id));
														window.open(item.redirected_url);
													}}
												>
													{item.caption}
												</Link>
											</>
										);
									})}
								</>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};
