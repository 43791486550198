import { useEffect } from "react";
import { InputNumber, Space, Spin } from "antd";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { LoadingOutlined } from "@ant-design/icons";
import { getShopifyCart } from "../../../redux/actions/liveEvent.action";
import { setShippingAddress } from "../../../redux/actions/shopify.setShippingAddress.action";
import { getShippingRates } from "../../../redux/actions/shopify.getShippingRates.action";
import { removePromoCode } from "../../../redux/actions/shopify.removePromoCode.action";
// import {getCheckout} from "../../../redux/actions/shopify.getCheckout.action";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
export default function Cart({ promoCodeForm, onChangeShippingMethod, brandId, setStatus, cart, setCheckout, setCart, removeBtn }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const { checkoutProduct, removeCart, shopifyApplyPromoCode, shopifyRemovePromoCode, shopifySetShippingAddress } = useSelector((state) => state);
	return (
		<div className="pro-list-main pro-list-main-cart">
			<h2>Cart </h2>
			<div className="pro-list-inner list-gap">
				{removeCart?.loading || checkoutProduct?.loading ? (
					<div className="load-container">
						<Spin tip="Loading..."></Spin>
					</div>
				) : cart?.lineItems?.length === 0 || cart === undefined ? (
					<p className="cart-empty">Cart is Empty</p>
				) : (
					cart?.lineItems?.map((item) => {
						let size = item?.customAttributes?.filter((item) => item?.key === "Size").map((item) => item?.value);
						let inseam = item?.customAttributes?.filter((item) => item?.key === "Inseam").map((item) => item?.value);
						return (
							<div className="list-col">
								<div className="prod-box">
									<div className="prod-image">
										<img src={`${item?.variant?.image?.src}`} />
									</div>
									<div className="prod-name">
										<div className="prod-title">
											<span className="prod-name-inr">{item?.title}</span>
											<span className="prod-veriants-inr">{size[0] | inseam[0]}</span>
										</div>
										<div className="prod-price">
											<span className="prod-inr-price">
												<span>${item?.variant?.priceV2?.amount}</span>
												{/* <span className="original-price">{item.productAmount}</span> */}
											</span>
										</div>
										<div className="qty-rmv-btn">
											<Space>
												<InputNumber size="medium" min={1} max={100000} value={item?.quantity} defaultValue={1} />
											</Space>
											<a
												href="javascript:void"
												className="remove-btn"
												onClick={(e) => {
													removeBtn(item?.id);
												}}
											>
												Remove
											</a>
										</div>
									</div>
								</div>
							</div>
						);
					})
				)}
			</div>
			<div className="checkout-buttons">
				{cart?.lineItems?.length === 0 || checkoutProduct?.loading || cart === undefined ? (
					""
				) : (
					<>
						{/* <div className="grand-total">
							<span>Grand Total:</span>
							<span className="total-price">${cart?.totalPrice}</span>
						</div> */}

						{!cart?.discountApplications[0]?.applicable && <div className="checkout-discount-section cart-checkout-discount-section "> {promoCodeForm}</div>}
						<div className="checkout-order-total">
							{shopifyApplyPromoCode?.loading ? <Spin tip="Loading..."></Spin> : ""}
							{shopifyRemovePromoCode?.loading ? <Spin tip="Loading..."></Spin> : ""}
							<div className="subtotal">
								<span>Subtotal</span>
								<span>${cart?.lineItemsSubtotalPrice?.amount}</span>
							</div>
							{cart?.discountApplications[0]?.applicable && (
								<div className="discount-main">
									<div className="discount-left d-flex justify-content-start align-items-center">
										<div>discount</div>
										<i class="fa fa-tags" aria-hidden="true"></i>
										<p>{cart?.discountApplications[0]?.code}</p>
									</div>

									<div className="discount-right">
										<a
											onClick={() => {
												dispatch(removePromoCode(brandId, userInfo?.token)).then((res) => {
													dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
														if (res?.payload?.message?.lineItems.length > 0) {
															setStatus(false);
														} else {
															setStatus(true);
														}
														setCart(res?.payload?.message);
													});
												});
											}}
											href="#"
											className="remove-discount"
										>
											<i class="fa fa-times" aria-hidden="true"></i>
										</a>
										<p className="discount-txt">${cart?.discountApplications?.[0]?.value?.percentage ? parseFloat(cart?.lineItemsSubtotalPrice?.amount).toFixed() * (cart?.discountApplications?.[0]?.value?.percentage / 100) : cart?.discountApplications?.[0]?.value?.amount}</p>
									</div>
								</div>
							)}
							{cart?.shippingLine && (
								<div className="shipping">
									<span>Shipping</span>
									<span>${cart?.shippingLine?.priceV2?.amount}</span>
								</div>
							)}

							{cart?.totalTaxV2 && (
								<div className="shipping">
									<span>Tax</span>
									<span>${cart?.totalTaxV2.amount}</span>
								</div>
							)}

							<div className="grand-total">
								<span className="gr-total">Total</span>
								<span className="gr-t-price">${cart?.paymentDueV2?.amount}</span>
							</div>
						</div>
						{shopifySetShippingAddress.loading ? (
							<Button>
								<Spin indicator={antIcon} />
							</Button>
						) : (
							<Button
								onClick={() => {
									dispatch(setShippingAddress(brandId, userInfo.token)).then((res) => {
										if (res?.payload?.success) {
											if (!cart?.shippingAddress) {
												dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
													if (res?.payload?.message?.lineItems.length > 0) {
														setStatus(false);
													} else {
														setStatus(true);
													}
													setCart(res?.payload?.message);
												});
											}

											setCheckout(true);

											dispatch(getShippingRates(brandId, userInfo.token)).then((res) => {
												//alert(cart?.shippingLine.length)
												if (cart?.shippingLine?.title === undefined) onChangeShippingMethod("", res?.payload?.message?.data?.node?.availableShippingRates?.shippingRates[0]?.handle);
											});
										} else {
											Swal.fire({
												icon: "error",
												title: res?.payload?.data?.message,
											});
										}
									});
									// var newWindow = window.open();
									// dispatch(getCheckout(brandId, userInfo.token)).then((res) => {
									// 	setTimeout(() => {
									// 		newWindow.location = res?.payload?.message;
									// 	});
									// });
								}}
							>
								CHECKOUT
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	);
}
