import React, { useEffect, useState, useRef } from "react";
import { Tabs, Form, Input, Layout, Collapse, Radio, Checkbox, Tooltip, notification, Spin } from "antd";
import { Button, Modal } from "react-bootstrap";
import { SmileOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useSelector, useDispatch, connect } from "react-redux";
import LiveVideo from "../liveStreaming/liveStreaming";
import { getEventsBy_Id, getShopifyCart } from "../../redux/actions/liveEvent.action";
import { getEventViews } from "../../redux/actions/EventView.action";
import { getProduct } from "../../redux/actions/shopify.getProduct.action";
import { getAllShippingAddress } from "../../redux/actions/shipping.action";
import { addToCart } from "../../redux/actions/shopify.addToCart.action";
import { rmvCart } from "../../redux/actions/shopifyRemoveCart.action";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import basket from "../../images/brand-logos/basket.png";
import { isNullOrUndefined } from "url/util";
import FloatLabel from "../../components/floatLabel";
import ShippingAddressModal from "../account/component/shippingAddress";
import { SOCKETURL } from "../../config";
import Chat from "./component/chat";
import Detail from "./component/detail";
import Cart from "./component/cart";
import Shop from "./component/shop";
import Cookies from 'js-cookie'
import queryString from "query-string";
import "../../css/live-events.scss";
import "../../css/checkout.scss";
import closeicon from "../../images/brand-logos/close-icon.svg";
import { setShippingRates } from "../../redux/actions/shopify.setShippingRates.action";
import { applyPromoCode } from "../../redux/actions/shopify.applyPromo.action";
import { removePromoCode } from "../../redux/actions/shopify.removePromoCode.action";
import { setShippingAddress } from "../../redux/actions/shopify.setShippingAddress.action";
import { updateShopifyAttribute } from "../../redux/actions/shopify.updateAttribute.action";
import * as chatActions from "../../redux/actions/chat";
import { getChat } from "../../redux/actions/chat";
import { openLogin } from "../../redux/actions/login.action";
import useWebSocket from "react-use-websocket";
const { Panel } = Collapse;
const { TabPane } = Tabs;
let soruce1;
let titles1;
let tag1;
let price1;
let brand_id1;
let addBag = [];
let lists_item = [];
let tag = "https://dl1961trial.myshopify.com/23973099/checkouts/3063eb9af1269e7d245bd55bee2630ab?key=e08d0841d1a89ad19c0b444d6055d007";
const { Sider, Content } = Layout;

const LiveEventNew = ({ createChatToken }) => {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const { liveEventId, eventViews, shopifyProduct, allShippingAddress, shopifyGetShippingRates, shopifyApplyPromoCode, shopifyRemovePromoCode, shopifySetShippingAddress, shopifySetShippingRates, removeCart } = useSelector((state) => {
		return state;
	});

	const [height, setHeight] = useState(0);
	const ref = useRef();
	useEffect(() => {
	  const resizeObserver = new ResizeObserver((event) => {
		setHeight(event[0].contentBoxSize[0].blockSize);
	  });
  
	  if (ref) {
		resizeObserver.observe(ref.current);
	  }
	}, [ref]);

	const [eventId, setEvent] = useState([]);
	const [shippingAddressModal, setShippingAddressModal] = useState(false);
	const params = useParams();
	const event_id = params.id;
	const [activeKey, setActiveKey] = useState("3");
	const [collapseKey, setCollapseKey] = useState("2");
	// const [paymentActiveKey, setPaymentActiveKey] = useState("5");
	const [form] = Form.useForm();
	const [form1] = Form.useForm();
	const [cart, setCart] = useState();
	const [brandId, setBrandID] = useState("");
	const [checkout, setCheckout] = useState(false);
	const [checkStatus, setStatus] = useState(true);
	const [clear, setClear] = useState(false);
	const [product, setProduct] = useState();
	const [stp1, setStp1] = useState(true);
	const [stp2, setStp2] = useState(false);
	const [stp3, setStp3] = useState(false);
	const [lineItem, setLineItem] = useState([]);
	const [quantity, setQty] = useState(1);
	const [promoCode, setPromoCode] = useState("");
	const [collapsed, setCollapsed] = useState(false);
	const [value, setValue] = useState();
	const [chatStatus, setChatStatus] = useState(false);
	const [chat, setChat] = useState([]);

	const [promoError, setPromoError] = useState("");
	const [isShipTo, setIsShipTo] = useState("");

	//Socket
	const [viewCount, setViewCount] = useState("");
	// const URL = 'wss://apiv2.konnect.bio';
	// const ws = new WebSocket(URL);

	//SetCookies
	const queryParams = queryString.parse(window.location.search);
	const [eventAttributes, setEventAttributes] = useState({});

	useEffect(() => {
		if (queryParams.event === 'eventshare') {
			Cookies.set('event', JSON.stringify({
				'event': queryParams.event,
				'pixel_id': queryParams.pixel_id,
				'publisher_id': queryParams.publisher_id,
				'advertiser_id': queryParams.advertiser_id,
				'event_id': queryParams.event_id,
				'roiEvent': queryParams.roiEvent,
				'brand': queryParams.brand,
				'category_id': queryParams?.category_id
			}), { expires: 60 })

		}
		if (Cookies.get('event')) {
			setEventAttributes(JSON.parse(Cookies.get('event')))
		}
	}, [])



	useEffect(() => {
		document.body.classList.add("live-event-header-hide");
		return () => {
			document.body.classList.remove("live-event-header-hide");
		};
	}, []);

	useEffect(() => {
		if (window.innerWidth <= 1290) {
			setCollapsed(true);
		}
	}, []);

	const didUnmount = useRef(false);
	const { sendMessage, sendJsonMessage, lastMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(SOCKETURL, {
		onOpen: () => console.log("opened"),

		shouldReconnect: (closeEvent) => {
			/*
			useWebSocket will handle unmounting for you, but this is an example of a 
			case in which you would not want it to automatically reconnect
		  */
			return didUnmount.current === false;
		},
		reconnectAttempts: 10,
		reconnectInterval: 10000000,
	});
	useEffect(() => {
		dispatch(getEventViews(event_id, userInfo?.token)).then((res) => {
			getWebSocket().onmessage = (data) => {
				const views = JSON.parse(data?.data);
				setViewCount(views.content?.views ? views.content?.views : viewCount);
			};
			setTimeout(() => {
				getWebSocket().send(
					JSON.stringify({
						type: "liveEvent",
						content: {
							event_id,
							views: res.payload.message.stream.viewerCount,
						},
					})
				);
			}, 8000);

			// 	console.log("connected");
		});

		//ws.close()
		return () => {
			didUnmount.current = true;
		};
	}, []);

	useEffect(() => {
		if (event_id) {
			if (userInfo) {
				dispatch(getAllShippingAddress(userInfo?.token));
			}
			dispatch(getEventsBy_Id(event_id, userInfo?._id)).then((res) => {
				setEvent(res?.payload?.message);
				setBrandID(res?.payload?.message[0]?.brand[0]?._id);
				if (userInfo?.token) {
					dispatch(getShopifyCart(res?.payload?.message[0]?.brand[0]?._id, userInfo?.token)).then((res) => {
						if (res?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(res?.payload?.message);
						setValue(res?.payload?.message?.shippingLine?.handle);
					});
				}
			});
		}
	}, [event_id]);

	const close = () => {};
	const onFinishPromoCode = (values) => {
		let promo = values.promo;
		dispatch(applyPromoCode(brandId, promo, userInfo?.token)).then((res) => {
			if (res?.payload?.success === true) {
				setPromoCode("");
				setPromoError("");
				dispatch(getShopifyCart(brandId, userInfo?.token)).then((res1) => {
					if (res1?.payload?.message?.discountApplications[0].applicable) {
						notification.open({
							message: "Promo Applied",
							// description: "Added to cart",
							icon: <SmileOutlined style={{ color: "#108ee9" }} />,
						});
						if (res1?.payload?.message?.lineItems?.length > 0) {
							setStatus(false);
						} else {
							setStatus(true);
						}
						setCart(res1?.payload?.message);
					} else {
						setPromoCode("");
						setPromoError(`${promo} discount code isn’t available to you right now`);
					}
				});
			}
			if (res?.payload?.data?.success === false) {
				setPromoError(res?.payload?.data?.message);
			}
			form.resetFields();
		});
	};
	const onFailedPromoCode = () => {};
	const openNotification = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button
				type="primary"
				size="small"
				onClick={() => {
					notification.close(key);
					onKeyChange("4");
				}}
			>
				View Cart
			</Button>
		);
		notification.open({
			message: "ADDED TO CART",
			description: (
				<div className="added-cart">
					<div className="added-prod-image">
						<img src={product?.images[0]?.src} />
					</div>
					<div className="added-prod-name">
						<div className="added-prod-title">
							<span className="added-prod-name-inr">{product?.title}</span>
							{/* <span className="added-prod-veriants-inr">{

cart?.lineItems.map((item) => item?.customAttribtes?.filter((item) => item?.key === "Size").map((item) => item?.value))

				}</span> */}
						</div>
						<div className="added-prod-price">
							<span>${price1}</span>
						</div>
					</div>
				</div>
			),
			btn,
			key,
			onClose: close,
		});
	};

	const PromoCodeForm = () => {
		return (
			<>
				<Form onFinish={onFinishPromoCode} onFinishFailed={onFailedPromoCode} form={form} autoComplete="off" size="large" name="user_login" className="login-form form-style2 position-relative" layout="vertical">
					<FloatLabel label="" name="promo" value={promoCode}>
						<Form.Item
							name="promo"
							rules={[
								{
									required: true,
									message: "Please insert promo",
								},
							]}
						>
							<Input
								placeholder="Gift card or discount code"
								onChange={(e) => {
									if (e.target.value === "") {
										setPromoError("");
									}
								}}
							/>
						</Form.Item>
					</FloatLabel>

					<Button type="primary" htmlType="submit">
						<i className="fa fa-arrow-right" aria-hidden="true"></i>
					</Button>
				</Form>
				{promoError && (
					<div className="error-wrong-coupon-code">
						<div role="alert" className="ant-form-item-explain-error">
							{promoError}
						</div>
					</div>
				)}
			</>
		);
	};

	//	const onPaymentKeyChange = (key) => setPaymentActiveKey(key);

	const onKeyChange = (key) => {
		setActiveKey(key);
		setCheckout(false);

		if (key == "1" && eventId && userInfo?._id) {
			setChatStatus(true);
			createChatToken(event_id, userInfo._id, userInfo?.token);
			dispatch(getChat(event_id, userInfo?.token)).then((res) => {
				if (res.payload.success) {
					setChat(res.payload.message?.data);
				} else {
					notification.error({
						message: res.payload.data.message,
						className: "toast-error",
					});
				}
			});
		}

		if (key === "4") {
			if (userInfo) {
				dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
					if (res?.payload?.message?.lineItems.length > 0) {
						setStatus(false);
					} else {
						setStatus(true);
					}
					setCart(res?.payload?.message);
				});
			}
		}
	};

	const onCollapseChange = (key) => {
		setCollapseKey(key);
	};

	const buy = (brand_id, Product_id, price) => {
		setStp1(false);
		setStp2(true);
		price1 = price;
		brand_id1 = Product_id;
		dispatch(getProduct(brand_id, Product_id, userInfo?.token)).then((res) => {
			setProduct(res?.payload?.message);
		});
	};

	const addToBag = async (src, title, tag, clear, list) => {
		setStp1(false);
		setStp2(true);
		//setStp3(true);
		setClear(true);
		soruce1 = src;
		titles1 = title;
		tag1 = tag;

		list?.selectedOptions.map((item) => {
			lists_item.push({
				key: item.name,
				value: item.value,
			});
		});

		// setBrandID(brand_id1);
		dispatch(addToCart(brandId, checkStatus, quantity, list.id, lists_item, userInfo?.token)).then((res) => {
			if (res?.payload?.success) {
				openNotification();
				dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
					// notification.open({
					// 	message: "Added To Cart",
					// 	// description: "Added to cart",
					// 	icon: <SmileOutlined style={{ color: "#108ee9" }} />,
					// });

					if (res?.payload?.message?.lineItems.length > 0) {
						setStatus(false);
					} else {
						setStatus(true);
					}
					setCart(res?.payload?.message);
				});

				lists_item = [];
				soruce1 = "";
				titles1 = "";
				tag1 = "";
				brand_id1 = "";
				setQty(1);
			}
		});
	};

	if (!isNullOrUndefined(soruce1) && clear) {
		addBag.push({
			source: soruce1,
			title: titles1,
			price: price1,
		});
	}

	const shippingaddress = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 1.4,
		swipe: true,
		slidesToScroll: 1,
		arrows: false,
		responsive: [],
	};

	const ShipToAddress = () => {
		// let address = allShippingAddress?.payload?.message.filter((item) => item?.is_default === true)[0];
		return (
			<span className="contact-2">
				{cart?.shippingAddress?.address1},{cart?.shippingAddress?.address2} {cart?.shippingAddress?.province} {cart?.shippingAddress?.city} {cart?.shippingAddress?.zip}, {cart?.shippingAddress?.country}
			</span>
		);
	};

	const TabsShopping = () => {
		return (
			<>
				<Tabs className="tabs-stage-one" defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
					<TabPane tab="Chat" key="1">
						<div className="login-before-chat">
							{chatStatus ? (
								<Chat eventId={event_id} chat={chat} />
							) : (
								<>
									<div className="before-login-inner">
										<h1>Login to start chat</h1>
										<Button onClick={(e) => dispatch(openLogin(true))}>Login</Button>
									</div>
								</>
							)}
						</div>
					</TabPane>
					<TabPane tab="Details" key="2" activeKey={"3"}>
						<Detail event={eventId} />
					</TabPane>
					<TabPane tab="Shop" key="3">
						<Shop quantity={quantity} price={price1} setLineItem={setLineItem} addBag={addBag} setQty={setQty} clear={clear} brandId={brandId} shopifyProduct={shopifyProduct} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setClear={setClear} liveEventId={liveEventId} userInfo={userInfo} eventId={eventId} lineItem={lineItem} product={product} buy={buy} addToBag={addToBag} />
					</TabPane>
					<TabPane
						tab={
							<div className="basket-icon">
								<span className="cart-count">{cart === undefined ? 0 : cart?.lineItems?.length}</span>
								<img src={basket} />
							</div>
						}
						key="4"
					>
						{checkout ? <CheckoutStep /> : <Cart onChangeShippingMethod={onChangeShippingMethod} promoCodeForm={<PromoCodeForm />} removeBtn={removeBtn} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} brandId={brandId} setStatus={setStatus} setCart={setCart} setCheckout={setCheckout} cart={cart} />}
					</TabPane>
				</Tabs>
			</>
		);
	};

	const CheckoutStep = () => {
		return (
			<>
				<div className="checkout-frame">
					<div className="order-summery">
						<Collapse className="position-relative" accordion defaultActiveKey={["2"]} activeKey={collapseKey} onChange={onCollapseChange}>
							<span className="total-price-top">${cart?.paymentDueV2?.amount}</span>
							<Panel header="Order Summary" key="1">
								<div className="ordered-products">
									{removeCart?.loading ? <Spin tip="Loading..."></Spin> : ""}
									{cart?.lineItems?.map((item) => {
										let size = item?.customAttributes?.filter((item) => item?.key === "Size").map((item) => item?.value);
										let inseam = item?.customAttributes?.filter((item) => item?.key === "Inseam").map((item) => item?.value);
										return (
											<div className="list-col">
												<div className="prod-box">
													<div className="prod-image">
														<span className="pro-qty">{item?.quantity}</span>
														<img src={`${item?.variant?.image?.src}`} />
													</div>
													<div className="prod-name">
														<div className="prod-title">
															<span className="prod-name-inr">{item?.title}</span>
															<span className="prod-veriants-inr">{size[0] | inseam[0]}</span>
														</div>

														<div className="qty-rmv-btn">
															<div className="prod-price">
																<span className="prod-inr-price">
																	<span>${item?.variant?.priceV2?.amount}</span>
																</span>
															</div>
															<a
																href="javascript:void"
																className="remove-btn"
																onClick={(e) => {
																	removeBtn(item?.id);
																}}
															>
																Remove
															</a>
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</div>

								<div className="checkout-discount-section">{PromoCodeForm()}</div>
								<div className="checkout-order-total">
									{shopifyApplyPromoCode?.loading ? <Spin tip="Loading..."></Spin> : ""}
									{shopifyRemovePromoCode?.loading ? <Spin tip="Loading..."></Spin> : ""}
									<div className="subtotal">
										<span>Subtotal</span>
										<span>${cart?.lineItemsSubtotalPrice?.amount}</span>
									</div>

									{cart?.discountApplications[0]?.applicable && (
										<div className="discount-main">
											<div className="discount-left d-flex justify-content-start align-items-center">
												<div>discounts</div>
												<i class="fa fa-tags" aria-hidden="true"></i>
												<p>{cart?.discountApplications[0]?.code}</p>
											</div>

											<div className="discount-right">
												<a
													onClick={() => {
														dispatch(removePromoCode(brandId, userInfo?.token)).then((res) => {
															dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
																if (res?.payload?.message?.lineItems.length > 0) {
																	setStatus(false);
																} else {
																	setStatus(true);
																}
																setCart(res?.payload?.message);
															});
														});
													}}
													href="#"
													className="remove-discount"
												>
													<i class="fa fa-times" aria-hidden="true"></i>
												</a>
												<p className="discount-txt">${cart?.discountApplications?.[0]?.value?.percentage ? parseFloat(cart?.lineItemsSubtotalPrice?.amount).toFixed() * (cart?.discountApplications?.[0]?.value?.percentage / 100) : cart?.discountApplications?.[0]?.value?.amount}</p>
											</div>
										</div>
									)}
									{cart?.shippingLine && (
										<div className="shipping">
											<span>Shipping</span>
											<span>${cart?.shippingLine?.priceV2?.amount}</span>
										</div>
									)}

									{cart?.totalTaxV2 && (
										<div className="shipping">
											<span>Tax</span>
											<span>${cart?.totalTaxV2?.amount}</span>
										</div>
									)}
									<div className="grand-total">
										<span className="gr-total">Total</span>
										<span className="gr-t-price">${cart?.paymentDueV2?.amount}</span>
									</div>
								</div>
							</Panel>

							<Panel header="Shipping" key="2" className="accordion-style2">
								<div className="customer-information">
									<div className="checkout-stage-two">
										<div className="more-addresses">
											<h2>More Addresses</h2>

											{shopifySetShippingAddress?.loading && <Spin tip="Loading..."></Spin>}

											{allShippingAddress?.loading ? (
												<Spin tip="Loading..."></Spin>
											) : (
												allShippingAddress?.payload?.message?.length > 0 && (
													<Slider {...shippingaddress}>
														{!allShippingAddress?.loading &&
															allShippingAddress?.payload?.message?.map((item, key) => {
																return item?.is_default ? (
																	<div key={key} className={`address-box ${isShipTo === "" || isShipTo === item?._id ? "dflt" : ""}`}>
																		<div className="address-inner-detail">
																			<h4>Default</h4>
																			<span>{item?.address1}</span>
																			<span>{item?.address2}</span>
																			<span>
																				{item?.city}, {item?.zip}
																			</span>
																			<span> {item?.province}</span>
																		</div>
																		<span className="edit-address">
																			<i
																				onClick={() => {
																					setShippingAddressModal(true);
																				}}
																				className="fa fa-pencil-square-o"
																				aria-hidden="true"
																			></i>
																			<Tooltip title="Mark Shipping Address">
																				<Checkbox
																					checked={isShipTo === "" || isShipTo === item?._id ? true : false}
																					onChange={() => {
																						if (userInfo) {
																							dispatch(setShippingAddress(brandId, userInfo?.token, item?._id)).then((res) => {
																								if (res?.payload?.data?.success === false) {
																									Swal.fire({
																										icon: "error",
																										title: res?.payload?.data?.message,
																									});
																								} else {
																									setIsShipTo(item?._id);
																									dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
																										if (res?.payload?.message?.lineItems?.length > 0) {
																											setStatus(false);
																										} else {
																											setStatus(true);
																										}
																										setCart(res?.payload?.message);
																									});
																								}
																							});

																							// dispatch(updateDefaultShippingAddress(item._id, userInfo.token)).then(() => {
																							// 	dispatch(getAllShippingAddress(userInfo?.token));
																							// });
																						}
																					}}
																				></Checkbox>
																			</Tooltip>
																		</span>
																	</div>
																) : (
																	<div onClick={() => {}} key={key} className={`address-box ${isShipTo === item?._id ? "dflt" : ""}`}>
																		<div className="address-inner-detail">
																			<span>{item?.address1}</span>
																			<span>{item?.address2}</span>
																			<span>
																				{item?.city}, {item?.zip}
																			</span>
																			<span> {item?.province}</span>
																		</div>
																		<span className="edit-address">
																			<i
																				onClick={() => {
																					setShippingAddressModal(true);
																				}}
																				className="fa fa-pencil-square-o"
																				aria-hidden="true"
																			></i>
																			<Tooltip title="Mark Shipping Address">
																				<Checkbox
																					checked={isShipTo === item?._id}
																					onChange={() => {
																						if (userInfo) {
																							dispatch(setShippingAddress(brandId, userInfo?.token, item?._id)).then((res) => {
																								if (res?.payload?.data?.success === false) {
																									Swal.fire({
																										icon: "error",
																										title: res?.payload?.data?.message,
																									});
																								} else {
																									setIsShipTo(item?._id);
																									dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
																										if (res?.payload?.message?.lineItems?.length > 0) {
																											setStatus(false);
																										} else {
																											setStatus(true);
																										}
																										setCart(res?.payload?.message);
																									});
																								}
																							});

																							// dispatch(updateDefaultShippingAddress(item._id, userInfo.token)).then(() => {
																							// 	dispatch(getAllShippingAddress(userInfo?.token));
																							// });
																						}
																					}}
																				></Checkbox>
																			</Tooltip>
																		</span>
																	</div>
																);
															})}
													</Slider>
												)
											)}
										</div>
										<br></br>
										<div className="filed-detail">
											<div className="contact-email">
												<span className="contc-1">Contact</span>
												<span className="contact-2">{userInfo?.email}</span>
											</div>
											{cart?.shippingAddress && (
												<div className="contact-email shipp-email">
													<span className="contc-1">Ship to</span>
													{ShipToAddress()}
													{/* <a href="#">Change</a> */}
												</div>
											)}
											{shopifySetShippingRates?.loading ? (
												<Spin tip="Loading..."></Spin>
											) : (
												cart?.shippingLine && (
													<div className="contact-email shipp-email">
														<span className="contc-1">Method</span>
														<span className="contact-2">{cart?.shippingLine?.title}</span>
														{/* <a href="#">Change</a> */}
													</div>
												)
											)}
										</div>

										<div className="select-shipping-rate">
											<h2>Shipping Method</h2>
											<div className="shipping-box style2">
												{shopifyGetShippingRates?.loading ? (
													<Spin tip="Loading..."></Spin>
												) : (
													<Radio.Group onChange={onChangeShippingMethod} value={value}>
														{shopifyGetShippingRates?.payload?.message?.data?.node?.availableShippingRates?.shippingRates?.map((item, key) => {
															return (
																<Radio key={key} value={item?.handle}>
																	<div className="ship-rates">
																		<span>{item?.title}</span>
																	</div>
																	<span className="ship-rates-right">${item?.priceV2?.amount}</span>
																</Radio>
															);
														})}
													</Radio.Group>
												)}
											</div>
										</div>
										<div className="checkot-continue-btn">
											<Button
												onClick={() => {
													var newWindow = window;
													dispatch(updateShopifyAttribute(brandId, eventId[0]?._id, eventAttributes, userInfo?.token, eventId[0]?.category_id)).then((res) => {
														if (res?.payload.success) {
															newWindow.open(cart?.webUrl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=100000,width=542,height=1000");
															setActiveKey("3");
														}
													});

													//setCollapseKey("3");
												}}
											>
												Continue to Payment
											</Button>
										</div>
									</div>
								</div>
							</Panel>
						</Collapse>
					</div>
				</div>
			</>
		);
	};

	const onChangeShippingMethod = (e, handled) => {
		var handle = handled ? handled : e.target.value;
		setValue(handle);
		dispatch(setShippingRates(brandId, handle, userInfo?.token)).then((res) => {
			dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	const removeBtn = (rmvId) => {
		dispatch(rmvCart(brandId, rmvId, userInfo?.token)).then(() => {
			notification.open({
				message: "PRODUCT REMOVED",
				description: "Product has been removed from your cart",
				icon: <SmileOutlined style={{ color: "#108ee9" }} />,
			});
			dispatch(getShopifyCart(brandId, userInfo?.token)).then((res) => {
				if (res?.payload?.message?.lineItems.length > 0) {
					setStatus(false);
				} else {
					setStatus(true);
					setCheckout(false);
				}
				setCart(res?.payload?.message);
			});
		});
	};

	return (
		<>
			<div ref={ref} className="horizontal-camera-view">
				<Layout>
					<Layout className="site-layout">
						<Content
							className="site-layout-background"
							style={{
								margin: 0,
								padding: 0,
								minHeight: "calc(100vh - 40px)",
							}}
						>
							<LiveVideo eventViews={viewCount} onKeyChange={onKeyChange} stp1={stp1} stp2={stp2} stp3={stp3} setStp1={setStp1} setStp2={setStp2} setStp3={setStp3} setCollapsed={setCollapsed} collapsed={collapsed} id={event_id} />
						</Content>
					</Layout>
					<Sider
						trigger={null}
						collapsible
						collapsed={collapsed}
						breakpoint="lg"
						collapsedWidth="0"
						onBreakpoint={(broken) => {
							console.log(broken);
						}}
						onCollapse={(collapsed, type) => {
							console.log(collapsed, type);
						}}
					>
						<div className="slide-pannel-new">
							{!collapsed && (
								<span className="close-slide-panel" onClick={() => setCollapsed(!collapsed)}>
									<i className="fa fa-angle-right" aria-hidden="true"></i>
								</span>
							)}
							{window.innerWidth > 768 && TabsShopping()}
						</div>
					</Sider>
				</Layout>
			</div>
			{height && (
        <div className="mobile-checkout-panel" style={{ marginTop: height }}>
          {window.innerWidth <= 768 && TabsShopping()}
        </div>
      )}

			<Modal dialogClassName="modal-shipping-from-front" size="md" scrollable show={shippingAddressModal} onHide={() => setShippingAddressModal(false)} centered>
				<span className="close-shipping-front" onClick={() => setShippingAddressModal(false)}>
					<img src={closeicon} className="filter-gray" />
				</span>
				<Modal.Body>
					<ShippingAddressModal edit={true} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default connect(null, chatActions)(LiveEventNew);