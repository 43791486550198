import React from "react";
import BreadCrum from "../../components/breadCrum/breadCrum";
import Category from "../../components/category/category";
import Popular from "../../components/popularCategory/popularCategory";
import "../../css/category.scss";
import "react-multi-carousel/lib/styles.css";
export default () => {
	return (
		<>
			<BreadCrum CategoryName="Categories" />
			<Popular />
			<Category />
		</>
	);
};
