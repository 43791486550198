import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { Button, Form } from "antd";
import { DatePicker, Space, Spin } from "antd";
import { getPurchaseReport } from "../../../redux/actions/purchase.action";
import { useSelector, useDispatch } from "react-redux";
// import moment from "moment";

const { RangePicker } = DatePicker;

export default () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [dateType, setDateType] = useState("mtd");

  const { purchaseReport } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    if (userInfo.token) dispatch(getPurchaseReport(userInfo?.token));
  }, []);

  const onFinish = async () => {
    if (userInfo.token)
      dispatch(getPurchaseReport(userInfo?.token, startDate, endDate));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="account-left-content">
        <h1>Purchases</h1>
        <div className="purchase-content-main">
          {/* <div className="row">
            <Form.Item>
              <Button
                onClick={() => {
                  setDateType("dtd");
                  dispatch(
                    getPurchaseReport(
                      userInfo?.token,
                      moment().format("YYYY-MM-DD"),
                      moment().format("YYYY-MM-DD")
                    )
                  );
                }}
                className={`${dateType === "dtd" ? "active" : ""}`}
                htmlType="submit"
                size="large"
              >
                TODAY
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  setDateType("mtd");
                  dispatch(
                    getPurchaseReport(
                      userInfo?.token,
                      moment().startOf("month").format("YYYY-MM-DD"),
                      moment().endOf("month").format("YYYY-MM-DD")
                    )
                  );
                }}
                className={`${dateType === "mtd" ? "active" : ""}`}
                htmlType="submit"
                size="large"
              >
                MTD
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  setDateType("ytd");
                  dispatch(
                    getPurchaseReport(
                      userInfo?.token,
                      moment().startOf("year").format("YYYY-MM-DD"),
                      moment().endOf("month").format("YYYY-MM-DD")
                    )
                  );
                }}
                className={`${dateType === "ytd" ? "active" : ""}`}
                htmlType="submit"
                size="large"
              >
                YTD
              </Button>
            </Form.Item>
          </div> */}
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
            <div className="purchases-filter">
              <Space direction="vertical w-98">
                <Form.Item name="date">
                  <RangePicker
                    format={dateFormat}
                    onChange={(e, value) => {
                      setStartDate(value[0]);
                      setEndDate(value[1]);
                    }}
                    size="large w-98"
                  />
                </Form.Item>
              </Space>
              <Form.Item>
                <Button htmlType="submit" size="large">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
          <div className="purchase-data">
            {purchaseReport.length === 0 && (
              <span className="pr-plc-holder">Please Select Date </span>
            )}
            <Table responsive striped bordered hover size="md">
              {purchaseReport?.payload?.message?.data.length > 0 && (
                <thead>
                  <tr>
                    <th>Order#</th>
                    <th>Order Date</th>
                    <th>Brand</th>
                    <th>Actual Price</th>
                    <th>Discount</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {purchaseReport.loading ? (
                  <div className="antd-loading-ift">
                    <Spin tip="Loading..."></Spin>
                  </div>
                ) : purchaseReport?.payload?.message?.data.length === 0 ? (
                  "No Data Found "
                ) : (
                  purchaseReport?.payload?.message?.data.map((item) => {
                    return (
                      <tr>
                        <td>{item?.order_id}</td>
                        <td>{item?.created_date}</td>
                        <td>{item?.brand_name}</td>
                        <td>${item?.total_sale}</td>
                        <td>${item?.discount}</td>
                        <td>${item?.order_totalprice}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};