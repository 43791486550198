import Layout from "./component/layout";
import Commission from "./component/commission";
export default () => {
	return (
		<>
			<Layout>
				<Commission />
			</Layout>
		</>
	);
};
