import Layout from "./component/layout";
import ShippingAddress from "./component/shippingAddress";

export default () => {
	return (
		<>
			<Layout>
				<ShippingAddress />
			</Layout>
		</>
	);
};
