import { SHOPIFY_CART_REQUEST, SHOPIFY_CART_SUCCESS, SHOPIFY_CART_ERROR } from "../../types/types";
export default function shopifyaddToCart(state = "", action) {
	const { type, payload } = action;
	switch (type) {
		case SHOPIFY_CART_REQUEST:
			return {
				loading: true,
				success: true,
			};
		case SHOPIFY_CART_SUCCESS:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};

		case SHOPIFY_CART_ERROR:
			return {
				...state,
				loading: false,
				success: false,
				payload: payload,
			};

		default:
			return state;
	}
}