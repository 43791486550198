import React, { useState, useEffect } from "react";
// import "./broadcast.scss";
import IVSBroadcastClient, { Errors, BASIC_LANDSCAPE } from "amazon-ivs-web-broadcast";
export default () => {
  const [preview, setPreview] = useState(false);
  const client = IVSBroadcastClient.create({
    streamConfig: IVSBroadcastClient.BASIC_LANDSCAPE,
    ingestEndpoint: "rtmps://862720a0b228.global-contribute.live-video.net:443/app/",
  });

  useEffect(() => {
    handlePermissions();
  }, []);

  async function handlePermissions() {
    let permissions = {
      audio: false,
      video: false,
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      for (const track of stream.getTracks()) {
        track.stop();
      }
      permissions = { video: true, audio: true };
    } catch (err) {
      permissions = { video: false, audio: false };
      console.error(err.message);
    }
    // If we still don't have permissions after requesting them display the error message
    if (!permissions.video) {
      console.error("Failed to get video permissions.");
    } else if (!permissions.audio) {
      console.error("Failed to get audio permissions.");
    }
  }

  async function startBroadcast() {
    const videoParams = { video: true };
    const audioParams = { audio: true };

    window.cameraStream = await navigator.mediaDevices.getUserMedia(videoParams);
    window.microphoneStream = await navigator.mediaDevices.getUserMedia(audioParams);
    client.addVideoInputDevice(window.cameraStream, "camera1", { index: 0 });
    client.addAudioInputDevice(window.microphoneStream, "mic1");
    client.startBroadcast("sk_us-east-1_1BR7Vn3C1UYJ_MCN5LUX5nemhjVXg5zkgfbPZleDRpR")
      .then((result) => {
        const previewEl = document.getElementById("preview");
        client.attachPreview(previewEl);
      })
      .catch((error) => {
        console.error(
          "Something drastically failed while broadcasting!",
          error
        );
      });
  }
  const stopBroadcast = () => {
    client.stopBroadcast();
    client.removeVideoInputDevice("camera1");
    client.removeAudioInputDevice("mic1");
  };
  return (
    <>
      <div className="midd-width-ifti">
        <div className="live-cam">
          <canvas id="preview"></canvas>
          <div className="start-stop">
            <button className="btn btn-primary btn-sm" onClick={startBroadcast}>
              Start
            </button>
            <button className="btn btn-primary btn-sm" onClick={stopBroadcast}>
              Stop
            </button>
          </div>
        </div>
      </div>
    </>
  );
};