import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import "../../../css/account-setup.scss";

export default ({ children }) => {
  const { account } = useSelector((state) => state);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <>
      <div className="account-container-main">
        <div className="midd-width-ifti">
          <div className="account-inr-container">
            <div className="account-sidebar">
              <div className="sid-1">
                <span className="member-welcome">Welcome,</span>
                <span className="member-name">
                  {account?.payload?.Data?.name}
                </span>
                <span className="member-since">
                  {account?.payload?.Data?.pixel_id}
                </span>
                
                <span className="member-edit">
                  <NavLink to="/myaccount">
                    <i className="fa fa-pencil" aria-hidden="true"></i>Edit
                    Profile
                  </NavLink>
                </span>
              </div>
              <div className="sid-2">
                <ul>
                <li>
                    <NavLink to="/myaccount">
                      <span>Account Settings</span>
                    </NavLink>
                  </li> 
                <li>
                    <NavLink to="/payment">
                      <span>Payment Method</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/shippingaddress">
                      <span>Shipping Information</span>
                    </NavLink>
                  </li>
                  {userInfo?.account_type != "brand" && (
                    <>
                      <li>
                        <NavLink to="/purchase">
                          <span>My Purchases</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/earned-commission">
                          <span>My Earnings</span>
                        </NavLink>
                      </li>

                    </>
                  )}
                  
                  {userInfo?.account_type != "brand" && (
                    <li>
                      <NavLink to="/mywallet">
                        <span>My Wallet</span>
                      </NavLink>
                    </li>
                  )}

                  {userInfo?.account_type === "brand" && (
                    <li>
                      <NavLink to="/brandwallet">
                        <span>My Wallet</span>
                      </NavLink>
                    </li>
                  )}

                  {userInfo?.account_type !== "brand" && (
                    <li>
                      <NavLink to="/favourite">
                        <span>My Favourites</span>
                      </NavLink>
                    </li>
                  )}

                  {/* {userInfo?.account_type === "customer" && (
                    <li>
                      <NavLink to="/become-influencer">
                        <span>Become an Influencer</span>
                      </NavLink>
                    </li>
                  )} */}
                 
                </ul>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
