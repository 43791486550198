import { Button, Form, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { postLogin } from "../../redux/actions/login.action";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
export const history = createBrowserHistory({
	forceRefresh: true,
});
export default function Login({}) {
	const dispatch = useDispatch();
	const { login } = useSelector((state) => {
		return state;
	});

	const onFinish = (values) => {
		dispatch(postLogin(values)).then((res) => {
			// if (res?.payload?.message?.account_type === "customer") {
			if (res.payload.success) {
				localStorage.setItem("userInfo", JSON.stringify(res?.payload?.message));
				window.location.reload(false);
			}
			// } else {
			// 	if (res?.payload?.message?.sid) {
			// 		history.push(`https://app.kbshop.com/login/?sid=${res?.payload?.message?.sid}`);
			// 	}
			//}
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="login-signup-ift">
			<Form
				name="basic"
				labelCol={{
					span: 8,
				}}
				wrapperCol={{
					span: 24,
				}}
				// initialValues={{
				//   remember: true,
				// }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<h2>Sign In</h2>
				<p className="text-danger">{login?.payload?.data?.message}</p>

				<Form.Item
					name="username"
					rules={[
						{
							required: true,
							message: "Please input your username!",
						},
					]}
				>
					<Input placeholder="Username" size="large" />
				</Form.Item>

				<Form.Item
					name="password"
					rules={[
						{
							required: true,
							message: "Please input your password!",
						},
					]}
				>
					<Input.Password placeholder="Password" size="large" />
				</Form.Item>

				{/* <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 0,
            span: 24,
          }}
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

				<Form.Item
					wrapperCol={{
						offset: 0,
						span: 24,
					}}
				>
					{login.loading ? (
						<Button type="primary" size="large" block loading>
							Loading
						</Button>
					) : (
						<Button type="primary" htmlType="submit" size="large" block>
							Submit
						</Button>
					)}
				</Form.Item>
			</Form>
		</div>
	);
}
