import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import poster1 from "../../images/reviews/poster-1.jpg";
// import posteravt1 from "../../images/reviews/vid-1-avatar.jpg";

// import poster2 from "../../images/reviews/poster-2.jpg";
// import posteravt2 from "../../images/reviews/vid-2-avatar.jpg";

// import poster3 from "../../images/reviews/poster-3.jpg";
// import posteravt3 from "../../images/reviews/vid-3-avatar.jpg";

import poster1 from "../../images/reviews/ank-1.jpg";
import posteravt1 from "../../images/reviews/ank-1-avt.jpg";

import poster2 from "../../images/reviews/ank-2.jpg";
import posteravt2 from "../../images/reviews/ank-2-avt.jpg";

import poster3 from "../../images/reviews/ank-3.jpg";
import posteravt3 from "../../images/reviews/ank-3-avt.jpg";

import poster4 from "../../images/reviews/poster-4.jpg";
import posteravt4 from "../../images/reviews/vid-4-avatar.jpg";

import poster5 from "../../images/reviews/poster-5.jpg";
import posteravt5 from "../../images/reviews/vid-5-avatar.jpg";

import poster6 from "../../images/reviews/poster-6.jpg";
import posteravt6 from "../../images/reviews/vid-6-avatar.jpg";

export default () => {
  const liveevents = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 5.5,
    swipe: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4.2,
          swipe: true,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3.2,
          swipe: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          swipe: true,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <div className="bioshop-live-event-container">
          <div className="live-shows">
            <div className="event-container" id="influencers">
              <div className="event-header">
                <h3>Influencers Reviews</h3>
              </div>
              <Slider {...liveevents}>
                <div className="event-col-box">
                  <NavLink to="/influencer-reviews" className="live-vid-link">
                    <div className="vid-item">
                      <img src={poster1} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="vid-views">
                            <i className="fa fa-play" aria-hidden="true"></i>{" "}
                            59.5k
                          </span>
                        </div>
                        <div className="vid-title">
                          <div className="author-name">
                            <span className="auth-image">
                              <img src={posteravt1} />
                            </span>
                            <span className="auth-title">taylor_lovee</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="event-col-box">
                  <NavLink to="/influencer-reviews-2" className="live-vid-link">
                    <div className="vid-item">
                      <img src={poster2} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="vid-views">
                            <i className="fa fa-play" aria-hidden="true"></i>{" "}
                            59.5k
                          </span>
                        </div>
                        <div className="vid-title">
                          <div className="author-name">
                            <span className="auth-image">
                              <img src={posteravt2} />
                            </span>
                            <span className="auth-title">andreaslookbook</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="event-col-box">
                  <NavLink to="/influencer-reviews-3" className="live-vid-link">
                    <div className="vid-item">
                      <img src={poster3} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="vid-views">
                            <i className="fa fa-play" aria-hidden="true"></i>{" "}
                            59.5k
                          </span>
                        </div>
                        <div className="vid-title">
                          <div className="author-name">
                            <span className="auth-image">
                              <img src={posteravt3} />
                            </span>
                            <span className="auth-title">jessglistening</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="event-col-box">
                  <NavLink to="/influencer-reviews-4" className="live-vid-link">
                    <div className="vid-item">
                      <img src={poster4} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="vid-views">
                            <i className="fa fa-play" aria-hidden="true"></i>{" "}
                            59.5k
                          </span>
                        </div>
                        <div className="vid-title">
                          <div className="author-name">
                            <span className="auth-image">
                              <img src={posteravt4} />
                            </span>
                            <span className="auth-title">janinegraffqvc</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="event-col-box">
                  <NavLink to="/influencer-reviews-5" className="live-vid-link">
                    <div className="vid-item">
                      <img src={poster5} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="vid-views">
                            <i className="fa fa-play" aria-hidden="true"></i>{" "}
                            59.5k
                          </span>
                        </div>
                        <div className="vid-title">
                          <div className="author-name">
                            <span className="auth-image">
                              <img src={posteravt5} />
                            </span>
                            <span className="auth-title">
                              shawnkillingerqvc
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="event-col-box">
                  <NavLink to="/influencer-reviews-6" className="live-vid-link">
                    <div className="vid-item">
                      <img src={poster6} />
                      <div className="vid-item-detail">
                        <div className="vid-time">
                          <span className="vid-views">
                            <i className="fa fa-play" aria-hidden="true"></i>{" "}
                            59.5k
                          </span>
                        </div>
                        <div className="vid-title">
                          <div className="author-name">
                            <span className="auth-image">
                              <img src={posteravt6} />
                            </span>
                            <span className="auth-title">cyndewatson</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
