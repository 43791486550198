import { POST_STATE_REQUEST, POST_STATE_SUCCESS, POST_STATE_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getState = (countryCode) => (dispatch) => {
	dispatch({ type: POST_STATE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}common/receive/states`,
		headers: {
			Accept: "application/json",
		},
		data: {
			country_code: countryCode,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_STATE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_STATE_ERROR,
				payload: error.response,
			});
		});
};
