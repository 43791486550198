import {
	GET_REMOVE_FAVOURITE_LIST_REQUEST,
	GET_REMOVE_FAVOURITE_LIST_SUCCESS,
	GET_REMOVE_FAVOURITE_LIST_ERROR,
	GET_REMOVE_FAVOURITE_REQUEST,
	GET_REMOVE_FAVOURITE_SUCCESS,
	GET_REMOVE_FAVOURITE_ERROR,
	GET_ADD_TO_FAVOURITE_REQUEST,
	GET_ADD_TO_FAVOURITE_SUCCESS,
	GET_ADD_TO_FAVOURITE_ERROR,
	GET_FAVOURITE_LIST_REQUEST,
	GET_FAVOURITE_LIST_SUCCESS,
	GET_FAVOURITE_LIST_ERROR,
	GET_FAVOURITE_BRAND_LIST_REQUEST,
	GET_FAVOURITE_BRAND_LIST_SUCCESS,
	GET_FAVOURITE_BRAND_LIST_ERROR,
	GET_REMOVE_FAVOURITE_BRAND_REQUEST,
	GET_REMOVE_FAVOURITE_BRAND_SUCCESS,
	GET_REMOVE_FAVOURITE_BRAND_ERROR,
	GET_ADD_TO_FAVOURITE_BRAND_REQUEST,
	GET_ADD_TO_FAVOURITE_BRAND_SUCCESS,
	GET_ADD_TO_FAVOURITE_BRAND_ERROR,
	GET_FAVOURITE_INFLUENCER_LIST_REQUEST,
	GET_FAVOURITE_INFLUENCER_LIST_SUCCESS,
	GET_FAVOURITE_INFLUENCER_LIST_ERROR
} from "../types/types";

import { BASEURL } from "../../config";
import axios from "axios";

export const getFavouriteList = (token, postId, childrenId) => (dispatch) => {
	dispatch({ type: GET_FAVOURITE_LIST_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/getFavouritePostUserList?page=1&limit=100`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			post_id: postId,
			children_id: childrenId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_FAVOURITE_LIST_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FAVOURITE_LIST_ERROR,
				payload: error.response,
			});
		});
};

export const getFavouriteBrandList = (token) => (dispatch) => {
	dispatch({ type: GET_FAVOURITE_BRAND_LIST_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/getFavourite`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			account_type: 'brand',
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_FAVOURITE_BRAND_LIST_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FAVOURITE_BRAND_LIST_ERROR,
				payload: error.response,
			});
		});
};


export const getFavouriteInfluencerList = (token) => (dispatch) => {
	dispatch({ type: GET_FAVOURITE_INFLUENCER_LIST_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/getFavourite`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			account_type: 'influencer',
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_FAVOURITE_INFLUENCER_LIST_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_FAVOURITE_INFLUENCER_LIST_ERROR,
				payload: error.response,
			});
		});
};

export const addToFavourite = (token, postId, childrenId) => (dispatch) => {
	dispatch({ type: GET_ADD_TO_FAVOURITE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/addToFavouriteUserList`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			post_id: postId,
			children_id: childrenId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ADD_TO_FAVOURITE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ADD_TO_FAVOURITE_ERROR,
				payload: error.response,
			});
		});
};


export const addToFavouriteBrand = (token,favourite_id) => (dispatch) => {
	dispatch({ type: GET_ADD_TO_FAVOURITE_BRAND_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/addFavourite`,	
		headers: { Authorization: `Bearer ${token}` },
		data: {
			favourite_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ADD_TO_FAVOURITE_BRAND_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ADD_TO_FAVOURITE_BRAND_ERROR,
				payload: error.response,
			});
		});
};

export const removeFavouritePost = (token, postId, childrenId) => (dispatch) => {
	dispatch({ type: GET_REMOVE_FAVOURITE_REQUEST });
	return axios({
		method: "POST",

		url: `${BASEURL}mobile/private/removeFavouritePostUserList`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			post_id: postId,
			children_id: childrenId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_REMOVE_FAVOURITE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_REMOVE_FAVOURITE_ERROR,
				payload: error.response,
			});
		});
};

export const removeFavouriteList = (token, postId) => (dispatch) => {
	dispatch({ type: GET_REMOVE_FAVOURITE_LIST_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/removeParentFavouritePost`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			post_id: postId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_REMOVE_FAVOURITE_LIST_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_REMOVE_FAVOURITE_LIST_ERROR,
				payload: error.response,
			});
		});
};

export const removeFavouriteBrandList = (token, _id) => (dispatch) => {
	dispatch({ type: GET_REMOVE_FAVOURITE_BRAND_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/removeFavourite`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			_id,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_REMOVE_FAVOURITE_BRAND_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_REMOVE_FAVOURITE_BRAND_ERROR,
				payload: error.response,
			});
		});
};