import Layout from "./component/layout";
import Favourite from "./component/favourite";
export default () => {
	return (
		<>
			<Layout>
				<Favourite />
			</Layout>
		</>
	);
};
