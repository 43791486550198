import React, { useState, useEffect } from "react";
import { Button, Form } from "antd";
import { Input, Checkbox, Spin } from "antd";
import "../../../css/account-setup.scss";
import FloatLabel from "../../../components/floatLabel";
import Swal from "sweetalert2";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { createCreditCard, getAllCreditCard, updateDefaultCard } from "../../../redux/actions/payment.action";

export default function Payment({ cart }) {
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const dispatch = useDispatch();
	const { addCreditCard, allCreditCard, defaultCard } = useSelector((state) => state);
	const [addCard, setAddCard] = useState(false);
	const [cardNumber, setCardNumber] = useState("");
	const [expiryDate, setExpiryDate] = useState("");
	const [cvc, setCvc] = useState("");
	const [zipCode, setZipCode] = useState("");
	//Billing Address
	const [checked, setChecked] = useState(true);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [streetAddress, setStreetAddress] = useState("");
	const [apartment, setApartment] = useState("");
	const [billingZipCode, setBillingZipCode] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [form] = Form.useForm();
	const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

	useEffect(() => {
		dispatch(getAllCreditCard(userInfo?.token));
	}, []);

	useEffect(() => {
		if(cart){
			setAddCard(true)
		}

	}, [cart]);

	const onFinish = async (values) => {


		if (userInfo.token) {
			dispatch(createCreditCard(values?.cardNumber, values?.expirtyDate, values?.cvc, userInfo?.token)).then(() => {
				dispatch(getAllCreditCard(userInfo?.token));
				form.resetFields();
				window.scrollTo(0, 0);
				setAddCard(false);
				Swal.fire({
					icon: "success",
					title: "Credit card added successfully!",
				});
			});
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<>
			<div className="account-left-content">
				<h1>Payment Method</h1>
				<div className="account-detail">
					<div className="card-information-store">
						<div className={`added-cards ${addCard ? "d-none" : "d-block"}`}>
							{allCreditCard?.loading ? (
								<Spin indicator={antIcon} />
							) : (
								allCreditCard?.payload?.message?.map((item, key) => {
									return (
										<span
											key={key}
											onClick={() => {
												dispatch(updateDefaultCard(item?.card_number, userInfo?.token)).then(() => {
													Swal.fire({
														icon: "success",
														title: "Default card set successfully!",
													});
													dispatch(getAllCreditCard(userInfo?.token));
												});
											}}
											className="add-card-detail"
										>
											<i class="fa fa-cc-visa" style={{ color: "#00ADEF" }} aria-hidden="true"></i>

											{defaultCard?.loading ? <Spin indicator={antIcon} /> : "****" + item.card_number.substr(-4)}

											<span className="selected-cradit-card">
												<i class={`fa ${item.is_default ? "fa-check-square-o" : ""}`} aria-hidden="true"></i>
											</span>
										</span>
									);
								})
							)}
						</div>
						{!cart && (
							<span
								className="add-card-detail"
								onClick={() => {
									setAddCard(true);
									window.scrollTo(0, 0);
								}}
							>
								<i class="fa fa-plus" aria-hidden="true"></i> Add Credit Card
							</span>
						)}
						<Form onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" form={form} size="large" name="user_login" className={`login-form ${addCard ? "d-block" : "d-none"}`} layout="vertical">
							<div className="cd-detail-box">
								<FloatLabel label="Card Number" name="cardNumber" value={cardNumber}>
									<Form.Item
										name="cardNumber"
										rules={[
											{
												required: true,
												message: "Please insert your credit card",
											},
											{
												message: "Please enter valid credit card",
												pattern: new RegExp(/^[0-9]+$/),
											},
										]}
									>
										<Input value={cardNumber} size="large" placeholder="1234 1234 1234 1234" onChange={(e) => setCardNumber(e.target.value)} maxLength={22} />
									</Form.Item>
								</FloatLabel>
							</div>
							<div className="cd-detail-box cd-detail-box-gap">
								<div className="cd-2col">
									<FloatLabel label="Expiry Date" name="expiryDate" value={expiryDate}>
										<Form.Item
											name="expirtyDate"
											rules={[
												{
													required: true,
													message: "Please insert expiry date",
												},
												{
													message: "Please enter valid expiry date",
													pattern: new RegExp(/^[0-9]+$/),
												},
											]}
										>
											<Input maxLength={4} value={expiryDate} size="large" placeholder="MM / YY" onChange={(e) => setExpiryDate(e.target.value)} />
										</Form.Item>
									</FloatLabel>
								</div>
								<div className="cd-2col">
									<FloatLabel label="CVC" name="cvc" value={cvc}>
										<Form.Item
											name="cvc"
											rules={[
												{
													required: true,
													message: "Please insert CVC",
												},
												{
													message: "Please enter valid CVC",
													pattern: new RegExp(/^[0-9]+$/),
												},
											]}
										>
											<Input value={cvc} size="large" placeholder="CVC" onChange={(e) => setCvc(e.target.value)} maxLength={3} />
										</Form.Item>
									</FloatLabel>
								</div>
							</div>
							<div className="cd-detail-box">
								<FloatLabel label="Zip Code" name="zipCode" value={zipCode}>
									<Form.Item
										name="zipCode"
										rules={[
											{
												required: true,
												message: "Please insert zip code",
											},
											{
												message: "Please enter valid zip code",
												pattern: new RegExp(/^[0-9]+$/),
											},
										]}
									>
										<Input value={zipCode} size="large" placeholder="Zip Code" onChange={(e) => setZipCode(e.target.value)} maxLength={4} />
									</Form.Item>
								</FloatLabel>
							</div>
							<div className="cd-detail-box">
								<Form.Item name="isShippingAddress">
									<Checkbox
										checked={checked}
										onChange={(e) => {
											setChecked(e.target.checked);
										}}
									>
										The billing address is the same as the shipping address
									</Checkbox>
								</Form.Item>
							</div>
							<div className={`cd-detail-box  ${checked ? "d-block" : "d-none"}`}>
								{addCreditCard.loading ? (
									<Button>
										<Spin indicator={antIcon} />
									</Button>
								) : (
									<Button htmlType="submit">Save</Button>
								)}
							</div>
							<div className={`billing-address-store ${checked ? "d-none" : "d-block"}`}>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="First Name" name="firstName" value={firstName}>
											<Form.Item
												name="firstName"
												rules={[
													{
														required: !checked ? true : false,
														message: "Please insert first name",
													},
												]}
											>
												<Input value={firstName} size="large" onChange={(e) => setFirstName(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Last Name" name="lastName" value={lastName}>
											<Form.Item
												name="lastName"
												rules={[
													{
														required: !checked ? true : false,
														message: "Please insert first name",
													},
												]}
											>
												<Input value={lastName} size="large" onChange={(e) => setLastName(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box">
									<FloatLabel label="Street Address" name="streetAddress" value={streetAddress}>
										<Form.Item
											name="streetAddress"
											rules={[
												{
													required: !checked ? true : false,
													message: "Please insert street address",
												},
											]}
										>
											<Input value={streetAddress} size="large" onChange={(e) => setStreetAddress(e.target.value)} />
										</Form.Item>
									</FloatLabel>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="APT/UNIT#" name="apartment" value={apartment}>
											<Form.Item
												name="apartement"
												rules={[
													{
														required: !checked ? true : false,
														message: "Please insert apartement",
													},
												]}
											>
												<Input value={apartment} size="large" onChange={(e) => setApartment(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="Zip Code" name="billingZipCode" value={billingZipCode}>
											<Form.Item
												name="billingZipCode"
												rules={[
													{
														required: !checked ? true : false,
														message: "Please insert zip code",
													},
												]}
											>
												<Input value={billingZipCode} size="large" onChange={(e) => setBillingZipCode(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box cd-detail-box-gap">
									<div className="cd-2col">
										<FloatLabel label="City" name="city" value={city}>
											<Form.Item
												name="city"
												rules={[
													{
														required: !checked ? true : false,
														message: "Please insert city",
													},
												]}
											>
												<Input value={city} size="large" onChange={(e) => setCity(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
									<div className="cd-2col">
										<FloatLabel label="State" name="state" value={state}>
											<Form.Item
												name="billingZipCode"
												rules={[
													{
														required: !checked ? true : false,
														message: "Please insert zip code",
													},
												]}
											>
												<Input value={state} size="large" onChange={(e) => setState(e.target.value)} />
											</Form.Item>
										</FloatLabel>
									</div>
								</div>
								<div className="cd-detail-box">
									<Button htmlType="submit">Save</Button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
