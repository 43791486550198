import {
    GET_CHAT_TOKEN
  } from "../../types/types";
  
  export default function city(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_CHAT_TOKEN:
          return payload
      default:
        return state;
    }
  }
  