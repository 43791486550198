import { GET_CHANGE_PASSWORD_REQUEST, GET_CHANGE_PASSWORD_SUCCESS, GET_CHANGE_PASSWORD_ERROR, POST_PASSWORD_MODAL_OPEN_SUCCESS } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getChangePassword = (user_id, currentPassword, newPassword) => (dispatch) => {
	dispatch({ type: GET_CHANGE_PASSWORD_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/auth/appchangePassword`,
		headers: {
			Accept: "application/json",
		},
		data: {
			user_id,
			currentPassword,
			newPassword,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_CHANGE_PASSWORD_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_CHANGE_PASSWORD_ERROR,
				payload: error.response,
			});
		});
};

export const openPasswordModal = (status) => (dispatch) => {
	return dispatch({
		type: POST_PASSWORD_MODAL_OPEN_SUCCESS,
		payload: status,
	});
};
