import { GET_ALL_CREDIT_CARD_REQUEST, GET_ALL_CREDIT_CARD_SUCCESS, GET_ALL_CREDIT_CARD_ERROR } from "../../types/types";
export default function addCreditCard(state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_ALL_CREDIT_CARD_REQUEST:
			return {
				loading: true,
			};
		case GET_ALL_CREDIT_CARD_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_ALL_CREDIT_CARD_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}
