import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";
export const history = createBrowserHistory({ forceRefresh: true });
export default () => {
  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("userInfo");
    navigate("/");
    window.location.reload();
  }
  return <> {logout()}</>;
};