import Layout from "./component/layoutStreaming";
import LiveEvents from "./component/liveEvents";

export default () => {
	return (
		<>
			<Layout>
				<LiveEvents />
			</Layout>
		</>
	);
};

