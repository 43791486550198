import { GET_BECOME_INFLUENCER_REQUEST, GET_BECOME_INFLUENCER_SUCCESS, GET_BECOME_INFLUENCER_ERROR } from "../../types/types";

export default function (state = [], action) {
	const { type, payload } = action;
	switch (type) {
		case GET_BECOME_INFLUENCER_REQUEST:
			return {
				loading: true,
			};
		case GET_BECOME_INFLUENCER_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_BECOME_INFLUENCER_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};
		default:
			return state;
	}
}
