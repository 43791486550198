import { GET_LIVE_EVENT_REQUEST, GET_LIVE_EVENT_SUCCESS, GET_LIVE_EVENT_ERROR, GET_EVENT_ID_REQUEST, GET_EVENT_ID_SUCCESS, GET_EVENT_ID_ERROR, SHOPIFY_GET_CART_REQUEST, SHOPIFY_GET_CART_SUCCESS, SHOPIFY_GET_CART_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getLiveEvent = () => (dispatch) => {
	dispatch({ type: GET_LIVE_EVENT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/getBanners`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_LIVE_EVENT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_LIVE_EVENT_ERROR,
				payload: error.response,
			});
		});
};

export const getEventsBy_Id = (id, userId) => (dispatch) => {
	dispatch({ type: GET_EVENT_ID_REQUEST });

	return axios({
		method: "GET",
		url: `${BASEURL}public/events/getoneevent/${id}?user=${userId}`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			console.log(response);
			return dispatch({
				type: GET_EVENT_ID_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_EVENT_ID_ERROR,
				payload: error.response,
			});
		});
};

export const getShopifyCart = (id, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_GET_CART_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/getcart`,

		headers: { Authorization: `Bearer ${token}` },
		data: {
			brand: id,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_GET_CART_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_GET_CART_ERROR,
				payload: error.response,
			});
		});
};
