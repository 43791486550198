import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPopularCategories } from "../../redux/actions/category.action";
import "../../css/category.scss";
const responsive5 = {
	superLargeDesktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 6,
		slidesToSlide: 3, // optional, default to 1.
	},
	desktop: {
		breakpoint: { max: 1023, min: 768 },
		items: 4,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 767, min: 0 },
		items: 2.5,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		items: 2.5,
		slidesToSlide: 2, // optional, default to 1.
	},
};

export default () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [PopularCategory, setPopularCategory] = useState([]);

	useEffect(() => {
		setLoading(true);
		dispatch(getPopularCategories()).then((res) => {
			setPopularCategory(res?.payload.data);
			setLoading(false);
		});
	}, []);

	return (
		<>
			<div className="popular_cat_main">
				<div className="midd-width-ifti">
					<div className="popular_cat">
						<div className="pik-header">
							<h3>Popular Categories</h3>
						</div>
						<div className="popular_cat_carousal">
							{loading ? (
								<>
									<Carousel responsive={responsive5} autoPlay={false} containerClass="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]}>
										{new Array(7).fill().map(() => {
											return (
												<div className="cash-item">
													<NavLink to={`#`}>
														<div className="cat_height"></div>
														<h4>Loading</h4>
													</NavLink>
												</div>
											);
										})}
									</Carousel>
								</>
							) : (
								<>
									<Carousel responsive={responsive5} autoPlay={false} containerClass="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]}>
										{PopularCategory.map((item) => {
											return (
												<>
													<div className="cash-item">
														<NavLink to={`/sub-influencer-category/${item._id}`}>
															<div className="cat_height">
																<img src={item.image_url} />
															</div>
															<h4>{item.category_name}</h4>
														</NavLink>
													</div>
												</>
											);
										})}
									</Carousel>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};