import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { getParentCategories } from "../../redux/actions/category.action";
//import { getMultipleSubCategories } from "../../redux/actions/category.action";
import { useDispatch } from "react-redux";
import closeicon from "../../images/brand-logos/close-icon.svg";

export default function CategoryFilter({ showFilter, setShowFilter, setCategory }) {
	const dispatch = useDispatch();
	// const { category } = useSelector((state) => state);
	const [allCategory, setAllCategory] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [categoryId, setCategoryId] = useState([]);
	const [subCategoryId, setSubCategoryId] = useState([]);
	const [sortBy, setSortBy] = useState("brand");

	useEffect(() => {
		dispatch(getParentCategories()).then((res) => {
			setAllCategory(res.payload.data);
		});
	}, []);

	const reset = () => {
		setSortBy("brand");
		setCategoryId([]);
		setSubCategoryId([]);
	};

	const applyAll = () => {
		let ids = [...categoryId, ...subCategoryId];
		setCategory(ids);
		setShowFilter(false);
	};

	return (
		<>
			<Modal
				backdropClassName="custom-backdrop"
				dialogClassName="filter-modal-box"
				show={showFilter}
				scrollable
				onHide={() => {
					setShowFilter(false);
				}}
				centered
			>
				<span
					className="filter-close"
					onClick={() => {
						setShowFilter(false);
					}}
				>
					<img src={closeicon} className="filter-gray" />
				</span>

				<Modal.Body>
					<div className="filter-container">
						<h3>Sort live events by</h3>
						<div className="filter-box">
							<Button
								variant="primary"
								onClick={() => {
									setSortBy("brand");
								}}
								className={`${sortBy === "brand" ? "active" : ""}`}
							>
								Brands
							</Button>
							{/* <Button
								variant="primary"
								onClick={() => {
									setSortBy("influencer");
								}}
								className={`${sortBy === "influencer" ? "active" : ""}`}
							>
								Influencers
							</Button> */}
						</div>
					</div>
					<div className="filter-container">
						<h3>Categories</h3>
						<div className="filter-box">
							{allCategory?.length > 0 &&
								allCategory.map((item) => (
									<Button
										className={categoryId.includes(item?._id) && "active"}
										onClick={() => {
											setSubCategoryId([]);
											let allCategories = [];
											if (categoryId.includes(item?._id)) {
												let catId = categoryId.filter(function (items) {
													return items !== item?._id;
												});
												allCategories = catId;
												setCategoryId(catId);
											} else {
												allCategories = [...categoryId, item?._id];
												setCategoryId([...categoryId, item?._id]);
											}
											// dispatch(getMultipleSubCategories(allCategories)).then((res) => {
											// 	setSubCategory(res?.payload?.data);
											// });
										}}
										variant="primary"
									>
										{item?.category_name}
									</Button>
								))}
						</div>
					</div>
					{subCategory.length > 0 && (
						<div className="filter-container">
							<h3>Subcategories</h3>
							<div className="filter-box">
								{subCategory?.map((item) => (
									<Button
										className={subCategoryId.includes(item?._id) && "active"}
										onClick={() => {
											let allSubCategories = [];
											if (subCategoryId.includes(item?._id)) {
												let catId = subCategoryId.filter(function (items) {
													return items !== item?._id;
												});
												allSubCategories = catId;
												setSubCategoryId(catId);
											} else {
												setSubCategoryId([...subCategoryId, item?._id]);
											}
										}}
										variant="primary"
									>
										{item?.category_name}
									</Button>
								))}
							</div>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					{" "}
					<Button
						onClick={() => {
							reset();
						}}
						variant="secondary"
					>
						Reset all
					</Button>
					<Button
						onClick={() => {
							applyAll();
						}}
						variant="primary"
					>
						Apply filter
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}