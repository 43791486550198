import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrum from "../../components/breadCrum/breadCrum";
import SubCategories from "../../components/subCategory/subCategory";
import Banner2 from "../../components/banner/banner2";
import CategoryFeatureBrand from "../../components/brand/categoryFeatureBrand";
import CategoryFavouriteBrand from "../../components/brand/categoryFavouriteBrand";
import CategoryWiseBrand from "../../components/brand/categoryWiseBrand";
import "../../css/category.scss";
import "react-multi-carousel/lib/styles.css";

export default () => {
	const params = useParams();
	const { subCategory } = useSelector((state) => state);
	const categoryName = subCategory?.payload?.parentData?.category_name;

	return (
		<>
			<BreadCrum categoryName={categoryName} />
			<SubCategories />
			<Banner2 category={params.id} type="category" />
			<CategoryFeatureBrand category={params.id} type="category" />
			<CategoryFavouriteBrand category={params.id} type="category" />
			<CategoryWiseBrand heading={`${categoryName ? categoryName : "Category"} Brands`} />
		</>
	);
};
