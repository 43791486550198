import {
  POST_CITY_REQUEST,
  POST_CITY_SUCCESS,
  POST_CITY_ERROR,
} from "../../types/types";

export default function city(state = "", action) {
  const { type, payload } = action;
  switch (type) {
    case POST_CITY_REQUEST:
      return {
        loading: true,
        success: true,
      };
    case POST_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    case POST_CITY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        payload: payload,
      };

    default:
      return state;
  }
}
