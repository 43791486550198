import React, { useState } from "react";
import inficon from "../../../images/brand-logos/hOuVCU.png";
import BecomeInfluenerModal from "../../../pages/becomeInfluencer/becomeInfluencer";
export default () => {
	const [becomeInfluencerModal, setBecomeInfluencerModal] = useState(false);
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	return (
		<>
			<div className="account-left-content">
				<h1>Become Influencer</h1>
				<div className="account-detail">
					<div className="pr-op-block pro-inf-block">
						<div className="inf-cret-main">
							<div className="inf-cret-left">
								<p>
									When you become an influencer, you will be able to participate in campaigns offered by brands and earn commissions. if you have at least <strong>1000</strong> followers you can qualify to
									became an influencer.
								</p>
								<p className="app-status">
									Application Status: <strong>{userInfo?.became_influencer ? userInfo?.became_influencer : "New"}</strong>
								</p>

								{userInfo.became_influencer == "new" && (
									<a
										onClick={() => {
											if (userInfo.became_influencer !== "pending") {
												setBecomeInfluencerModal(true);
											}
										}}
										href="#"
									>
										Become An Influencer
									</a>
								)}
							</div>
							<div className="inf-cret-right">
								<img src={inficon} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<BecomeInfluenerModal
				setBecomeInfluencerModal={(value) => {
					setBecomeInfluencerModal(value);
				}}
				becomeInfluencerModal={becomeInfluencerModal}
			/>
		</>
	);
};
