import React, { useEffect, useState } from "react";
import { Select, Drawer, Form } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { DownOutlined, ShoppingOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import bars from "../../images/bars.svg";
import logo from "../../images/logo-svg-blk.svg";
import searchicon from "../../images/brand-logos/search.svg";
import Register from "../../pages/auth/register";
import Login from "../../pages/auth/login";
import ForgetPassword from "../../pages/auth/forgetPassword";
import { createBrowserHistory } from "history";
import {
  getCountry,
  getSelectedCountry,
} from "../../redux/actions/country.action";
import { openRegister } from "../../redux/actions/register.action";
import { getAccount } from "../../redux/actions/account.action";
import "antd/dist/antd.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const history = createBrowserHistory();
const { Option } = Select;

let userInfo = localStorage.getItem("userInfo");

if (userInfo !== "undefined") {
  userInfo = JSON.parse(localStorage.getItem("userInfo"));
} else {
  localStorage.removeItem("userInfo");
  window.location.reload();
}

export default function Header() {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setLoginModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [step, setStep] = useState("");

  useEffect(() => {
    if (path === "/customer") setShowHeader(true);
    else setShowHeader(false);
  }, [path]);

  useEffect(() => {
    dispatch(getCountry()).then((res) => {
      const selectCountries = [];
      res.payload.message.map(({ name, code1 }) => {
        return selectCountries.push({ value: code1, label: name });
      });
      setCountryList(selectCountries);
    });
    dispatch(getSelectedCountry("US"));
  }, []);

  useEffect(() => {
    if (userInfo?.token && account?.length === 0)
      dispatch(getAccount(userInfo?.token));
  }, []);

  const [subMenuClass, setSubMenuClass] = useState("");
  const [visibleMenu, setVisibleMenu] = useState(false);

  const handleMenuClick = (e) => {
    if (e.key !== "2") {
      setVisibleMenu(false);
    }
  };

  const handleVisibleChange = (flag) => {
    setVisibleMenu(flag);
  };

  useEffect(() => {
    dispatch(getCountry()).then((res) => {
      const selectCountries = [];
      res.payload.message.map(({ name, code1 }) => {
        return selectCountries.push({ value: code1, label: name });
      });
      setCountryList(selectCountries);
    });
  }, []);

  const { account, showAuthModal, showRegModal } = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    setLoginModal(showAuthModal?.payload);
    setShowRegisterModal(false);
  }, [showAuthModal]);

  useEffect(() => {
    setLoginModal(showRegModal?.payload?.status);
    setStep(showRegModal?.payload?.step);
    setShowRegisterModal(true);
  }, [showRegModal]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const changeCountry = (e) => {
    dispatch(getSelectedCountry(e));
  };

  const mobiflipslider = {
    className: "slider variable-width abc",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToScroll: 3,
    swipe: true,
    arrows: false,
    variableWidth: true,
  };

  const countrySelect = () => {
    return (
      <div className="country-select">
        <Select
          defaultValue={"US"}
          className=""
          showSearch
          placeholder="Select Country"
          size="medium"
          optionFilterProp="children"
          onChange={(e) => {
            changeCountry(e);
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {countryList.map((item) => (
            <Option value={item.value}>{item.label}</Option>
          ))}
        </Select>
      </div>
    );
  };

  const logout = () => {
    localStorage.removeItem("userInfo");
    window.location.reload(false);
    // history.push(`https://app.kbshop.com/logout`);
    navigate("/");
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={
        userInfo?.account_type === "influencer"
          ? [
              {
                label: (
                  <NavLink to={"/myaccount"}>
                    <span className="drop-account">
                      My Account{" "}
                      <i className="fa fa-cog" aria-hidden="true"></i>
                    </span>
                  </NavLink>
                ),
                key: 0,
              },

              {
                label: (
                  <span onClick={() => logout()}>
                    Logout <i className="fa fa-sign-out" aria-hidden="true"></i>
                  </span>
                ),
                key: 2,
              },
            ]
          : userInfo?.account_type === "brand"
          ? [
              {
                label: (
                  <NavLink to={"/brandwallet"}>
                    <span className="drop-account">
                      My Account{" "}
                      <i className="fa fa-cog" aria-hidden="true"></i>
                    </span>
                  </NavLink>
                ),
                key: 0,
              },

              {
                label: (
                  <span
                    onClick={() => {
                      if (userInfo?.sid) {
                        window.open(
                          `https://app.kbshop.com/login/?sid=${userInfo?.sid}`
                        );
                      }
                    }}
                    className="drop-account"
                  >
                    Brand Mode <i className="fa fa-tags" aria-hidden="true"></i>
                  </span>
                ),
                key: 1,
              },

              {
                label: (
                  <span onClick={() => logout()}>
                    Logout <i className="fa fa-sign-out" aria-hidden="true"></i>
                  </span>
                ),
                key: 2,
              },
            ]
          : [
              {
                label: (
                  <NavLink to={"/myaccount"}>
                    <span className="drop-account">
                      My Account{" "}
                      <i className="fa fa-cog" aria-hidden="true"></i>
                    </span>
                  </NavLink>
                ),
                key: 0,
              },
              // {
              //   label: (
              //     <NavLink to={"/purchase"}>
              //       <span className="abc">
              //         Purchases{" "}
              //         <i className="fa fa-credit-card" aria-hidden="true"></i>
              //       </span>
              //     </NavLink>
              //   ),
              //   key: 0,
              // },

              // {
              // 	label: (
              // 		<NavLink to={"/become-influencer"}>
              // 			<span>
              // 				Become an Influencer <i className="fa fa-users" aria-hidden="true"></i>
              // 			</span>
              // 		</NavLink>
              // 	),
              // 	key: 1,
              // },

              // {
              //   label: (
              //     <span
              //       onClick={() => {
              //         if (subMenuClass) setSubMenuClass("");
              //         else setSubMenuClass("pro-ul");
              //       }}
              //       className="settting"
              //     >
              //       Setting <i className="fa fa-cog" aria-hidden="true"></i>
              //       <div className={`sub-menu-open ${subMenuClass}`}>
              //         <NavLink to={"/myaccount"}>
              //           <span>
              //             <i
              //               className="fa fa-chevron-right"
              //               aria-hidden="true"
              //             ></i>{" "}
              //             Profile
              //           </span>
              //         </NavLink>
              //         <NavLink to={"/myaccount?changePassword"}>
              //           <span>
              //             <i
              //               className="fa fa-chevron-right"
              //               aria-hidden="true"
              //             ></i>{" "}
              //             Change Password
              //           </span>
              //         </NavLink>
              //       </div>
              //     </span>
              //   ),
              //   key: 2,
              // },
              // {
              //   label: (
              //     <NavLink to={"/favourite"}>
              //       <span>
              //         Favourites{" "}
              //         <i className="fa fa-heart" aria-hidden="true"></i>
              //       </span>
              //     </NavLink>
              //   ),
              //   key: 3,
              // },
              {
                label: (
                  <span onClick={() => logout()}>
                    Logout <i className="fa fa-sign-out" aria-hidden="true"></i>
                  </span>
                ),
                key: 4,
              },
            ]
      }
    />
  );

  return (
    <>
      <div className={` c-portal-header ${sticky ? "sticky-shadow" : ""}`}>
        <div className="midd-width-ifti">
          <div className="p-h-inner">
            <div className="mobile-menu-ifti">
              <nav className="menuBar">
                <div className="menuCon">
                  <span className="barsBtn" onClick={() => showDrawer()}>
                    <img src={bars} alt="menu open icon"></img>
                  </span>
                  <Drawer
                    placement="left"
                    onClose={onClose}
                    visible={visible}
                    width={"100%"}
                  >
                    {!userInfo ? (
                      <div className="sign-mobile-drawer">
                        <h4>
                          Join today and get a<span> $10 Welcome Bonus </span>
                          when you spend $75.
                        </h4>

                        <div className="sign-mb-btns">
                          <span
                            onClick={() => {
                              setLoginModal(true);
                              setShowRegisterModal(false);
                            }}
                          >
                            Sign In
                          </span>
                          <span
                            onClick={() => {
                              dispatch(openRegister(true, "step1"));
                            }}
                          >
                            Join Now
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="my-account-mobile-menu">
                        <div
                          onClick={() => {
                            if (subMenuClass) setSubMenuClass("");
                            else setSubMenuClass("pro-ul-mob-open");
                          }}
                          className="customer-box"
                        >
                          <span className="cus-name-mob">
                            {account?.payload?.Data?.name}
                          </span>
                          <span className="cus-id-mob">
                            {" "}
                            {account?.payload?.Data?.pixel_id}
                          </span>
                          <i className="fa fa-cog" aria-hidden="true"></i>
                        </div>
                        <div className={`account-mob-menu ${subMenuClass}`}>
                          <ul>
                            {/* {userInfo?.account_type !== "brand" && (
															<li>
																<NavLink to="/mywallet" onClick={() => onClose()}>
																	<i className="fa fa-briefcase" aria-hidden="true"></i> My Wallet
																</NavLink>
															</li>
														)} */}
                            {userInfo?.account_type === "brand" && (
                              <li>
                                <NavLink
                                  to="/brandwallet"
                                  onClick={() => onClose()}
                                >
                                  <i
                                    className="fa fa-briefcase"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  My Wallet
                                </NavLink>
                              </li>
                            )}
                            <li>
                              <NavLink
                                to="/favourite"
                                onClick={() => onClose()}
                              >
                                <i
                                  className="fa fa-heart"
                                  aria-hidden="true"
                                ></i>{" "}
                                My Favourites
                              </NavLink>
                            </li>
                            {/* {userInfo?.account_type === "customer" && (
															<li>
																<NavLink to="/become-influencer" onClick={() => onClose()}>
																	<i className="fa fa-users" aria-hidden="true"></i> Become an Influencer
																</NavLink>
															</li>
														)} */}
                            {userInfo?.account_type !== "brand" && (
                              <>
                                {/* <li>
																	<NavLink to="/earned-commission" onClick={() => onClose()}>
																		<i className="fa fa-money" aria-hidden="true"></i> My Commission
																	</NavLink>
																</li> */}

                                <li>
                                  <NavLink
                                    to="/purchase"
                                    onClick={() => onClose()}
                                  >
                                    <i
                                      className="fa fa-credit-card"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    My Purchases
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink
                                    to="/shippingaddress"
                                    onClick={() => onClose()}
                                  >
                                    <i
                                      className="fa fa-address-card"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Shipping Address
                                  </NavLink>
                                </li>
                              </>
                            )}
                            <li>
                              <NavLink
                                to="/myaccount"
                                onClick={() => onClose()}
                              >
                                <i
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>{" "}
                                Account Setting
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {userInfo?.account_type === "brand" && (
                      <div className="dr-mobi-menu-mode">
                        <a
                          target="_blank"
                          onClick={() => {
                            onClose();
                            window.open(
                              `https://app.kbshop.com/login/?sid=${userInfo?.sid}`
                            );
                          }}
                        >
                          <i className="fa fa-tags" aria-hidden="true"></i>{" "}
                          Brand Mode
                        </a>
                      </div>
                    )}

                    <div className="dr-mobi-menu-ift">
                      <ul>
                        {/* <li>
													<NavLink to="/" onClick={() => onClose()}>
														<i className="fa fa-home" aria-hidden="true"></i> Home
													</NavLink>
												</li> */}
                        <li>
                          <NavLink
                            reloadDocument
                            to="/"
                            onClick={() => {
                              onClose();
                            }}
                          >
                            <i
                              className="fa fa-video-camera"
                              aria-hidden="true"
                            ></i>{" "}
                            Live Events
                          </NavLink>
                        </li>
                        {/* <li>
													<NavLink to="/brands" onClick={() => onClose()}>
														<i className="fa fa-tags" aria-hidden="true"></i> Brands
													</NavLink>
												</li>
												<li>
													<NavLink to="/category" onClick={() => onClose()}>
														<i className="fa fa-list-alt" aria-hidden="true"></i> Categories
													</NavLink>
												</li>
												<li>
													<NavLink to="/influencer" onClick={() => onClose()}>
														<i className="fa fa-instagram"></i> Influencers
													</NavLink>
												</li> */}
                        {/* <li>
													<NavLink to="/about-us" onClick={() => onClose()}>
														<i className="fa fa-info-circle"></i> About Us
													</NavLink>
												</li>
												<li>
													<NavLink to="/contact-us" onClick={() => onClose()}>
														<i className="fa fa-address-book"></i> Contact us
													</NavLink>
												</li> */}
                        <li>
                          <NavLink to="/dl1961denim" onClick={() => onClose()}>
                            <i class="fa fa-instagram" aria-hidden="true"></i>{" "}
                            Social Store
                          </NavLink>
                        </li>
                        {/* <li>
                          <NavLink to="#" onClick={() => onClose()}>
                            <i
                              className="fa fa-bullhorn"
                              aria-hidden="true"
                            ></i>{" "}
                            Promotions
                          </NavLink>
                        </li> */}
                        {/* <li>
                          <NavLink to="#" onClick={() => onClose()}>
                            <i
                              className="fa fa-comment-o"
                              aria-hidden="true"
                            ></i>{" "}
                            Help
                          </NavLink>
                        </li> */}
                        {/* <li>
													<a
														href="javascript:void"
														onClick={() => {
															if (subMenuClass) setSubMenuClass("");
															else setSubMenuClass("mobile-inr-inr-submenu-open");
														}}
													>
														<i className="fa fa-briefcase" aria-hidden="true"></i> For Businesses
													</a>
													<ul className={`mobile-inr-inr-submenu-close ${subMenuClass}`}>
														<li>
															<NavLink to="/retailer-brand" onClick={() => onClose()}>
																Retailers & Brands
															</NavLink>
														</li>
														<li>
															<NavLink to="/influencer-creator" onClick={() => onClose()}>
																Influencers & Creators
															</NavLink>
														</li>
														<li>
															<NavLink to="/about-us" onClick={() => onClose()}>
																About Us
															</NavLink>
														</li>
													</ul>
												</li> */}

                        {userInfo?.account_type === "brand" && (
                          <>
                            <li>
                              <a
                                href="javascript::void"
                                onClick={() => {
                                  onClose();
                                  window.open(
                                    `https://app.kbshop.com/login/?sid=${userInfo?.sid}`
                                  );
                                }}
                              >
                                <i
                                  className="fa fa-tags"
                                  aria-hidden="true"
                                ></i>{" "}
                                Brand Mode
                              </a>
                            </li>

                            <li>
                              <a
                                href="javascript::void"
                                onClick={() => {
                                  onClose();
                                  window.open(`https://www.live.kbshop.com`);
                                }}
                              >
                                <i
                                  className="fa fa-video-camera"
                                  aria-hidden="true"
                                ></i>{" "}
                                KBLIVE
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript::void"
                                onClick={() => {
                                  onClose();
                                  window.open(`https://www.live.kbshop.com`);
                                }}
                              >
                                <i
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>{" "}
                                Host
                              </a>
                            </li>
                          </>
                        )}

                        {userInfo?.account_type === "influencer" && (
                          <>
                            <li>
                              <a
                                href="javascript::void"
                                onClick={() => {
                                  onClose();
                                  window.open(`https://www.live.kbshop.com`);
                                }}
                              >
                                <i
                                  className="fa fa-user"
                                  aria-hidden="true"
                                ></i>{" "}
                                Host
                              </a>
                            </li>
                          </>
                        )}

                        {userInfo?.account_type === "customer" && (
                          <li>
                            <a
                              href="javascript::void"
                              onClick={() => {
                                onClose();
                                window.open(`https://www.live.kbshop.com`);
                              }}
                            >
                              <i className="fa fa-user" aria-hidden="true"></i>{" "}
                              Host
                            </a>
                          </li>
                        )}

                        {userInfo && (
                          <li>
                            <NavLink
                              to="#"
                              onClick={() => {
                                onClose();
                                logout();
                              }}
                            >
                              <i
                                className="fa fa-sign-out"
                                aria-hidden="true"
                              ></i>{" "}
                              Logout
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Drawer>
                </div>
              </nav>
            </div>
            <div className="p-h-left">
              <NavLink to="/">
                <img className="logo" src={logo} alt="logo" />
              </NavLink>
              <div className="mobile-search-container">
                <div className="mobile-search-box">
                  <span
                    className="mobile-search-icon"
                    onClick={() => {
                      setMobileSearch(true);
                    }}
                  >
                    <img src={searchicon} alt="" />
                  </span>
                  <div
                    className={`mobile-serach-div ${
                      mobileSearch ? "mobile-search" : ""
                    }`}
                  >
                    <div className="mobile-search-inner">
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Search "
                        id="example-search-input"
                      />
                    </div>
                    <span
                      className="close-search"
                      onClick={() => {
                        setMobileSearch(false);
                      }}
                    >
                      Close
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-h-middle input-group col-md-5">
              <input
                className="form-control py-2"
                type="search"
                placeholder="Search "
                id="example-search-input"
              />
              <span className="input-group-append">
                <button className="btn btn-outline-secondary" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </span>
            </div>

            {userInfo !== null ? (
              <div className="p-h-user">
                {/* <i
                className="fa fa-user-o fa-2x"
                aria-hidden="true"
                onClick={() => logout()}
              ></i>
              <span>{userInfo?.name}</span> */}
                <Dropdown
                  onVisibleChange={handleVisibleChange}
                  visible={visibleMenu}
                  arrow
                  placement="topRight"
                  overlay={menu}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <i className="fa fa-user-o fa-2x" aria-hidden="true"></i>
                      <div className="userNameTop">
                        <span>{userInfo?.name}</span>
                      </div>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
                {countrySelect()}{" "}
              </div>
            ) : (
              <div className="login-signup">
                <span
                  className="tp-frm-btns"
                  onClick={() => {
                    setLoginModal(true);
                    setShowRegisterModal(false);
                  }}
                >
                  Sign In
                </span>
                <span
                  className="tp-frm-btns"
                  onClick={() => {
                    setLoginModal(true);
                    setShowRegisterModal(true);
                  }}
                >
                  Join Now
                </span>

                {countrySelect()}
              </div>
            )}
          </div>
        </div>

        <div className="c-top-menu">
          <div className="midd-width-ifti">
            <div className="c-top-menu-inner">
              <ul>
                {/* <li>
                  <NavLink to="/">
                    <i className="fa fa-home" aria-hidden="true"></i> Home
                  </NavLink>
                </li> */}
                <li>
                  <NavLink reloadDocument exact={true} to={"/"}>
                    {" "}
                    <i
                      className="fa fa-video-camera"
                      aria-hidden="true"
                    ></i>{" "}
                    Live Events
                  </NavLink>
                </li>
                {/* <li>
									<NavLink to="/brands">
										<i className="fa fa-tags" aria-hidden="true"></i> Brands
									</NavLink>
								</li>
								<li>
									<NavLink to="/category">
										<i className="fa fa-list-alt" aria-hidden="true"></i> Categories
									</NavLink>
								</li>
								<li>
									<NavLink to="/influencer">
										<i className="fa fa-users" aria-hidden="true"></i> Influencers
									</NavLink>
								</li> */}
                {/* <li>
									<a to="#">
										<i className="fa fa-bullhorn" aria-hidden="true"></i> Promotions
									</a>
								</li> */}
                {/* <li>
									<NavLink to="/about-us">
										<i className="fa fa-info-circle" aria-hidden="true"></i> About Us
									</NavLink>
									<a href="javascript:void" onClick={() => onClose()}>
										<i className="fa fa-briefcase" aria-hidden="true"></i> For Businesses
									</a>
									<ul>
										<li>
											<NavLink to="/retailer-brand">Retailers & Brands</NavLink>
										</li>
										<li>
											<NavLink to="/influencer-creator">Influencers & Creators</NavLink>
										</li>
										<li>
											<NavLink to="/about-us">About Us</NavLink>
										</li>
									</ul>
								</li> */}
                {/* <li>
									<NavLink to="/contact-us">
										<i className="fa fa-address-book" aria-hidden="true"></i> Contact Us
									</NavLink>
								</li> */}
                <li>
                  <NavLink to="/dl1961denim">
                    <i class="fa fa-instagram" aria-hidden="true"></i> Social
                    Store
                  </NavLink>
                </li>

                {userInfo?.account_type === "brand" && (
                  <div className="kblive">
                    <li className="aftr-mode">
                      <a
                        href="javascript::void"
                        onClick={() => {
                          onClose();
                          window.open(
                            `https://app.kbshop.com/login/?sid=${userInfo?.sid}`
                          );
                        }}
                      >
                        <i className="fa fa-tags" aria-hidden="true"></i> Brand
                        Mode
                      </a>
                    </li>

                    <li className="aftr-mode">
                      <a
                        href="javascript::void"
                        onClick={() => {
                          onClose();
                          window.open(
                            `https://www.kblive.com/login/?sid=${userInfo?.sid}`
                          );
                        }}
                      >
                        <i
                          className="fa fa-video-camera"
                          aria-hidden="true"
                        ></i>{" "}
                        KBLIVE
                      </a>
                    </li>
                  </div>
                )}

                {userInfo?.account_type === "influencer" && (
                  <div className="kblive">
                    <li className="aftr-mode">
                      <a
                        href="javascript::void"
                        onClick={() => {
                          onClose();
                          window.open(
                            `https://www.kblive.com/login/?sid=${userInfo?.sid}`
                          );
                        }}
                      >
                        <i className="fa fa-user" aria-hidden="true"></i> Host
                      </a>
                    </li>
					{/* <li className="aftr-mode">
                      <Button onClick={() => setOpen(true)}>Open</Button>
                    </li> */}
                  </div>
                )}

                {userInfo?.account_type === "customer" && (
                  <div className="kblive">
                    <li className="aftr-mode">
                      <a
                        href="javascript::void"
                        onClick={() => {
                          onClose();
                          window.open(
                            `https://www.kblive.com/login/?sid=${userInfo?.sid}`
                          );
                        }}
                      >
                        <i className="fa fa-user" aria-hidden="true"></i> Host
                      </a>
                    </li>
					{/* <li className="aftr-mode">
                      <Button onClick={() => setOpen(true)}>Open</Button>
                    </li> */}
                  </div>
                )}
                {/* <li className="social-cart-icon">
									<NavLink>
										<ShoppingOutlined />
										<small>10</small>
									</NavLink>
								</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-front-menu">
        <div className="midd-width-ifti">
          <Slider {...mobiflipslider}>
            <div className="nav-link-front">
              <NavLink reloadDocument to="/">
                Live Events
              </NavLink>
            </div>
            {/* <div className="nav-link-front">
							<NavLink to="/brands">Brands</NavLink>
						</div>
						<div className="nav-link-front">
							<NavLink to="/category">Categories</NavLink>
						</div>
						<div className="nav-link-front">
							<NavLink to="/influencer">Influencers</NavLink>
						</div> */}
            {/* <div className="nav-link-front">
							<NavLink to="/retailer-brand" className="ft_box_link">
								Retailers & Brands
							</NavLink>
						</div>
						<div className="nav-link-front">
							<NavLink to="/influencer-creator" className="ft_box_link">
								Influencers & Creators
							</NavLink>
						</div> */}
            <div className="nav-link-front">
              <NavLink to="/dl1961denim" className="ft_box_link">
                Social Store
              </NavLink>
            </div>

            {userInfo?.account_type === "brand" && (
              <div className="nav-link-front">
                <a
                  className="ft_box_link"
                  href="javascript::void"
                  onClick={() => {
                    onClose();
                    window.open(
                      `https://app.kbshop.com/login/?sid=${userInfo?.sid}`
                    );
                  }}
                >
                  Brand Mode
                </a>
              </div>
            )}

            {userInfo?.account_type === "brand" && (
              <div className="nav-link-front">
                <a
                  className="ft_box_link"
                  href="javascript::void"
                  onClick={() => {
                    onClose();
                    window.open(`https://www.live.kbshop.com`);
                  }}
                >
                  KBLIVE
                </a>
              </div>
            )}

            {userInfo?.account_type === "influencer" && (
              <div className="nav-link-front">
                <a
                  className="ft_box_link"
                  href="javascript::void"
                  onClick={() => {
                    onClose();
                    window.open(`https://www.live.kbshop.com`);
                  }}
                >
                  Host
                </a>
              </div>
            )}

            {userInfo?.account_type === "customer" && (
              <div className="nav-link-front">
                <a
                  className="ft_box_link"
                  href="javascript::void"
                  onClick={() => {
                    onClose();
                    window.open(`https://www.live.kbshop.com`);
                  }}
                >
                  &nbsp; Host
                </a>
              </div>
            )}
          </Slider>
        </div>
      </div>

      <Modal
        dialogClassName="signup-modal form-close-modal"
        size="md"
        backdropClassName="custom-backdrop"
        show={showLoginModal}
        // onHide={() => setLoginModal(false)}
        centered
        animation={false}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header>
          <button
            type="button"
            className="close"
            onClick={() => {
              setLoginModal(false);
            }}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {showRegisterModal ? (
            <>
              <Register step={step} />
              <p className="already">
                Already have an account?&nbsp;
                <span
                  onClick={() => {
                    setShowRegisterModal(false);
                  }}
                >
                  Login
                </span>
              </p>
            </>
          ) : (
            <>
              {forgotPassword ? (
                <>
                  <ForgetPassword closeModal={setLoginModal} />
                  <p className="already">
                    <span
                      onClick={() => {
                        setForgotPassword(false);
                      }}
                    >
                      Sign In
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <Login />
                  <p className="already">
                    New to KBSHOP?&nbsp;
                    <span
                      onClick={() => {
                        setShowRegisterModal(true);
                      }}
                    >
                      Create an Account
                    </span>
                    &nbsp;OR&nbsp;
                    <span
                      onClick={() => {
                        setForgotPassword(true);
                      }}
                    >
                      Forgot Password
                    </span>
                  </p>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
