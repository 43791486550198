import { GET_BANNER_REQUEST, GET_BANNER_SUCCESS, GET_BANNER_ERROR, GET_CATEGORY_BANNER_REQUEST, GET_CATEGORY_BANNER_SUCCESS, GET_CATEGORY_BANNER_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getBanner = (country) => (dispatch) => {
	dispatch({ type: GET_BANNER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/category/getBanners`,
		data: { country },
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BANNER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BANNER_ERROR,
				payload: error.response,
			});
		});
};

export const getCategoryBanner =
	(category, type, country = "US") =>
	(dispatch) => {
		dispatch({ type: GET_CATEGORY_BANNER_REQUEST });

		return axios({
			method: "POST",
			url: `${BASEURL}mobile/category/getCategoryBanners`,
			data: { category_id: category, type, country: country },
			headers: {
				Accept: "application/json",
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_CATEGORY_BANNER_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_CATEGORY_BANNER_ERROR,
					payload: error.response,
				});
			});
	};
