import Layout from "./component/layout";
import Video from "./component/video";
export default () => {
	
	return (
		<>
			<Layout>
				<Video />
			</Layout>
		</>
	);
};
