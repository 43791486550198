import { SHOPIFY_SET_SHIPPING_RATES_REQUEST, SHOPIFY_SET_SHIPPING_RATES_SUCCESS, SHOPIFY_SET_SHIPPING_RATES_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const setShippingRates = (brand,handle, token) => (dispatch) => {
	dispatch({ type: SHOPIFY_SET_SHIPPING_RATES_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}shopify/setshippingrates`,
		headers: { Authorization: `Bearer ${token}` },

		data: {
			brand: brand,
            handle:handle,
		},
	})
		.then((response) => {
			return dispatch({
				type: SHOPIFY_SET_SHIPPING_RATES_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: SHOPIFY_SET_SHIPPING_RATES_ERROR,
				payload: error.response,
			});
		});
};
