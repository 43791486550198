import googleplay from "../../images/brand-logos/google-play.svg";
import applestore from "../../images/brand-logos/app-store.svg";


export default function commingSoon(){
    return (
        
        <div className="coming_Soon_main">
            <div className="coming_soon_inr">
                <h1>COMING SOON</h1>
                <p>We are preparing something exciting <br />& amazing for you.</p>
                <div className="app_buttons">
                    <h4>Download Our App</h4>
                    <a href="https://apps.apple.com/app/id1618762939" className="apple-store"><img src={applestore} /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.konnectbio1961"><img src={googleplay} /></a>
                </div>
            </div>
        </div>
    )
}