import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBrandLiveEvent } from "../../redux/actions/liveShopping.action";
import { dateInDays } from "../../helper/date";
export default function BrandLiveEvent() {
	const params = useParams();
	const dispatch = useDispatch();
	const [count, setCount] = useState(2);
	const [data, setData] = useState([]);
	const { allLiveEvent } = useSelector((state) => {
		return state;
	});

	useEffect(() => {
		dispatch(getBrandLiveEvent(1, 6, params.username)).then((res) => {
			setData(res.payload.message.data);
		});
	}, []);

	const liveEvent = {
		className: "slider variable-width",
		dots: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 5.5,
		swipe: false,
		slidesToScroll: 1,
		nextArrow: <SampleNextArrow className={"slick-arrow slick-next"} />,
		prevArrow: <SamplePrevArrow className={"slick-arrow slick-next"} />,
		responsive: [
			{
				breakpoint: 1350,
				settings: {
					slidesToShow: 4.2,
					swipe: true,
				},
			},
			{
				breakpoint: 990,
				settings: {
					slidesToShow: 3.2,
					swipe: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1.5,
					swipe: true,
				},
			},
		],
	};

	function SampleNextArrow(props) {
		const { className, onClick } = props;
		return (
			<button
				className={className}
				onClick={() => {
					setCount(() => count + 1);
					onClick();
					if (allLiveEvent?.payload?.message?.next) {
						dispatch(getBrandLiveEvent(count, 6, params.username)).then((res) => {
							setData([...data, ...res?.payload?.message?.data]);
						});
					}
				}}
			></button>
		);
	}

	function SamplePrevArrow(props) {
		const { className, onClick } = props;
		return (
			<button
				className={className}
				onClick={() => {
					if (count > 0) setCount(() => count - 1);
					onClick();
				}}
			></button>
		);
	}

	const onSwipeEvent = (swipeDirection) => {
		if (swipeDirection === "left") {
			if (allLiveEvent?.payload?.message?.next) {
				dispatch(getBrandLiveEvent(count, 6, params.username)).then((res) => {
					setData([...data, ...res?.payload?.message?.data]);
				});
			}
		}
	};
	return (
		<>
			{/* {data.length > 0 ? (
				<div className="event-container">
					<div className="event-header">
						<h3>Live Events</h3>
					</div>
					<Slider onSwipe={onSwipeEvent} {...liveEvent}>
						{new Array(1).fill().map((_, i) => {
							return (
								<div className="event-col-box">
									<NavLink to="/live-event" className="live-vid-link">
										<div className="vid-item">
											<div className="vid-item-detail">
												<div className="vid-time">
													<span className="live-now">Live Now</span>
												</div>
												<div className="vid-title">
													<span className="v-title">----------</span>
													<div className="author-name">
														<span className="auth-image"></span>
														<span className="auth-title">----------</span>
													</div>
												</div>
											</div>
										</div>
									</NavLink>
								</div>
							);
						})}
					</Slider>
				</div>
			) : data.length ? ( */}
			<div className="event-container">
				<div className="event-header">
					<h3>All Events</h3>
				</div>
				{data.length > 0 && (
					<Slider onSwipe={onSwipeEvent} {...liveEvent}>
						{data.map((item) => {
							return (
								<>
									{item.event_status === "live" ? (
										<div className="event-col-box">
											<NavLink to={`/live-event/${item._id}/${item.instagram_username ?item.instagram_username:item.pixel_id}?referrel=live-events&name=${params.username}`} className="live-vid-link">
											<div className={`vid-item ${item?.default_banner  ? 'default-image':''}`}>
													<img alt="" src={item?.banner} />
													<div className="vid-item-detail">
													<div class="vid-time"><span class="live-now">Live Now</span></div>
											
														<div className="vid-title">
															<span className="v-title">{item?.title}</span>
															<div className="author-name">
																<span className="auth-image">
																	<img alt="" src={item?.brand_profile} />
																</span>
																<span className="auth-title">{item?.brand_name}</span>
															</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									) : (
										<div className="event-col-box">
											<NavLink to={`/upcoming-event/${item._id}/${item.instagram_username ?item.instagram_username:item.pixel_id}?referrel=live-events&name=${params.username}`} className="live-vid-link">
											<div className={`vid-item ${item.default_banner  ? 'default-image':''}`}>
													<img alt="" src={item?.banner} />
													<div className="vid-item-detail">
														<div className="vid-time">
															<span>in {dateInDays(item?.start_date)} </span>
														</div>
														<div className="vid-title">
															<span className="v-title">{item?.title}</span>
															<div className="author-name">
																<span className="auth-image">
																	<img alt="" src={item?.brand_profile} />
																</span>
																<span className="auth-title">{item?.brand_name}</span>
															</div>
														</div>
													</div>
												</div>
											</NavLink>
										</div>
									)}
								</>
							);
						})}
					</Slider>
				)}
			</div>
		</>
	);
}