import Layout from "./component/layout";
import Contact from "./component/contact";
export default () => {
	return (
		<>
			<Layout>
				<Contact />
			</Layout>
		</>
	);
};
