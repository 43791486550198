import { GET_BIO_SHOP_MENU_REQUEST, GET_BIO_SHOP_MENU_SUCCESS, GET_BIO_SHOP_MENU_ERROR, GET_BIO_SHOP_ALL_POST_REQUEST, GET_BIO_SHOP_ALL_POST_SUCCESS, GET_BIO_SHOP_ALL_POST_ERROR, GET_BIO_SHOP_ALL_LINK_REQUEST, GET_BIO_SHOP_ALL_LINK_SUCCESS, GET_BIO_SHOP_ALL_LINK_ERROR, GET_BIO_SHOP_LINK_CLINK_REQUEST, GET_BIO_SHOP_LINK_CLINK_SUCCESS, GET_BIO_SHOP_LINK_CLINK_ERROR, GET_BIO_SHOP_CATEGORY_POST_REQUEST, GET_BIO_SHOP_CATEGORY_POST_SUCCESS, GET_BIO_SHOP_CATEGORY_POST_ERROR, GET_ADD_TO_FAVOURITE_REQUEST, GET_ADD_TO_FAVOURITE_SUCCESS, GET_ADD_TO_FAVOURITE_ERROR, GET_BIO_SHOP_VIEW_COUNT_REQUEST, GET_BIO_SHOP_VIEW_COUNT_SUCCESS, GET_BIO_SHOP_VIEW_COUNT_ERROR, GET_ADD_TO_URL_SUCCESS, GET_ADD_TO_URL_ERROR, GET_ADD_TO_URL_REQUEST } from "../types/types";
import { BASEURL, TEMPURL } from "../../config";
import axios from "axios";

export const getBioShopMenu = (name, token) => (dispatch) => {
	let config = {};
	dispatch({ type: GET_BIO_SHOP_MENU_REQUEST });

	if (token) {
		config = {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		};
	} else {
		config = {
			Accept: "application/json",
		};
	}
	return axios({
		method: "GET",
		url: `${BASEURL}profile/userinfo/${name}`,
		headers: config,
	})
		.then((response) => {
			return dispatch({
				type: GET_BIO_SHOP_MENU_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BIO_SHOP_MENU_ERROR,
				payload: error.response,
			});
		});
};

export const getBioShopAllPost = (username, page, limit, postType,sortBy) => (dispatch) => {
	dispatch({ type: GET_BIO_SHOP_ALL_POST_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}profile/posts/${username}?limit=${limit}&page=${page}&post_type=${postType}&source=shop&sort_by=${sortBy}`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BIO_SHOP_ALL_POST_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BIO_SHOP_ALL_POST_ERROR,
				payload: error.response,
			});
		});
};

export const getBioShopAllLink = (username) => (dispatch) => {
	dispatch({ type: GET_BIO_SHOP_ALL_LINK_REQUEST });
	return axios({
		method: "GET",
		url: `${BASEURL}profile/posts/${username}?post_type=link&source=shop`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BIO_SHOP_ALL_LINK_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BIO_SHOP_ALL_LINK_ERROR,
				payload: error.response,
			});
		});
};

export const getBioShopPostClick = (postId, username, mediaUrl, mediaType, caption, timestamp, userId, postType, mediaId) => (dispatch) => {
	dispatch({ type: GET_BIO_SHOP_LINK_CLINK_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}click/reserve?source=shop`,
		headers: {
			Accept: "application/json",
		},
		data: {
			post_id: postId,
			username: username,
			media_url: mediaUrl,
			media_type: mediaType,
			caption: caption,
			timestamp: timestamp,
			user_id: userId,
			post_type: postType,
			media_id: mediaId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BIO_SHOP_LINK_CLINK_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BIO_SHOP_LINK_CLINK_ERROR,
				payload: error.response,
			});
		});
};

export const getBioShopCategoryPost = (username, page, limit, id, parentId, sub, postType,sortBy) => (dispatch) => {
	dispatch({ type: GET_BIO_SHOP_CATEGORY_POST_REQUEST });
	let subId = "";
	let vid = "";

	if (sub) subId = `&sub_id=${sub}`;

	if (postType === "video") {
		vid = `&post_type=video`;
	}

	return axios({
		method: "GET",
		url: `${BASEURL}profile/filter/${username}?limit=${limit}&page=${page}&id=${id}${subId}&post_type=${postType}&sort_by=${sortBy}&source=shop`,
		headers: {
			Accept: "application/json",
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BIO_SHOP_CATEGORY_POST_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BIO_SHOP_CATEGORY_POST_ERROR,
				payload: error.response,
			});
		});
};

export const addToFavourite = (token, postId, childrenId) => (dispatch) => {
	dispatch({ type: GET_ADD_TO_FAVOURITE_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/private/addToFavouriteUserList`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			post_id: postId,
			children_id: childrenId,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ADD_TO_FAVOURITE_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ADD_TO_FAVOURITE_ERROR,
				payload: error.response,
			});
		});
};

export const addUrl = (token, urls) => (dispatch) => {
	dispatch({ type: GET_ADD_TO_URL_REQUEST });
	return axios({
		method: "POST",
		url: `${TEMPURL}shorturl`,
		headers: { Authorization: `Bearer ${token}` },
		data: {
			url: urls,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_ADD_TO_URL_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_ADD_TO_URL_ERROR,
				payload: error.response,
			});
		});
};

export const getBioShopViewCount = (userId, username) => (dispatch) => {
	dispatch({ type: GET_BIO_SHOP_VIEW_COUNT_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}view/reserve`,
		headers: {
			Accept: "application/json",
		},
		data: {
			user_id: userId,
			username: username,
		},
	})
		.then((response) => {
			return dispatch({
				type: GET_BIO_SHOP_VIEW_COUNT_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: GET_BIO_SHOP_VIEW_COUNT_ERROR,
				payload: error.response,
			});
		});
};
