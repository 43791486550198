import { POST_REGISTER_OPEN_SUCCESS, POST_REGISTER_REQUEST, POST_REGISTER_SUCCESS, POST_REGISTER_ERROR, POST_BRAND_REGISTER_REQUEST, POST_BRAND_REGISTER_SUCCESS, POST_BRAND_REGISTER_ERROR } from "../types/types";
import { BASEURL } from "../../config";

import axios from "axios";
export const getCustomerRegister = (data) => (dispatch) => {

	dispatch({ type: POST_REGISTER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}mobile/auth/appUserSignup`,
		headers: {
			Accept: "application/json",
		},
		data: {
			name: data?.firstName + " " + data.lastName,
			nick_name: data.nickName,
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			zip:data.zipCode,
			gender: data.gender,
			country: data.country,
			state: data.state,
			city: data.city,
			password: data.password,
			device: "web",
			coming_from_app: false,
			brand: process.env.REACT_APP_BRAND_ID
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_REGISTER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_REGISTER_ERROR,
				payload: error.response,
			});
		});
};

export const getBrandRegister = (data) => (dispatch) => {
	dispatch({ type: POST_BRAND_REGISTER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}signup/user`,
		headers: {
			Accept: "application/json",
		},
		data: {
			account_type: "brand",
			name: data.name,
			email: data.email,
			gender: data.gender,
			country: data.country,
			state: data.state,
			city: data.city,
			password: data.password,
			device: "web",
			referred_by: data.refferedBy,
			zip: data.zipCode,
			coming_from_app: false,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_BRAND_REGISTER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_BRAND_REGISTER_ERROR,
				payload: error.response,
			});
		});
};

export const openRegister = (status, step) => (dispatch) => {
	return dispatch({
		type: POST_REGISTER_OPEN_SUCCESS,
		payload: { status: status, step: step },
	});
};