import Layout from "./component/layout";
import BrandWallet from "./component/brandWallet";
export default () => {
	return (
		<>
			<Layout>
				<BrandWallet />
			</Layout>
		</>
	);
};
