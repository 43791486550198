import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import MiniPlayer from "../../components/miniPlayer";
import HlsPlayer from "../../components/hlsPlayer/hlsPlayer";
import { getEventsBy_Id } from "../../redux/actions/liveEvent.action";
import { CONTROLS, POSITION } from "../../components/miniPlayer";
import { createBrowserHistory } from "history";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import closeicon from "../../images/brand-logos/close-icon.svg";
import ShortUrl from "../../components/shortUrl/shortUrl";
import { openLogin } from "../../redux/actions/login.action";
import { addUrl } from "../../redux/actions/bioShop.action";
import { getEventLikes } from "../../redux/actions/likesCount.action";
import { EyeOutlined } from "@ant-design/icons";
import MultipleHeart from "../../components/hearts/multipleHearts";
import { NavLink, useNavigate } from "react-router-dom";
import HeartLike from "../../components/hearts/heartLike";
import "../../css/hearts.scss";

export const history = createBrowserHistory();
export default (props) => {
	let userInfo = JSON.parse(localStorage.getItem("userInfo"));
	let id = props.id;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [eventId, setEvent] = useState([]);
	const [loading, setLoading] = useState(true);
	const [eventEnded, setEventEnded] = useState(false);
	const [replay, setReplay] = useState(false);
	const [showShare, setShowShare] = useState(false);

	useEffect(() => {
		if (id) {
			setLoading(true);
			dispatch(getEventsBy_Id(id, userInfo?._id)).then((res) => {
				if (res?.payload?.message?.length > 0) {
					setEvent(res?.payload?.message);
					setLoading(false);
					if (res?.payload?.message[0]?.event_status === "recorded") {
						setEventEnded(true);
					}
				} else {
					Swal.fire({
						icon: "error",
						title: "Event Ended",
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							navigate("/");
							window.location.reload();
						}
					});
					setLoading(false);
				}
			});
		}
	}, [id]);

	useEffect(() => {
		if (replay) {
			setLoading(true);
			dispatch(getEventsBy_Id(id, userInfo?._id)).then((res) => {
				if (res?.payload?.message?.length > 0) {
					setEvent(res?.payload?.message);
					setLoading(false);
					setEventEnded(false);
				} else {
					Swal.fire({
						icon: "error",
						title: "Event Ended",
					}).then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							navigate("/");
							window.location.reload();
						}
					});
					setLoading(false);
				}
			});
		}
	}, [replay]);
	const Navigates = (num) => {
		if (props.collapsed == true) {
			props.setCollapsed(!props.collapsed);
		}
		props.onKeyChange(num);
	};

	// const closeWindow = () => {
	// 	navigate(`/?liveEventClose&event_id=${id}`);
	// 	window.location.reload();
	// };

	const share = () => {
		let url = window.location.href;
		if (!userInfo) {
			dispatch(openLogin(true));
		} else {
			dispatch(addUrl(userInfo.token, shareAction(url)));
			setShowShare(true);
			shareAction(url);
		}
	};
	const shareAction = (url) => {
		const newUrl = url;
		const newRedirectUrl = new URL(newUrl);
		newRedirectUrl.searchParams.set("pixel_id", userInfo?.pixel_id);
		newRedirectUrl.searchParams.set("publisher_id", userInfo?._id);
		newRedirectUrl.searchParams.set("advertiser_id", eventId[0]?.brand[0]?._id);
		newRedirectUrl.searchParams.set("category_id", eventId[0]?.category_id);
		newRedirectUrl.searchParams.set("event_id", id);
		newRedirectUrl.searchParams.set("roiEvent", true);
		newRedirectUrl.searchParams.set("brand", 1);
		newRedirectUrl.searchParams.set("event", "eventshare");

		return newRedirectUrl.toString();
	};

	return (
		<>
			{loading ? (
				"loading"
			) : (
				<div className="lv-cntr App">
					{eventEnded && (
						<div className="event-ended-main-box">
							<div className="event-ended-box">
								<div className={`ended-event-poster ${eventId[0]?.default_banner ? "default-image" : ""}`}>
									<img src={eventId[0]?.banner} alt="" />
									<div className="event-ended-detail">
										<div className="event-ended-detail-inner">
											<h2>{eventId[0]?.thankyou_message ? eventId[0]?.thankyou_message : "This live event has ended"}</h2>
											<span className="ended-ev-title">{eventId[0]?.title}</span>
											<div className="ended-auther-name">
												<span className="ended-auth-image">
													<img src={eventId[0]?.brand[0]?.profile_image_url} alt="" />
												</span>
												<span className="ended-auth-title">by {eventId[0]?.brand[0]?.brand_name}</span>
											</div>
											<span className="notify-button">
												<Button
													onClick={() => {
														if (eventId[0]?.event_status === "recorded") {
															setEventEnded(false);
														} else {
															setReplay(true);
														}
													}}
												>
													Replay
												</Button>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<div className="brand-detail-overlay">
						<div className="brand-detail">
							<div className="author-name">
								<span className="auth-image">
									<img alt="" src={eventId[0]?.brand[0]?.profile_image_url} />
								</span>
								<div className="auth-detail">
									<span class="auth-title">{eventId[0]?.title}</span>
									<span className="event-name">by {eventId[0]?.brand[0]?.brand_name}</span>
								</div>
							</div>
						</div>
						<div className="live-txt"> {eventId[0]?.event_status === "live" ? "Live" : "Recorded"}</div>
						<div className="live-audiance">
							<EyeOutlined /> {props?.eventViews}
						</div>
					</div>
					<div className="close-live-event">
						<NavLink to="/" className="close-video">
							<img src={closeicon} className="filter-gray" />
						</NavLink>
					</div>
					<div className={eventId[0]?.event_status === "recorded" ? "drawer-buttons-outer drawer-for-recorded-events " : "drawer-buttons-outer"}>
						<div className="dr-1">
							<Tooltip placement="top" title="Details">
								<Button
									onClick={() => {
										Navigates("2");
									}}
								>
									<i class="fa fa-info" aria-hidden="true"></i>
								</Button>
							</Tooltip>
						</div>
						<div className="dr-1">
							<Tooltip placement="top" title="Shop">
								<Button
									onClick={() => {
										Navigates("3");

										//props.setCollapsed(!props.collapsed);
									}}
								>
									<i class="fa fa-dollar" aria-hidden="true"></i>
								</Button>
							</Tooltip>
						</div>
						<div className="dr-1">
							<Tooltip placement="top" title="Share">
								<Button
									onClick={() => {
										share();
									}}
								>
									<i class="fa fa-share-alt" aria-hidden="true"></i>
								</Button>
							</Tooltip>
						</div>
						<div className="dr-1">
							<Tooltip placement="top" title="Cart">
								<Button
									onClick={() => {
										Navigates("4");
									}}
								>
									<i class="fa fa-shopping-bag" aria-hidden="true"></i>
								</Button>
							</Tooltip>
						</div>
						<div className="dr-1">
							<Button
								onClick={() => {
									if (!userInfo) {
										dispatch(openLogin(true));
									} else {
										dispatch(getEventLikes(userInfo?.token, id));
										HeartLike();
									}
								}}
							>
								<i class="fa fa-heart-o" aria-hidden="true"></i>
							</Button>
						</div>
					</div>
					<div className="emoji-overlay"></div>
					<div className="custom-hearts">
						<MultipleHeart />
					</div>

					{eventId === [] ? (
						<>Loading......</>
					) : eventId[0]?.event_status === "recorded" ? (
						<div className="MiniPlayer">
							<div className="MiniPlayer-videoBox">
								<div className="MinPlayer-video large">
									<HlsPlayer streamUrl={eventId[0]?.recording_url} />
								</div>
							</div>
						</div>
					) : (
						<MiniPlayer setEventEnded={setEventEnded} streamUrl={eventId[0].stream_type === 'external' ? eventId[0].streaming_url : eventId[0]?.streamingData[0]?.channel?.playbackUrl} controls={[CONTROLS.resize, CONTROLS.close, CONTROLS.mute]} position={POSITION.bottomRight} event={eventId} transition />
					)}
				</div>
			)}
			<ShortUrl url={window.location.href} showShare={showShare} setShowShare={setShowShare} />
		</>
	);
};
