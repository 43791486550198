import { GET_BECOME_INFLUENCER_REQUEST, GET_BECOME_INFLUENCER_SUCCESS, GET_BECOME_INFLUENCER_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const getBecomeInfluencer =
	({ token, name, address, country, state, city, no_of_followers, zip }) =>
	(dispatch) => {
		dispatch({ type: GET_BECOME_INFLUENCER_REQUEST });
		return axios({
			method: "POST",
			url: `${BASEURL}mobile/private/becameInfluencer`,
			headers: { Authorization: `Bearer ${token}` },
			data: {
				name,
				address,
				country,
				state,
				city,
				no_of_followers,
				zip,
			},
		})
			.then((response) => {
				return dispatch({
					type: GET_BECOME_INFLUENCER_SUCCESS,
					payload: response.data,
				});
			})
			.catch((error) => {
				return dispatch({
					type: GET_BECOME_INFLUENCER_ERROR,
					payload: error.response,
				});
			});
	};
