export default () => {
	return (
		<>
			<h3>Privacy Policy</h3>
			<h5>KBShop</h5>
			<p>We at KONNECTBIO INC. ("KBShop," "we," and "us") know you care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our Privacy Policy. By using or accessing the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent that we will collect, use, and share your information in the following ways.</p>
			<h5>Personal information we collect:</h5>
			<p>Information you provide to us. We receive and keep any information you consciously provide to us. For example, through the registration process and through your account settings, we may collect Personal Information includes:</p>
			<ul className="list-simple">
				<li>
					<strong>Contact data,</strong> such as your first and last name, email address, mailing address, and phone number.
				</li>
				<li>
					<strong>Profile data,</strong> such as your username and password that you set to establish an online account with us, social network handles, biographical details, photographs (we use a content creator’s public Instagram photo as their default profile photo), links and descriptions that you add to your profile, interests, preferences, and any other information that you add to your account profile.
				</li>
				<li>
					<strong>Communications</strong> that we exchange, including when you contact us with questions or feedback, through social media, or otherwise, and that you exchange with others on or through the Service.
				</li>
				<li>
					<strong>Payments and transactional data</strong> needed to complete your orders, Requests, Support, or payments on or through the Service (such as payment card information, billing and shipping information, payment processor information).
				</li>
				<li>
					<strong>Marketing data,</strong> such as your preferences for receiving communications about our or our partners’ products, services, activities, and events, and details about how you engage with our communications.
				</li>
				<li>
					<strong>Content</strong> that you upload or share to the Service, including text, photos, and videos in connection with Requests, products, digital goods and your profile.
				</li>
				<li>
					<strong>Product data,</strong> such as the description of a product or digital good for sale, its price, and its commission.
				</li>
				<li>
					<strong>Other information</strong> that we may collect which is not specifically listed here, which we will use as described in this Privacy Policy or as otherwise described at the time of collection.
				</li>
				<li>
					<strong>Third party sources.</strong> We may combine personal information we receive from you with personal information we obtain from other sources, such as:
					<ul>
						<li>
							<strong>Public sources,</strong> such as social media platforms.
						</li>
						<li>
							<strong>Business partners,</strong> such as companies that have entered into joint marketing relationships or other joint ventures with us, merchants, and affiliate marketplaces.
						</li>
					</ul>
				</li>
				<li>
					<strong>Automatic data collection.</strong> We, our service providers, may automatically log information about you, your computer or mobile device, your activity over time on our sites and other online services, and your interactions with our marketing communications such as:
					<ul>
						<li>
							<strong>Device data,</strong> such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 3G), and general location information such as city, state or geographic area.
						</li>
						<li>
							<strong>Online activity data,</strong> such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Service, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them.
						</li>
					</ul>
				</li>
				<li>
					<strong>Cookies.</strong> Some of our automatic data collection is facilitated by cookies and similar technologies.
				</li>
				<li>
					<strong>Data about others.</strong> Users of the Service may have the opportunity to refer friends or other contacts to us and share their contact information with us. Please do not refer someone to us or share their contact information with us unless you have their permission to do so.
				</li>
			</ul>
			<h5>How we use your personal information:</h5>
			<p>We use your personal information for the following purposes or as otherwise described at the time we collect it:</p>
			<p>Service delivery. We use your personal information to:</p>
			<ul className="list-simple">
				<li>provide, operate and improve the Service and our business;</li>
				<li>process and track your orders;</li>
				<li>facilitate payments;</li>
				<li>establish and maintain your user profile on the Service;</li>
				<li>enable security features of the Service, such as by sending you security codes via email, and remembering devices from which you have previously logged in;</li>
				<li>communicate with you about the Service, including by sending announcements, updates, security alerts, and support and administrative messages;</li>
				<li>understand your needs and interests, and personalize your experience with the Service and our communications; and</li>
				<li>provide support for the Service, and respond to your requests, questions and feedback.</li>
			</ul>
			<p>
				<strong>Research and development.</strong> We may use your personal information for research and development purposes, including to analyze and improve the Service and our business. As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.
			</p>
			<p>
				<strong>Marketing.</strong> We may send you for KBShop-related or other direct marketing communications as permitted by law. You may opt-out of our marketing communications as described in the Opt-out of marketing section below.
			</p>
			<p>
				<strong>Compliance and protection.</strong> We may use your personal information to:
			</p>
			<ul className="list-simple">
				<li>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;</li>
				<li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
				<li>audit our internal processes for compliance with legal and contractual requirements and internal policies;</li>
				<li>enforce the terms and conditions that govern the Service; and</li>
				<li>Prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyber-attacks and identity theft.</li>
			</ul>
			<h5>How we share your personal information:</h5>
			<p>We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection:</p>
			<p>
				<strong>Affiliates.</strong> Our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.
			</p>
			<p>
				<strong>Service providers.</strong> Companies and individuals that provide services on our behalf or help us operate the Service or our business (such as information technology, hosting, customer relationship management and support, payment processing, email delivery, advertising, marketing, and website analytics).
			</p>
			<p>
				<strong>Professional advisors.</strong> Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.
			</p>
			<p>
				<strong>Authorities and others.</strong> Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes described above.
			</p>
			<p>
				<strong>Business transferees.</strong> Acquiring and other relevant parties to business transactions (or potential transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, KBShop or our affiliates (including, in connection with a bankruptcy or similar proceedings).
			</p>
			<p>
				<strong>Other users and the public.</strong> You may choose to make publicly visible some of the information on your profile. If you submit a Request to a content creator, you can choose to make the Request public or private. Any information that is not private may be associated with your profile and can be collected and used by others. We cannot control who reads the information that you make viewable or what they may choose to do with it.
			</p>
			<h5>Your Rights:</h5>
			<p>You have the following rights with respect to your personal information.</p>
			<p>
				<strong>Access or update your information.</strong> If you have registered for an online account with us, you may review and update certain account information from your account.
			</p>
			<p>
				<strong>Opt-out of marketing communications.</strong> You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. You may continue to receive service-related and other non-marketing emails.
			</p>
			<p>
				<strong>Cookies.</strong> For information about cookies employed by the Service and how to control them, see our Cookie Notice.
			</p>
			<p>
				<strong>Do Not Track.</strong> Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com.</a>
			</p>
			<p>
				<strong>Declining to provide information.</strong> We need to collect personal information to provide certain services. If you do not provide the information we identify as mandatory, we may not be able to provide those services.
			</p>
			<p>
				<strong>Third party platforms.</strong> If you choose to connect to the Service through your social media account, you may be able to use your settings in your account with that platform to limit the information we receive from it. If you revoke our ability to access information from a third party platform, that choice will not apply to information that we have already received from that third party.
			</p>
			<p>
				<strong>Privacy settings.</strong> In your account’s privacy settings, you may choose to make certain profile information publicly visible and whether you want third parties to be able to find or contact you through the Service.
			</p>
			<h5>Links to Other sites and services:</h5>
			<p>The Service may contain links to websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or other online services that are not associated with us. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions.</p>
			<h5>Security of Data:</h5>
			<p>The Security of your data is important us, but remember that no method of transmission over the internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security. Authorize employee, representatives and agents will have access to your Personal Data for the purpose described in this Privacy Policy.</p>
			<h5>International data transfer:</h5>
			<p>We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.</p>
			<h5>Children:</h5>
			<p>The Service is not intended for use by children under 13 years of age. We do not knowingly collect personal information through the Service from a child under 13 without the consent of the child’s parent or guardian as required by law, we will delete it.</p>
			<h5>Changes to this Privacy Policy:</h5>
			<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
			<p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
			<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
			<h5>How to Contact Us:</h5>
			<p>Please direct any questions or comments about this Policy to:</p>
			<p>
				KONNECTBIO INC.
				<br />
				121 Varick Street, 4th Floor
				<br /> New York, NY 10013
				<br /> <a href="mailto:support@kbshop.com">support@kbshop.com</a>
			</p>
		</>
	);
};