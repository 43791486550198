import React, { useState, useEffect } from "react";
import { DatePicker, Button, Form, Empty, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getCommissionReport, getEarnCommission } from "../../../redux/actions/wallet.action";
import Table from "react-bootstrap/Table";
import wallet1 from "../../../images/brand-logos/commission-discounts.svg";
import numeral from "numeral";

const { RangePicker } = DatePicker;

export default () => {
	const dispatch = useDispatch();
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [form] = Form.useForm();
	const dateFormat = "YYYY-MM-DD";
	const { commissionEarn, commission } = useSelector((state) => state);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	useEffect(() => {
		dispatch(getEarnCommission(userInfo?.token));
		dispatch(getCommissionReport(userInfo?.token));
	}, []);

	const onFinish = async () => {
		if (userInfo.token) {
			dispatch(getCommissionReport(userInfo?.token, startDate, endDate));
			dispatch(getEarnCommission(userInfo?.token, startDate, endDate));
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	return (
		<div className="account-left-content">
			<h1>Earned Commission</h1>
			<div className="wallet-content-main">
				<div className="wallet-row-1">
					<div className="balance-box">
						<div className="inr-box-main">
							<div className="wallet-icon">
								<img src={wallet1} alt="" className="filter-gray" />
							</div>
							<div className="wallet-data">
								<div className="wallet-detail">
									<span className="box-title">This Month</span><div className="box-ammount">
										${commissionEarn?.payload?.data?.dateRangeEarningTotal ? numeral(commissionEarn?.payload?.data?.dateRangeEarningTotal).format('0.00') : 0} 
										{/* <span>July</span> */}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="balance-box">
						<div className="inr-box-main">
							<div className="wallet-icon">
								<img src={wallet1} alt="" className="filter-gray" />
							</div>
							<div className="wallet-data">
								<div className="wallet-detail">
									<span className="box-title">Total Earnings</span>
									<div className="box-ammount">${commissionEarn?.payload?.data?.total_balance ? numeral(commissionEarn?.payload?.data?.total_balance).format('0.00') : 0}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="wallet-row-2">
					<div className="wallet-header">
						<h3>Product List</h3>
						<Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
							<div className="wallet-header-right">
								<RangePicker
									format={dateFormat}
									onChange={(e, value) => {
										setStartDate(value[0]);
										setEndDate(value[1]);
									}}
								/>
								<Button type="primary" htmlType="submit">
									Submit
								</Button>
							</div>
						</Form>
					</div>
					<div className="purchase-data">
						<Table responsive="lg">
							<thead>
								<tr>
									<th>Product Name</th>
									<th>Category</th>
									<th>Brand</th>
									<th>Price</th>
									<th>Earning</th>
								</tr>
							</thead>
							<tbody>
								{commission?.loading ? (
									<div className="antd-loading-ift">
										<Spin tip="Loading..."></Spin>
									</div>
								) : commission?.payload?.message?.data?.length === 0 ? (
									<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
								) : (
									commission?.payload?.message?.data?.map((item) => {
										return (
											<tr>
												<td style={{ width: "370px" }}>
													<div className="pro-name">
														<div className="pro-boxes-logos">
															<img src={item?.post?.media_url} />
														</div>
														<div className="brand-user-pro">{item?.post?.children[0]?.ProductName}</div>
													</div>
												</td>
												<td>{item?.parent_category?.category_name}</td>
												<td>{item?.brand_name}</td>
												<td>${numeral(item?.total_sale).format("0.00")}</td>
												<td>${numeral(item?.customer_commission).format("0.00")}</td>
											</tr>
										);
									})
								)}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
};