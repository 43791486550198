import { GET_COMMISSION_EARN_REPORT_REQUEST, GET_COMMISSION_EARN_REPORT_SUCCESS, GET_COMMISSION_EARN_REPORT_ERROR } from "../../types/types";
export default (state = [], action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_COMMISSION_EARN_REPORT_REQUEST:
			return {
				loading: true,
			};
		case GET_COMMISSION_EARN_REPORT_SUCCESS:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		case GET_COMMISSION_EARN_REPORT_ERROR:
			return {
				...state,
				loading: false,
				payload: payload,
			};

		default:
			return state;
	}
};
