import React from "react";
import BreadCrum from "../../components/breadCrum/breadCrum";
import PopularInf from "../../components/popularInfluencerCategory/popularInfluencerCategory";
import AllInfCategories from "../../pages/category/influencerCategory";
import "../../css/category.scss";
import "react-multi-carousel/lib/styles.css";

function Influencer() {
	return (
		<>
			<BreadCrum categoryName={"Influencers"} />
			<PopularInf />
			<AllInfCategories />
		</>
	);
}
export default Influencer;
