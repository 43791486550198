import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../hearts/multiple-hearts.json";

export default function MultipleHearts() {
  // set initial state property

  const [isStopped, setIsStopped] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setInterval(() => {
      setIsStopped(false);
      setTimeout(() => {
        setIsStopped(true);
      }, 2200);
    }, 20000);
  }, []);

  return (
    <div className="streaming-heart">
      {/* add Lottie component */}
      <Lottie
        options={defaultOptions}
        //  height={1200}
        // width={65}
        isStopped={isStopped}
      />
    </div>
  );
}
