import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "react-multi-carousel/lib/styles.css";
import { Spin } from "antd";
import "../../../css/bioshop.scss";
import { getBioShopMenu } from "../../../redux/actions/bioShop.action";

export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { bioShopMenu } = useSelector((state) => state);
  useEffect(() => {
    if (bioShopMenu.length === 0) {
      dispatch(getBioShopMenu(params.username));
    }
  }, []);
  return (
    <>
      {bioShopMenu.loading ? (
        <>
          <Spin tip="Loading..."></Spin>
        </>
      ) : (
        <>
          <div className="justify-content-md-center bioshop-profile-page">
            <div className="brand-logo">
              <div className="profile-logo-brnd">
                <img
                  src={bioShopMenu?.payload?.message?.data?.profile_image_url}
                />
              </div>
            </div>
            <div className="brand-detail">
              <h2>{bioShopMenu?.payload?.message?.data?.name}</h2>
              <p>{bioShopMenu?.payload?.message?.data?.bio}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};