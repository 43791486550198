import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, useDispatch } from "react-redux";
import SkeletonPosts from "../../../components/skeletons/SkeletonPosts";
import { getBioShopCategoryPost, getBioShopPostClick } from "../../../redux/actions/bioShop.action";
import Popup from "./popup";
import numeral from "numeral";
import queryString from "query-string";

export default () => {
	const dispatch = useDispatch();
	const params = useParams();
	const { bioShopMenu } = useSelector((state) => state);
	const [showCustomerModal, setsShowCustomerModal] = useState(false);
	const [post, setPost] = useState([]);
	const [loading, setLoading] = useState(true);
	const [singleItem, setSingleItem] = useState("");
	const [hasMore, sethasMore] = useState(true);
	const [page, setpage] = useState(2);
	const userId = bioShopMenu?.payload?.message?.data._id;
	const queryParams = queryString.parse(window.location.search);

	useEffect(() => {
		setpage(2);
	}, [params.username]);

	useEffect(() => {
		setLoading(true);
		dispatch(getBioShopCategoryPost(params.username, 1, 18, params.categoryId, params.parentId, params.sub, queryParams.post_type)).then((res) => {
			setPost(res?.payload?.message?.result.data);
			setLoading(false);
			sethasMore(res?.payload?.message?.result?.next?.page ? true : false);
		});
	}, [params.parentId]);

	const fetchMorePost = async () => {
		if (hasMore) {
			return dispatch(getBioShopCategoryPost(params.username, page, 18, params.categoryId, params.parentId, params.sub, queryParams.post_type)).then((res) => {
				if (res?.payload?.message?.result?.data?.length) {
					sethasMore(res?.payload?.message?.result?.next?.page ? true : false);
					return res?.payload?.message?.result.data;
				}
			});
		}
	};

	const discountPercent = (percent, actualAmount) => {
		let num = percent.replace(/[^0-9]/g, "");
		return ((num / 100) * actualAmount).toFixed(2);
	};

	const fetchData = async () => {
		const postData = await fetchMorePost();
		setPost([...post, ...postData]);
		setpage(page + 1);
	};

	const clickModal = (data) => {
		setsShowCustomerModal(true);
		setSingleItem(data);
	};

	return (
		<>
			<div className="bio_cat_images">
				<section className="tabsSecMain">
					<div className="tab-content" id="myTabContent">
						<div className="tab-pane fade show active">
							{loading ? (
								[1, 2].map((n) => <SkeletonPosts key={n} theme="light" />)
							) : post?.length > 0 ? (
								<>
									<InfiniteScroll
										dataLength={post.length}
										className="post-grid"
										next={fetchData}
										hasMore={hasMore}
										loader={
											<div className={`container`}>
												<div className="d-flex justify-content-center">
													<div className="content-loader"></div>
												</div>
											</div>
										}
										// useWindow={false}
									>
										{post.map((item, i) => (
											<div
												key={i}
												className="post-image"
												//	onClick={() => clickModal(true)}
											>
												<div
													onClick={() => {
														if (item?.children?.length > 0) clickModal({ ...item, index: i });
													}}
													className={`image-box ${item.media_type === "VIDEO" && "play-button"}`}
												>
													{item.media_type === "VIDEO" && <i className="fa fa-play-circle" aria-hidden="true"></i>}
													{item?.children.length ? (
														<span>
															{item.media_type === "VIDEO" ? (
																<>
																	<video id={`post-video-${item.post_id}`} controlsList="nodownload" preload="metadata" poster={item.media_url}>
																		<source src={item.media_url + "#t=0.001"} type="video/mp4"></source>
																	</video>
																</>
															) : (
																<>
																	<img src={item.media_url} alt="media_url" className="rounded" />
																</>
															)}
														</span>
													) : (
														<>
															{item.media_type === "VIDEO" ? (
																<>
																	{" "}
																	<span>
																		<video
																			onMouseDown={(e) => {
																				if (e.nativeEvent.button === 1) {
																					window.open(item.redirected_url);
																					dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																				}
																			}}
																			onClick={(ev) => {
																				window.open(item.redirected_url);
																				dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																			}}
																			key={i}
																			id={`post-video-${item.post_id}`}
																			controlsList="nodownload"
																			preload="metadata"
																			poster={item.media_url}
																		>
																			<source src={item.media_url + "#t=0.001"} type="video/mp4"></source>
																		</video>
																	</span>
																</>
															) : (
																<span
																	onMouseDown={(e) => {
																		if (e.nativeEvent.button === 1) {
																			window.open(item?.redirected_url);
																			dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																		}
																	}}
																	onClick={(ev) => {
																		dispatch(getBioShopPostClick(item.post_id, params.username, item.media_url, item.media_type, item.caption, item.timestamp, userId, item.post_type, item.media_id));
																	}}
																	key={i}
																>
																	<a href={item?.redirected_url} target="_blank" className="image_link">
																		<img src={item?.media_url} alt="media_image" className="rounded image-inner-media" />
																	</a>
																</span>
															)}
														</>
													)}
												</div>
												{item?.children?.length > 0 && (
													<div className="cat-pro-detail">
														<div className="cat-pro-title">
															<span className="bio-item-title">{item?.children[0]?.ProductName}</span>
															<span className="bio-item-fee">Referral Fee 3%</span>
														</div>
														<div className="cat-pro-price">
															<span>{item.children[0]?.productPromoCodePromo === "KB0" ? `$${numeral(item.children[0]?.productAmount).format("0.00")}` : item.children[0]?.productPromoCodeDscs?.includes("%") ? `$${numeral(item.children[0]?.productAmount - discountPercent(item.children[0]?.productPromoCodeDscs, item.children[0]?.productAmount)).format("0.00")}  ` : `$${numeral(item.children[0]?.productAmount - item.children[0]?.productPromoCodeDscs.replace(/[^0-9]/g, "")).format("0.00")}`}</span>
															{item.children[0]?.productPromoCodePromo === "KB0" ? <span></span> : <span>${numeral(item.children[0]?.productAmount).format("0.00")}</span>}
														</div>
													</div>
												)}
											</div>
										))}
									</InfiniteScroll>
								</>
							) : (
								<div className="no-data-found">
									<h1>Nothing Here Yet</h1>
									<p>{params.username} hasn't added anything.</p>
								</div>
							)}
						</div>
					</div>
				</section>
			</div>
			<Popup
				fetchData={(status) => {
					if (status) {
						fetchData();
					}
				}}
				showCustomerModal={showCustomerModal}
				setsShowCustomerModal={setsShowCustomerModal}
				username={params.username}
				item={singleItem}
				post={post?.map((item, i) => {
					return {
						...item,
						index: i,
					};
				})}
			/>
		</>
	);
};