export const GET_ALL_PROFILE_REQUEST = 'GET_ALL_PROFILE_REQUEST';
export const GET_ALL_PROFILE_SUCCESS = 'GET_ALL_PROFILE_SUCCESS';
export const GET_ALL_PROFILE_ERROR = 'GET_ALL_PROFILE_ERROR';

export const GET_POPULAR_CATEGORIES_REQUEST = 'GET_POPULAR_CATEGORIES_REQUEST';
export const GET_POPULAR_CATEGORIES_SUCCESS = 'GET_POPULAR_CATEGORIES_SUCCESS';
export const GET_POPULAR_CATEGORIES_ERROR = 'GET_POPULAR_CATEGORIES_ERROR';

export const GET_PARENT_CATEGORIES_REQUEST = 'GET_PARENT_CATEGORIES_REQUEST';
export const GET_PARENT_CATEGORIES_SUCCESS = 'GET_PARENT_CATEGORIES_SUCCESS';
export const GET_PARENT_CATEGORIES_ERROR = 'GET_PARENT_CATEGORIES_ERROR';

export const GET_ALL_BRAND_REQUEST = 'GET_ALL_BRAND_REQUEST';
export const GET_ALL_BRAND_SUCCESS = 'GET_ALL_BRAND_SUCCESS';
export const GET_ALL_BRAND_ERROR = 'GET_ALL_BRAND_ERROR';

export const GET_ALL_INFLUENCER_REQUEST = 'GET_ALL_INFLUENCER_REQUEST';
export const GET_ALL_INFLUENCER_SUCCESS = 'GET_ALL_INFLUENCER_SUCCESS';
export const GET_ALL_INFLUENCER_ERROR = 'GET_ALL_INFLUENCER_ERROR';

export const GET_FEATURE_BRAND_REQUEST = 'GET_FEATURE_BRAND_REQUEST';
export const GET_FEATURE_BRAND_SUCCESS = 'GET_FEATURE_BRAND_SUCCESS';
export const GET_FEATURE_BRAND_ERROR = 'GET_FEATURE_BRAND_ERROR';

export const GET_USER_BRAND_REQUEST = 'GET_USER_BRAND_REQUEST';
export const GET_USER_BRAND_SUCCESS = 'GET_USER_BRAND_SUCCESS';
export const GET_USER_BRAND_ERROR = 'GET_USER_BRAND_ERROR';

export const GET_SUB_CATEGORIES_REQUEST = 'GET_SUB_CATEGORIES_REQUEST';
export const GET_SUB_CATEGORIES_SUCCESS = 'GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_ERROR = 'GET_SUB_CATEGORIES_ERROR';

export const GET_POPULAR_INFLUENCER_REQUEST = 'GET_POPULAR_INFLUENCER_REQUEST';
export const GET_POPULAR_INFLUENCER_SUCCESS = 'GET_POPULAR_INFLUENCER_SUCCESS';
export const GET_POPULAR_INFLUENCER_ERROR = 'GET_POPULAR_INFLUENCER_ERROR';

export const GET_CATEGORY_WISE_BRAND_REQUEST =
  'GET_CATEGORY_WISE_BRAND_REQUEST';
export const GET_CATEGORY_WISE_BRAND_SUCCESS =
  'GET_CATEGORY_WISE_BRAND_SUCCESS';
export const GET_CATEGORY_WISE_BRAND_ERROR = 'tGET_CATEGORY_WISE_BRAND_ERROR';

export const GET_CATEGORY_WISE_INFLUENCER_REQUEST =
  'GET_CATEGORY_WISE_INFLUENCER_REQUEST';
export const GET_CATEGORY_WISE_INFLUENCER_SUCCESS =
  'GET_CATEGORY_WISE_INFLUENCER_SUCCESS';
export const GET_CATEGORY_WISE_INFLUENCER_ERROR =
  'GET_CATEGORY_WISE_INFLUENCER_ERROR';

export const GET_BIO_SHOP_MENU_REQUEST = 'GET_BIO_SHOP_MENU_REQUEST';
export const GET_BIO_SHOP_MENU_SUCCESS = 'GET_BIO_SHOP_MENU_SUCCESS';
export const GET_BIO_SHOP_MENU_ERROR = 'GET_BIO_SHOP_MENU_ERROR';

export const POST_LOGIN_REQUEST = 'POST_LOGIN_REQUEST';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';

export const GET_BIO_SHOP_ALL_POST_REQUEST = 'GET_BIO_SHOP_ALL_POST_REQUEST';
export const GET_BIO_SHOP_ALL_POST_SUCCESS = 'GET_BIO_SHOP_ALL_POST_SUCCESS';
export const GET_BIO_SHOP_ALL_POST_ERROR = 'GET_BIO_SHOP_ALL_POST_ERROR';

export const GET_BIO_SHOP_ALL_LINK_REQUEST = 'GET_BIO_SHOP_ALL_LINK_REQUEST';
export const GET_BIO_SHOP_ALL_LINK_SUCCESS = 'GET_BIO_SHOP_ALL_LINK_SUCCESS';
export const GET_BIO_SHOP_ALL_LINK_ERROR = 'GET_BIO_SHOP_ALL_LINK_ERROR';

export const GET_BIO_SHOP_LINK_CLINK_REQUEST =
  'GET_BIO_SHOP_LINK_CLINK_REQUEST';
export const GET_BIO_SHOP_LINK_CLINK_SUCCESS =
  'GET_BIO_SHOP_LINK_CLINK_SUCCESS';
export const GET_BIO_SHOP_LINK_CLINK_ERROR = 'GET_BIO_SHOP_LINK_CLINK_ERROR';
export const POST_COUNTRY_REQUEST = 'POST_COUNTRY_REQUEST';
export const POST_COUNTRY_SUCCESS = 'POST_COUNTRY_SUCCESS';
export const POST_COUNTRY_ERROR = 'POST_COUNTRY_ERROR';

export const POST_STATE_REQUEST = 'POST_STATE_REQUEST';
export const POST_STATE_SUCCESS = 'POST_STATE_SUCCESS';
export const POST_STATE_ERROR = 'POST_STATE_ERROR';

export const POST_CITY_REQUEST = 'POST_CITY_REQUEST';
export const POST_CITY_SUCCESS = 'POST_CITY_SUCCESS';
export const POST_CITY_ERROR = 'POST_CITY_ERROR';

export const POST_REGISTER_REQUEST = 'POST_REGISTER_REQUEST';
export const POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS';
export const POST_REGISTER_ERROR = 'POST_REGISTER_ERROR';

export const GET_BIO_SHOP_CATEGORY_POST_REQUEST =
  'GET_BIO_SHOP_CATEGORY_POST_REQUEST';
export const GET_BIO_SHOP_CATEGORY_POST_SUCCESS =
  'GET_BIO_SHOP_CATEGORY_POST_SUCCESS';
export const GET_BIO_SHOP_CATEGORY_POST_ERROR =
  'GET_BIO_SHOP_CATEGORY_POST_ERROR';

export const GET_ADD_TO_FAVOURITE_REQUEST = 'GET_ADD_TO_FAVOURITE_REQUEST';
export const GET_ADD_TO_FAVOURITE_SUCCESS = 'GET_ADD_TO_FAVOURITE_SUCCESS';
export const GET_ADD_TO_FAVOURITE_ERROR = 'GET_ADD_TO_FAVOURITE_ERROR';

export const GET_ADD_TO_URL_SUCCESS = 'GET_ADD_TO_URL_SUCCESS';
export const GET_ADD_TO_URL_ERROR = 'GET_ADD_TO_URL_ERROR';
export const GET_ADD_TO_URL_REQUEST = 'GET_ADD_TO_URL_REQUEST';

export const POST_LOGIN_OPEN_REQUEST = 'POST_LOGIN_OPEN_REQUEST';
export const POST_LOGIN_OPEN_SUCCESS = 'POST_LOGIN_OPEN_SUCCESS';
export const POST_LOGIN_OPEN_ERROR = 'POST_LOGIN_OPEN_ERROR';

export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';

export const GET_FAVOURITE_BRAND_REQUEST = 'GET_FAVOURITE_BRAND_REQUEST';
export const GET_FAVOURITE_BRAND_SUCCESS = 'GET_FAVOURITE_BRAND_SUCCESS';
export const GET_FAVOURITE_BRAND_ERROR = 'GET_FAVOURITE_BRAND_ERROR';

export const GET_HOT_DEAL_REQUEST = 'GET_HOT_DEAL_REQUEST';
export const GET_HOT_DEAL_SUCCESS = 'GET_HOT_DEAL_SUCCESS';
export const GET_HOT_DEAL_ERROR = 'GET_HOT_DEAL_ERROR';

export const GET_CATEGORY_BANNER_REQUEST = 'GET_CATEGORY_BANNER_REQUEST';
export const GET_CATEGORY_BANNER_SUCCESS = 'GET_CATEGORY_BANNER_SUCCESS';
export const GET_CATEGORY_BANNER_ERROR = 'GET_CATEGORY_BANNER_ERROR';

export const GET_CATEGORY_FAVOURITE_BRAND_REQUEST =
  'GET_CATEGORY_FAVOURITE_BRAND_REQUEST';
export const GET_CATEGORY_FAVOURITE_BRAND_SUCCESS =
  'GET_CATEGORY_FAVOURITE_BRAND_SUCCESS';
export const GET_CATEGORY_FAVOURITE_BRAND_ERROR =
  'GET_CATEGORY_FAVOURITE_BRAND_ERROR';

export const GET_CATEGORY_FEATURE_BRAND_REQUEST =
  'GET_CATEGORY_FEATURE_BRAND_REQUEST';
export const GET_CATEGORY_FEATURE_BRAND_SUCCESS =
  'GET_CATEGORY_FEATURE_BRAND_SUCCESS';
export const GET_CATEGORY_FEATURE_BRAND_ERROR =
  'GET_CATEGORY_FEATURE_BRAND_ERROR';

export const GET_CHANGE_PASSWORD_REQUEST = 'GET_CHANGE_PASSWORD_REQUEST';
export const GET_CHANGE_PASSWORD_SUCCESS = 'GET_CHANGE_PASSWORD_SUCCESS';
export const GET_CHANGE_PASSWORD_ERROR = 'GET_CHANGE_PASSWORD_ERROR';

export const POST_SELECTED_COUNTRY_REQUEST = 'POST_SELECTED_COUNTRY_REQUEST';
export const POST_SELECTED_COUNTRY_SUCCESS = 'POST_SELECTED_COUNTRY_SUCCESS';
export const POST_SELECTED_COUNTRY_ERROR = 'POST_SELECTED_COUNTRY_ERROR';

export const POST_BRAND_REGISTER_REQUEST = 'POST_BRAND_REGISTER_REQUEST';
export const POST_BRAND_REGISTER_SUCCESS = 'POST_BRAND_REGISTER_SUCCESS';
export const POST_BRAND_REGISTER_ERROR = 'POST_BRAND_REGISTER_ERROR';

export const GET_BIO_SHOP_VIEW_COUNT_REQUEST =
  'GET_BIO_SHOP_VIEW_COUNT_REQUEST';
export const GET_BIO_SHOP_VIEW_COUNT_SUCCESS =
  'GET_BIO_SHOP_VIEW_COUNT_SUCCESS';
export const GET_BIO_SHOP_VIEW_COUNT_ERROR = 'GET_BIO_SHOP_VIEW_COUNT_ERROR';
export const POST_PASSWORD_MODAL_OPEN_REQUEST =
  'POST_PASSWORD_MODAL_OPEN_REQUEST';
export const POST_PASSWORD_MODAL_OPEN_SUCCESS =
  'POST_PASSWORD_MODAL_OPEN_SUCCESS';
export const POST_PASSWORD_MODAL_OPEN_ERROR = 'POST_PASSWORD_MODAL_OPEN_ERROR';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const GET_UPDATE_ACCOUNT_REQUEST = 'GET_UPDATE_ACCOUNT_REQUEST';
export const GET_UPDATE_ACCOUNT_SUCCESS = 'GET_UPDATE_ACCOUNT_SUCCESS';
export const GET_UPDATE_ACCOUNT_ERROR = 'GET_UPDATE_ACCOUNT_ERROR';

export const GET_BECOME_INFLUENCER_REQUEST = 'GET_BECOME_INFLUENCER_REQUEST';
export const GET_BECOME_INFLUENCER_SUCCESS = 'GET_BECOME_INFLUENCER_SUCCESS';
export const GET_BECOME_INFLUENCER_ERROR = 'GET_BECOME_INFLUENCER_ERROR';

export const GET_REMOVE_FAVOURITE_LIST_REQUEST =
  'GET_REMOVE_FAVOURITE_LIST_REQUEST';
export const GET_REMOVE_FAVOURITE_LIST_SUCCESS =
  'GET_REMOVE_FAVOURITE_LIST_SUCCESS';
export const GET_REMOVE_FAVOURITE_LIST_ERROR =
  'GET_REMOVE_FAVOURITE_LIST_ERROR';

export const GET_REMOVE_FAVOURITE_REQUEST = 'GET_REMOVE_FAVOURITE_REQUEST';
export const GET_REMOVE_FAVOURITE_SUCCESS = 'GET_REMOVE_FAVOURITE_SUCCESS';
export const GET_REMOVE_FAVOURITE_ERROR = 'GET_REMOVE_FAVOURITE_ERROR';

export const GET_FAVOURITE_LIST_REQUEST = 'GET_FAVOURITE_LIST_REQUEST';
export const GET_FAVOURITE_LIST_SUCCESS = 'GET_FAVOURITE_LIST_SUCCESS';
export const GET_FAVOURITE_LIST_ERROR = 'GET_FAVOURITE_LIST_ERROR';

export const GET_FAVOURITE_BRAND_LIST_REQUEST =
  'GET_FAVOURITE_BRAND_LIST_REQUEST';
export const GET_FAVOURITE_BRAND_LIST_SUCCESS =
  'GET_FAVOURITE_BRAND_LIST_SUCCESS';
export const GET_FAVOURITE_BRAND_LIST_ERROR = 'GET_FAVOURITE_BRAND_LIST_ERROR';

export const GET_REMOVE_FAVOURITE_BRAND_REQUEST =
  'GET_REMOVE_FAVOURITE_BRAND_REQUEST';
export const GET_REMOVE_FAVOURITE_BRAND_SUCCESS =
  'GET_REMOVE_FAVOURITE_BRAND_SUCCESS';
export const GET_REMOVE_FAVOURITE_BRAND_ERROR =
  'GET_REMOVE_FAVOURITE_BRAND_ERROR';

export const GET_ADD_TO_FAVOURITE_BRAND_REQUEST =
  'GET_ADD_TO_FAVOURITE_BRAND_REQUEST';
export const GET_ADD_TO_FAVOURITE_BRAND_SUCCESS =
  'GET_ADD_TO_FAVOURITE_BRAND_SUCCESS';
export const GET_ADD_TO_FAVOURITE_BRAND_ERROR =
  'GET_ADD_TO_FAVOURITE_BRAND_ERROR';

export const GET_FAVOURITE_INFLUENCER_LIST_REQUEST =
  'GET_FAVOURITE_INFLUENCER_LIST_REQUEST';
export const GET_FAVOURITE_INFLUENCER_LIST_SUCCESS =
  'GET_FAVOURITE_INFLUENCER_LIST_SUCCESS';
export const GET_FAVOURITE_INFLUENCER_LIST_ERROR =
  'GET_FAVOURITE_INFLUENCER_LIST_ERROR';

export const GET_PURCHASE_REPORT_REQUEST = 'GET_PURCHASE_REPORT_REQUEST';
export const GET_PURCHASE_REPORT_SUCCESS = 'GET_PURCHASE_REPORT_SUCCESS';
export const GET_PURCHASE_REPORT_ERROR = 'GET_PURCHASE_REPORT_ERROR';

export const POST_REGISTER_OPEN_REQUEST = 'POST_REGISTER_OPEN_REQUEST';
export const POST_REGISTER_OPEN_SUCCESS = 'POST_REGISTER_OPEN_SUCCESS';
export const POST_REGISTER_OPEN_ERROR = 'POST_REGISTER_OPEN_ERROR';

export const GET_COMMISSION_REPORT_REQUEST = 'GET_COMMISSION_REPORT_REQUEST';
export const GET_COMMISSION_REPORT_SUCCESS = 'GET_COMMISSION_REPORT_SUCCESS';
export const GET_COMMISSION_REPORT_ERROR = 'GET_COMMISSION_REPORT_ERROR';

export const GET_COMMISSION_EARN_REPORT_REQUEST =
  'GET_COMMISSION_REPORT_REQUEST';
export const GET_COMMISSION_EARN_REPORT_SUCCESS =
  'GET_COMMISSION_EARN_REPORT_SUCCESS';
export const GET_COMMISSION_EARN_REPORT_ERROR =
  'GET_COMMISSION_EARN_REPORT_ERROR';

export const POST_CONTACT_REQUEST = 'POST_CONTACT_REQUEST';
export const POST_CONTACT_SUCCESS = 'POST_CONTACT_SUCCESS';
export const POST_CONTACT_ERROR = 'POST_CONTACT_ERROR';

export const GET_ALL_WITHDRAW_REQUEST = 'GET_ALL_WITHDRAW_REQUEST';
export const GET_ALL_WITHDRAW_SUCCESS = 'GET_ALL_WITHDRAW_SUCCESS';
export const GET_ALL_WITHDRAW_ERROR = 'GET_ALL_WITHDRAW_ERROR';

export const POST_WITHDRAW_REQUEST = 'POST_WITHDRAW_REQUEST';
export const POST_WITHDRAW_SUCCESS = 'POST_WITHDRAW_SUCCESS';
export const POST_WITHDRAW_ERROR = 'POST_WITHDRAW_ERROR';

export const GET_SUB_CATEGORY_WISE_BRAND_REQUEST =
  'GET_SUB_CATEGORY_WISE_BRAND_REQUEST';
export const GET_SUB_CATEGORY_WISE_BRAND_SUCCESS =
  'GET_SUB_CATEGORY_WISE_BRAND_SUCCESS';
export const GET_SUB_CATEGORY_WISE_BRAND_ERROR =
  'GET_SUB_CATEGORY_WISE_BRAND_ERROR';

export const GET_BRAND_WALLET_REQUEST = 'GET_BRAND_WALLET_REQUEST';
export const GET_BRAND_WALLET_SUCCESS = 'GET_BRAND_WALLET_SUCCESS';
export const GET_BRAND_WALLET_ERROR = 'GET_BRAND_WALLET_ERROR';

export const GET_LIVE_EVENT_REQUEST = 'GET_LIVE_EVENT_REQUEST';
export const GET_LIVE_EVENT_SUCCESS = 'GET_LIVE_EVENT_SUCCESS';
export const GET_LIVE_EVENT_ERROR = 'GET_LIVE_EVENT_ERROR';

export const ALL_UPCOMING_EVENT_REQUEST = 'ALL_UPCOMING_EVENT_REQUEST';
export const ALL_UPCOMING_EVENT_SUCCESS = 'ALL_UPCOMING_EVENT_SUCCESS';
export const ALL_UPCOMING_EVENT_ERROR = 'ALL_UPCOMING_EVENT_ERROR';

export const UPCOMING_EVENT_REQUEST = 'UPCOMING_EVENT_REQUEST';
export const UPCOMING_EVENT_SUCCESS = 'UPCOMING_EVENT_SUCCESS';
export const UPCOMING_EVENT_ERROR = 'UPCOMING_EVENT_ERROR';

export const ALL_LIVE_EVENT_REQUEST = 'ALL_LIVE_EVENT_REQUEST';
export const ALL_LIVE_EVENT_SUCCESS = 'ALL_LIVE_EVENT_SUCCESS';
export const ALL_LIVE_EVENT_ERROR = 'ALL_LIVE_EVENT_ERROR';

export const LIVE_EVENT_REQUEST = 'LIVE_EVENT_REQUEST';
export const LIVE_EVENT_SUCCESS = 'LIVE_EVENT_SUCCESS';
export const LIVE_EVENT_ERROR = 'LIVE_EVENT_ERROR';

export const GET_EVENT_ID_REQUEST = 'GET_EVENT_ID_REQUEST';
export const GET_EVENT_ID_SUCCESS = 'GET_EVENT_ID_SUCCESS';
export const GET_EVENT_ID_ERROR = 'GET_EVENT_ID_ERROR';

export const BRAND_LIVE_EVENT_REQUEST = 'BRAND_LIVE_EVENT_REQUEST';
export const BRAND_LIVE_EVENT_SUCCESS = 'BRAND_LIVE_EVENT_SUCCESS';
export const BRAND_LIVE_EVENT_ERROR = 'BRAND_LIVE_EVENT_ERROR';

export const GET_MULTIPLE_SUB_CATEGORIES_REQUEST =
  'GET_MULTIPLE_SUB_CATEGORIES_REQUEST';
export const GET_MULTIPLE_SUB_CATEGORIES_SUCCESS =
  'GET_MULTIPLE_SUB_CATEGORIES_SUCCESS';
export const GET_MULTIPLE_SUB_CATEGORIES_ERROR =
  'GET_MULTIPLE_SUB_CATEGORIES_ERROR';

export const SHOPIFY_PROD_REQUEST = 'SHOPIFY_PROD_REQUEST';
export const SHOPIFY_PROD_SUCCESS = 'SHOPIFY_PROD_SUCCESS';
export const SHOPIFY_PROD_ERROR = 'SHOPIFY_PROD_ERROR';

export const SHOPIFY_CART_REQUEST = 'SHOPIFY_CART_REQUEST';
export const SHOPIFY_CART_SUCCESS = 'SHOPIFY_CART_SUCCESS';
export const SHOPIFY_CART_ERROR = 'SHOPIFY_CART_ERROR';

export const SHOPIFY_GET_CART_REQUEST = 'SHOPIFY_GET_CART_REQUEST';
export const SHOPIFY_GET_CART_SUCCESS = 'SHOPIFY_GET_CART_SUCCESS';
export const SHOPIFY_GET_CART_ERROR = 'SHOPIFY_GET_CART_ERROR';

export const SHOPIFY_CHECKOUT_REQUEST = 'SHOPIFY_CHECKOUT_REQUEST';
export const SHOPIFY_CHECKOUT_SUCCESS = 'SHOPIFY_CHECKOUT_SUCCESS';
export const SHOPIFY_CHECKOUT_ERROR = 'SHOPIFY_CHECKOUT_ERROR';

export const SHOPIFY_REMOVE_CART_REQUEST = 'SHOPIFY_REMOVE_CART_REQUEST';
export const SHOPIFY_REMOVE_CART_SUCCESS = 'SHOPIFY_REMOVE_CART_SUCCESS';
export const SHOPIFY_REMOVE_CART_ERROR = 'SHOPIFY_REMOVE_CART_ERROR';

export const ADD_CREDIT_CARD_REQUEST = 'ADD_CREDIT_CARD_REQUEST';
export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const ADD_CREDIT_CARD_ERROR = 'ADD_CREDIT_CARD_ERROR';

export const GET_ALL_CREDIT_CARD_REQUEST = 'GET_ALL_CREDIT_CARD_REQUEST';
export const GET_ALL_CREDIT_CARD_SUCCESS = 'GET_ALL_CREDIT_CARD_SUCCESS';
export const GET_ALL_CREDIT_CARD_ERROR = 'GET_ALL_CREDIT_CARD_ERROR';

export const SET_DEFAULT_CREDIT_CARD_REQUEST =
  'SET_DEFAULT_CREDIT_CARD_REQUEST';
export const SET_DEFAULT_CREDIT_CARD_SUCCESS =
  'SET_DEFAULT_CREDIT_CARD_SUCCESS';
export const SET_DEFAULT_CREDIT_CARD_ERROR = 'SET_DEFAULT_CREDIT_CARD_ERROR';

export const CREATE_SHIPPING_ADDRESS_REQUEST =
  'CREATE_SHIPPING_ADDRESS_REQUEST';
export const CREATE_SHIPPING_ADDRESS_SUCCESS =
  'CREATE_SHIPPING_ADDRESS_SUCCESS';
export const CREATE_SHIPPING_ADDRESS_ERROR = 'CREATE_SHIPPING_ADDRESS_ERROR';

export const GET_ALL_SHIPPING_ADDRESS_REQUEST =
  'GET_ALL_SHIPPING_ADDRESS_REQUEST';
export const GET_ALL_SHIPPING_ADDRESS_SUCCESS =
  'GET_ALL_SHIPPING_ADDRESS_SUCCESS';
export const GET_ALL_SHIPPING_ADDRESS_ERROR = 'GET_ALL_SHIPPING_ADDRESS_ERROR';

export const SET_DEFAULT_SHIPPING_ADDRESS_REQUEST =
  'SET_DEFAULT_SHIPPING_ADDRESS_REQUEST';
export const SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS =
  'SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS';
export const SET_DEFAULT_SHIPPING_ADDRESS_ERROR =
  'SET_DEFAULT_SHIPPING_ADDRESS_ERROR';

export const SHOPIFY_SET_SHIPPING_ADDRESS_REQUEST =
  'SHOPIFY_SET_SHIPPING_ADDRESS_REQUEST';
export const SHOPIFY_SET_SHIPPING_ADDRESS_SUCCESS =
  'SHOPIFY_SET_SHIPPING_ADDRESS_SUCCESS';
export const SHOPIFY_SET_SHIPPING_ADDRESS_ERROR =
  'SHOPIFY_SET_SHIPPING_ADDRESS_ERROR';

export const SHOPIFY_GET_SHIPPING_RATES_REQUEST =
  'SHOPIFY_GET_SHIPPING_RATES_REQUEST';
export const SHOPIFY_GET_SHIPPING_RATES_SUCCESS =
  'SHOPIFY_GET_SHIPPING_RATES_SUCCESS';
export const SHOPIFY_GET_SHIPPING_RATES_ERROR =
  'SHOPIFY_GET_SHIPPING_RATES_ERROR';

export const SHOPIFY_SET_SHIPPING_RATES_REQUEST =
  'SHOPIFY_SET_SHIPPING_RATES_REQUEST';
export const SHOPIFY_SET_SHIPPING_RATES_SUCCESS =
  'SHOPIFY_SET_SHIPPING_RATES_SUCCESS';
export const SHOPIFY_SET_SHIPPING_RATES_ERROR =
  'SHOPIFY_SET_SHIPPING_RATES_ERROR';

export const SHOPIFY_APPLY_PROMO_REQUEST = 'SHOPIFY_APPLY_PROMO_REQUEST';
export const SHOPIFY_APPLY_PROMO_SUCCESS = 'SHOPIFY_APPLY_PROMO_SUCCESS';
export const SHOPIFY_APPLY_PROMO_ERROR = 'SHOPIFY_APPLY_PROMO_ERROR';

export const SHOPIFY_REMOVE_PROMO_CODE_REQUEST =
  'SHOPIFY_REMOVE_PROMO_CODE_REQUEST';
export const SHOPIFY_REMOVE_PROMO_CODE_SUCCESS =
  'SHOPIFY_REMOVE_PROMO_CODE_SUCCESS';
export const SHOPIFY_REMOVE_PROMO_CODE_ERROR =
  'SHOPIFY_REMOVE_PROMO_CODE_ERROR';

export const SHOPIFY_COMPLETE_CHECKOUT_REQUEST =
  'SHOPIFY_COMPLETE_CHECKOUT_REQUEST';
export const SHOPIFY_COMPLETE_CHECKOUT_SUCCESS =
  'SHOPIFY_COMPLETE_CHECKOUT_SUCCESS';
export const SHOPIFY_COMPLETE_CHECKOUT_ERROR =
  'SHOPIFY_COMPLETE_CHECKOUT_ERROR';

export const SHOPIFY_UPDATE_ATTRIBUTE_REQUEST =
  'SHOPIFY_UPDATE_ATTRIBUTE_REQUEST';
export const SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS =
  'SHOPIFY_UPDATE_ATTRIBUTE_SUCCESS';
export const SHOPIFY_UPDATE_ATTRIBUTE_ERROR = 'SHOPIFY_UPDATE_ATTRIBUTE_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const SHOPIFY_EMPTY_CART_REQUEST = 'SHOPIFY_EMPTY_CART_REQUEST';
export const SHOPIFY_EMPTY_CART_SUCCESS = 'SHOPIFY_EMPTY_CART_SUCCESS';
export const SHOPIFY_EMPTY_CART_ERROR = 'SHOPIFY_EMPTY_CART_ERROR';

export const ALL_PUBLISH_EVENT_REQUEST = 'ALL_PUBLISH_EVENT_REQUEST';
export const ALL_PUBLISH_EVENT_SUCCESS = 'ALL_PUBLISH_EVENT_SUCCESS';
export const ALL_PUBLISH_EVENT_ERROR = 'ALL_PUBLISH_EVENT_ERROR';

export const GET_EVENT_VIEW_REQUEST = 'GET_EVENT_VIEW_REQUEST';
export const GET_EVENT_VIEW_SUCCESS = 'GET_EVENT_VIEW_SUCCESS';
export const GET_EVENT_VIEW_ERROR = 'GET_EVENT_VIEW_ERROR';

export const GET_CHAT_TOKEN = 'GET_CHAT_TOKEN';

export const GET_CHAT_REQUEST = 'GET_CHAT_REQUEST';
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS';
export const GET_CHAT_ERROR = 'GET_CHAT_ERROR';

export const SAVE_CHAT_REQUEST = 'SAVE_CHAT_REQUEST';
export const SAVE_CHAT_SUCCESS = 'SAVE_CHAT_SUCCESS';
export const SAVE_CHAT_ERROR = 'SAVE_CHAT_ERROR';

export const GET_EVENT_LIKES_REQUEST = "GET_EVENT_LIKES_REQUEST";
export const GET_EVENT_LIKES_SUCCESS = "GET_EVENT_LIKES_SUCCESS";
export const GET_EVENT_LIKES_ERROR = "GET_EVENT_LIKES_ERROR"