import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useParams, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSubCategories } from "../../redux/actions/category.action";
import "react-multi-carousel/lib/styles.css";
import "../../css/category.scss";

const responsive5 = {
	superLargeDesktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 6,
		slidesToSlide: 3, // optional, default to 1.
	},
	desktop: {
		breakpoint: { max: 1023, min: 768 },
		items: 4,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 767, min: 0 },
		items: 2.5,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 767, min: 0 },
		items: 2.5,
		slidesToSlide: 2, // optional, default to 1.
	},
};

export default ({ categoryName, page }) => {
	const dispatch = useDispatch();
	const params = useParams();
	const id = params.id;
	const [subCategory, setSubCategory] = useState([]);
	const [parentCategory, setParentCategory] = useState([]);

	useEffect(() => {
		if (id) {
			dispatch(getSubCategories(id)).then((res) => {
				setSubCategory(res?.payload?.data);
				setParentCategory(res.payload.parentData);
			});
		}
	}, [id, params.category]);

	const renderCategory = () => {
		let subItem = [];
		subItem.push(
			<NavLink to={`/${page ? "sub-influencer-category" : "sub-category"}/${parentCategory?._id}`}>
				<div className="cash-item">
					<div className="cat_height">
						<img src={parentCategory?.image_url} />
					</div>
					<h4>All {parentCategory?.category_name}</h4>
				</div>
			</NavLink>
		);
		subCategory?.map((item) => {
			subItem.push(
				<NavLink to={`/${page ? "sub-influencer-sub-category" : "sub-sub-category"}/${id}/${item._id}`}>
					<div className="cash-item">
						<div className="cat_height">
							<img src={item.image_url} />
						</div>
						<h4>{item.category_name}</h4>
					</div>
				</NavLink>
			);
		});
		return subItem;
	};

	return (
		<>
			<div className="popular_cat_main">
				<div className="midd-width-ifti">
					<div className="popular_cat">
						<div className="pik-header">
							<h3>{categoryName ? categoryName : parentCategory?.category_name}</h3>
						</div>
						<div className="popular_cat_carousal">
							{subCategory && (
								<Carousel responsive={responsive5} containerClass="carousel-container" removeArrowOnDeviceType={["tablet", "mobile"]}>
									{renderCategory()}
								</Carousel>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
